import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DeleteContact } from '../../../services/ContactService';

const CanvaDeleteContact = ({
  showDelete,
  setShowDelete,
  selectedContact,
  setSelectedContact,
  setTrigger,
  setGrandParentTrigger
}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const deleteObject = async () => {
    const response = await DeleteContact(selectedContact.id);
    setIsLoadingSubmit(true);
    if (response.success === true) {
      setSelectedContact({});
      setShowDelete(false);
      setTrigger(Date.now());
      setGrandParentTrigger(Date.now());
      toast('Contact supprimé');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmit(false);
  };
  return (
    <>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedContact.id !== undefined && (
              <>
                La suppression du contact "{`${selectedContact.name}`}" est
                définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            disabled={isLoadingSubmit}
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, supprimer
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CanvaDeleteContact;
CanvaDeleteContact.propTypes = {
  showDelete: PropTypes.bool,
  setShowDelete: PropTypes.func,
  selectedContact: PropTypes.object,
  setSelectedContact: PropTypes.func,
  setGrandParentTrigger: PropTypes.func,
  setTrigger: PropTypes.func
};
