import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import FalconCloseButton from '../../../common/FalconCloseButton';
import FormAlert from '../../commons/FormAlert';
import { Circle } from 'react-color/lib/components/circle/Circle';
import PropTypes from 'prop-types';
import {
  PostPrestationType,
  PutPrestationType,
  ShowPrestationType
} from '../../../../services/PrestationTypeService';
import { toast } from 'react-toastify';

const ManageType = ({
  showManageType,
  setShowManageType,
  selectedType,
  setTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    color: '',
    name: ''
  });

  useEffect(() => {
    const fetchOneType = async () => {
      const response = await ShowPrestationType(
        selectedType && selectedType.id
      );
      if (response.success === true) {
        setFormData({
          ...formData,
          name: selectedType.name,
          color: selectedType.color,
          id: selectedType.id
        });
      }
    };

    if (selectedType && selectedType.id) {
      fetchOneType();
    }

    /*    fetchType();*/
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedType && selectedType.id) {
      response = await PutPrestationType(
        selectedType && selectedType.id,
        formData
      );
    } else {
      response = await PostPrestationType(formData);
    }

    if (response.success === true) {
      toast('Type enregistré');
      setShowManageType(false);
      /* setShowIndexType(false);*/
      setTrigger(Date.now());
      setFormData({
        name: '',
        color: ''
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
      setErrorForm(response.validator);
    }
  };

  const handleFieldChange = e => {
    if (e.target) {
      if (e.target.name === 'is_admin') {
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked ? 1 : 0
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      }
    } else if (e.hex) {
      setFormData({
        ...formData,
        color: e.hex
      });
    }
  };

  return (
    <>
      {/* --- ouvre le Manage de type de prestation -----*/}
      <Offcanvas
        placement={'end'}
        show={showManageType && selectedType}
        onHide={() => {
          setShowManageType(false);
          setFormData({
            name: '',
            color: '',
            id: ''
          });
        }}
      >
        <Offcanvas.Header className="pb-0">
          <Offcanvas.Title>
            {selectedType && selectedType.id
              ? ' Modifier le type de prestation'
              : 'Ajouter un type de prestation'}
          </Offcanvas.Title>
          <FalconCloseButton
            onClick={() => {
              setShowManageType(false);
              setFormData({
                name: '',
                color: '',
                id: ''
              });
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="warning" data={errorForm} />
            </div>
          )}
          <Form.Group controlId="name">
            <Form.Label>Choisir un nom :</Form.Label>

            <Form.Control
              placeholder="Type de prestation"
              size="sm"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <div className="d-block mt-4"></div>
          <Circle
            color={formData.color}
            onChange={handleFieldChange}
            width="100%"
          />
          <div className="d-block mt-3"></div>
          {formData.color === '' ? (
            <></>
          ) : (
            <Form.Label className="mb-3">
              Couleur sélectionnée :
              <span
                className="rounded-circle d-inline-block"
                style={{
                  backgroundColor: formData.color,
                  height: '28px',
                  width: '28px',
                  position: 'relative',
                  top: '9px',
                  left: '5px'
                }}
              ></span>
            </Form.Label>
          )}
          <Col className={'mt-3'}>
            <Button
              onClick={e => {
                handleSubmit(e);
              }}
            >
              Enregistrer
            </Button>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageType.propTypes = {
  showManageType: PropTypes.bool,
  setShowManageType: PropTypes.func,
  setFormData: PropTypes.func,
  setTrigger: PropTypes.func,
  selectedType: PropTypes.object,
  formData: PropTypes.object
};

export default ManageType;
