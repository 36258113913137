import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'prestation-sheets';

export async function GeneratePrestationSheetFile(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function SendPrestationSheet(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function DeletePrestationSheet(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function StoreConsumption(id, formData) {
  return modelService.PutModel(`prestations/${id}/consumption`, formData);
}
