import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'vehicles';

export async function IndexVehicles() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowVehicles(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutVehicles(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostVehicles(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteVehicles(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
