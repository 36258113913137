import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'pennylane-invoices';

export async function GeneratePennylaneInvoiceFile(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function FinalizePennylaneInvoice(id, formData) {
  return modelService.PutModel(`${model}/${id}/finalize`, formData);
}

export async function SendPennylaneInvoice(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function DeletePennylaneInvoice(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
