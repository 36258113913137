import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconAlert from '../../../common/IconAlert';

const OffCanvaFolderDelete = ({
  showCanvaFolderDelete,
  setShowCanvaFolderDelete,
  handleDeleteFolder,
  selectedObject
}) => {
  const getNameChildrensAndFiles = selectedObject => {
    let nameChildrens = [];
    let nameFiles = [];
    const getRecursiveNameChildrensAndFiles = selectedObject => {
      if (selectedObject.childrens && selectedObject.childrens.length > 0) {
        selectedObject.childrens.forEach(children => {
          nameChildrens.push(children.name);
          getRecursiveNameChildrensAndFiles(children);
        });
      }
      if (selectedObject.files && selectedObject.files.length > 0) {
        selectedObject.files.forEach(file => {
          nameFiles.push(file.name);
        });
      }
    };
    getRecursiveNameChildrensAndFiles(selectedObject);
    return { nameChildrens, nameFiles };
  };

  return (
    <Offcanvas
      show={showCanvaFolderDelete}
      onHide={() => {
        setShowCanvaFolderDelete(false);
      }}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {selectedObject &&
          selectedObject.id &&
          ((selectedObject.childrens && selectedObject.childrens.length > 0) ||
            (selectedObject.files && selectedObject.files.length > 0)) && (
            <IconAlert variant={'warning'}>
              La suppression du dossier {selectedObject.name} entraînera la
              suppression des dossiers et fichiers suivent :
              <ul className={'mb-0 mt-2'}>
                {getNameChildrensAndFiles(selectedObject).nameChildrens.map(
                  (nameChildren, index) => (
                    <li key={index}>{nameChildren}</li>
                  )
                )}
                {getNameChildrensAndFiles(selectedObject).nameFiles.map(
                  (nameFile, index) => (
                    <li key={index}>{nameFile}</li>
                  )
                )}
              </ul>
            </IconAlert>
          )}
        <Button
          size="sm"
          variant="falcon-danger"
          className="me-2"
          onClick={() => {
            handleDeleteFolder(selectedObject);
          }}
        >
          Oui, supprimer le dossier {selectedObject.name}
        </Button>
        <Button
          size="sm"
          variant="falcon-default"
          className="me-2"
          onClick={() => {
            setShowCanvaFolderDelete(false);
          }}
        >
          Annuler
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

OffCanvaFolderDelete.propTypes = {
  showCanvaFolderDelete: PropTypes.bool,
  setShowCanvaFolderDelete: PropTypes.func,
  handleDeleteFolder: PropTypes.func,
  selectedObject: PropTypes.object.isRequired
};

export default OffCanvaFolderDelete;
