import React from 'react';
import { Offcanvas, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

const CanvaCustomer = ({
  showCanvasCustomer,
  setShowCanvasCustomer,
  selectedCustomer,
  setSelectedCustomer
}) => {
  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showCanvasCustomer}
        onHide={() => {
          setShowCanvasCustomer(false);
          setSelectedCustomer({});
        }}
        className={'w-50'}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Documents</Offcanvas.Title>
        </Offcanvas.Header>
        {selectedCustomer && selectedCustomer.id && (
          <Offcanvas.Body className="">
            {selectedCustomer.prestations.map((prestation, index) => (
              <>
                <p key={index}>
                  <b>{prestation.name}</b>
                  {(prestation.last_billing_sheet ||
                    prestation.last_booklet ||
                    prestation.last_logistic_sheet ||
                    prestation.last_prestation_sheet ||
                    prestation.last_quote) && (
                    <Table responsive className="table-stats-tracks">
                      <tbody>
                        {prestation.last_logistic_sheet && (
                          <tr>
                            <td style={{ paddingLeft: '0' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path
                                  d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                                  fill="#000000"
                                />
                              </svg>
                              <a
                                href={prestation.last_logistic_sheet.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Fiche logistique (
                                {moment(
                                  prestation.last_logistic_sheet.created_at
                                )
                                  .local()
                                  .format('DD/MM/YYYY')}
                                )
                              </a>
                            </td>
                          </tr>
                        )}
                        {prestation.last_prestation_sheet && (
                          <tr>
                            <td style={{ paddingLeft: '0' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path
                                  d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                                  fill="#000000"
                                />
                              </svg>
                              <a
                                href={prestation.last_prestation_sheet.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Fiche prestation (
                                {moment(
                                  prestation.last_prestation_sheet.created_at
                                )
                                  .local()
                                  .format('DD/MM/YYYY')}
                                )
                              </a>
                            </td>
                          </tr>
                        )}
                        {prestation.last_booklet && (
                          <tr>
                            <td style={{ paddingLeft: '0' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path
                                  d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                                  fill="#000000"
                                />
                              </svg>
                              <a
                                href={prestation.last_booklet.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Plaquette (
                                {moment(prestation.last_booklet.created_at)
                                  .local()
                                  .format('DD/MM/YYYY')}
                                )
                              </a>
                            </td>
                          </tr>
                        )}
                        {prestation.last_quote && (
                          <tr>
                            <td style={{ paddingLeft: '0' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path
                                  d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                                  fill="#000000"
                                />
                              </svg>
                              <a
                                href={prestation.last_quote.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Devis (
                                {moment(prestation.last_quote.created_at)
                                  .local()
                                  .format('DD/MM/YYYY')}
                                )
                              </a>
                            </td>
                          </tr>
                        )}
                        {prestation.last_billing_sheet && (
                          <tr>
                            <td style={{ paddingLeft: '0' }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path
                                  d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                                  fill="#000000"
                                />
                              </svg>
                              <a
                                href={prestation.last_billing_sheet.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Fiche de facturation (
                                {moment(
                                  prestation.last_billing_sheet.created_at
                                )
                                  .local()
                                  .format('DD/MM/YYYY')}
                                )
                              </a>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </p>
              </>
            ))}
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
};

export default CanvaCustomer;

CanvaCustomer.propTypes = {
  showCanvasCustomer: PropTypes.bool,
  setShowCanvasCustomer: PropTypes.func,
  setParentTrigger: PropTypes.func,
  selectedCustomer: PropTypes.object,
  setSelectedCustomer: PropTypes.func
};
