import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import NavbarTop from '../../avs/navbar/top/NavbarTop';
import { Container } from 'react-bootstrap';
import Flex from '../../common/Flex';
import RequireAuthButler from '../../../middlewares/RequireAuthButler';

const WaiterLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const navigate = useNavigate();

  useEffect(() => {
    RequireAuthButler(navigate);
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    /* <div className={isFluid ? 'container-fluid' : 'container'}>*/
    <Container fluid={Flex} className="px-4">
      {/*      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}*/}
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/* ------ Main Routes ------*/}
          <Outlet />
        </div>
      </ProductProvider>
    </Container>

    /*    </div>*/
  );
};

export default WaiterLayout;
