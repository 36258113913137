import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import {
  PostIngredient,
  DeleteIngredient,
  PutIngredient,
  ShowIngredient,
  IndexIngredient
} from '../../../services/IngredientsService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';

const ManageIngredient = ({
  showCanvaIngredient,
  setShowCanvaIngredient,
  setTrigger,
  selectedObject,
  formParentProduct,
  setFormParentProduct,
  setLabelIngredients
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingSubmitDelete, setIsLoadingSubmitDelete] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;

    if (selectedObject && selectedObject.id) {
      response = await PutIngredient(selectedObject.id, formData);
    } else {
      response = await PostIngredient(formData);
    }

    if (response.success === true) {
      toast('Ingrédient enregistré');
      setIsLoadingSubmit(true);
      if (!formParentProduct) {
        setTrigger(Date.now());
      }
      setShowCanvaIngredient(false);
      // setTrigger(Date.now());
      const responseIngredient = await IndexIngredient();
      if (responseIngredient.success === true) {
        let ingredientArray = [];
        responseIngredient.data.map(ing => {
          ingredientArray.push({
            value: ing.id,
            label: ing.name
          });
        });
        setLabelIngredients(ingredientArray);
      }

      if (formParentProduct) {
        setFormParentProduct({
          ...formParentProduct,
          ingredients: [
            ...formParentProduct.ingredients,
            { value: response.data.id, label: response.data.name }
          ]
        });
        setShowCanvaIngredient(false);
        return;
      }

      setShowCanvaIngredient(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };

  const deleteIngredient = async () => {
    const responseDeleteIngredient = await DeleteIngredient(selectedObject.id);
    setIsLoadingSubmitDelete(true);
    if (responseDeleteIngredient.success === true) {
      toast('Produit supprimé');

      setTrigger(Date.now());
      setShowDelete(false);
      setShowCanvaIngredient(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmitDelete(false);
  };

  useEffect(() => {
    const fetchOneIngredient = async () => {
      const responseOneIngredient = await ShowIngredient(selectedObject.id);
      if (responseOneIngredient.success === true) {
        let dataIngredient = responseOneIngredient.data;
        setFormData({
          name: dataIngredient.name,
          id: dataIngredient.id
        });
      }
    };

    if (selectedObject && selectedObject.id) {
      setFormData({
        ...formData,
        name: selectedObject.name
      });
    }

    if (selectedObject && selectedObject.id) {
      fetchOneIngredient();
    }
  }, []);

  return (
    <>
      {showCanvaIngredient && selectedObject && (
        <Offcanvas
          show={showCanvaIngredient}
          onHide={() => {
            setShowCanvaIngredient(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header
            closeButton={() => {
              setShowCanvaIngredient(false);
            }}
          >
            <Offcanvas.Title>
              <h3 className="text-primary admin-title mb-0">
                {selectedObject && selectedObject.id
                  ? 'Modifier un ingrédient'
                  : 'Ajouter un ingrédient'}
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Nom de l'ingredient :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleFieldChange}
                    placeholder={'Tomate, farine, sucre ...'}
                  />
                </Form.Group>
              </Col>

              <Col className="mt-3">
                <Col>
                  <Button
                    disabled={isLoadingSubmit}
                    onClick={e => {
                      handleSubmit(e);
                    }}
                    variant="primary"
                    size="sm"
                  >
                    {selectedObject && selectedObject.id
                      ? 'Enregistrer les modifications'
                      : `Ajouter l'ingrédient`}
                  </Button>

                  {selectedObject && selectedObject.id && (
                    <Button
                      variant="danger"
                      className=" float-end"
                      size="sm"
                      type="button"
                      onClick={() => {
                        setShowDelete(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{
                          marginBottom: '2px',
                          marginRight: '3px'
                        }}
                      >
                        <path
                          d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                    </Button>
                  )}
                </Col>
              </Col>
            </Form>
          </Offcanvas.Body>

          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              <p>
                Supprimer l'ingrédient :{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {selectedObject.name}{' '}
                </span>
                ?
              </p>
              <Button
                disabled={isLoadingSubmitDelete}
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteIngredient();
                }}
              >
                Oui, supprimer l'ingrédient
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
        </Offcanvas>
      )}
    </>
  );
};

ManageIngredient.propTypes = {
  showCanvaIngredient: PropTypes.bool,
  setShowCanvaIngredient: PropTypes.func,
  selectedObject: PropTypes.object,
  setTrigger: PropTypes.func,
  formParentProduct: PropTypes.object,
  setFormParentProduct: PropTypes.func,
  setLabelIngredients: PropTypes.func
};

export default ManageIngredient;
