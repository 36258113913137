import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormAlert from '../commons/FormAlert';
import PropTypes from 'prop-types';
import {
  PostCustomer,
  PutCustomer,
  ShowCustomer
} from '../../../services/CustomerService';

const ManageCustomers = ({
  setShowManageCustomers,
  showManageCustomers,
  selectedObject,
  setSelectedObject
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    zip: '',
    city: '',
    pennylane_id: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      const responseCustomer = await ShowCustomer(selectedObject.id);
      if (responseCustomer.success === true) {
        let dataCustomer = responseCustomer.data;
        setFormData({
          name: dataCustomer.name,
          address: dataCustomer.address,
          zip: dataCustomer.zip,
          city: dataCustomer.city,
          pennylane_id: dataCustomer.pennylane_id
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (selectedObject && selectedObject.id) {
      fetchCustomer();
    }
  }, [selectedObject]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (selectedObject && selectedObject.id) {
      response = await PutCustomer(selectedObject.id, formData);
    } else {
      response = await PostCustomer(formData);
    }
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      setShowManageCustomers(false);
      toast(`Client enregistré.`);
      setTimeout(function () {
        window.location.href = `clients`;
      }, 300);
    }
    setSelectedObject({});
    setFormData({
      name: '',
      address: '',
      zip: '',
      city: '',
      pennylane_id: ''
    });
  };

  return (
    <>
      {showManageCustomers && (
        <>
          <Offcanvas
            className="w-50"
            show={showManageCustomers}
            onHide={() => {
              setShowManageCustomers(false);
              setSelectedObject({});
            }}
            placement="end"
          >
            <Offcanvas.Header
              closeButton={() => {
                setShowManageCustomers(false);
                setSelectedObject({});
              }}
            >
              <Offcanvas.Title>
                <h3 className="text-primary admin-title mb-0">
                  {selectedObject && selectedObject.id
                    ? 'Modifier les informations sur le portail AVS et dans Pennylane'
                    : `Ajouter un client sur le portail AVS et dans Pennylane`}
                </h3>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    {errorForm && (
                      <div className="mt-3">
                        <FormAlert variant="danger" data={errorForm} />
                      </div>
                    )}
                  </Col>
                  <Col lg={12}>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Label>Nom de l'entreprise :</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Nom de l'entreprise"
                            value={formData.name || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Label>Adresse :</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            placeholder="Adresse"
                            value={formData.address || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="mt-3" md="6">
                        <Form.Group controlId="email">
                          <Form.Label>Code postal :</Form.Label>
                          <Form.Control
                            type="text"
                            name="zip"
                            placeholder="Code postal"
                            value={formData.zip || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="mt-3" md="6">
                        <Form.Group controlId="email">
                          <Form.Label>Ville :</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder="Ville"
                            value={formData.city || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="mt-3" md="6">
                        <Form.Group controlId="email">
                          <Form.Label>
                            Identifiant Pennylane{' '}
                            <small>
                              laisse vide pour créer le client automatiquement
                              dans Pennylane
                            </small>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="pennylane_id"
                            placeholder="918768fb-9d63-4eb0-9389-14fa24015e34"
                            value={formData.pennylane_id || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="flex-between-center mt-3">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{
                              marginBottom: '2px',
                              marginRight: '4px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

ManageCustomers.propTypes = {
  setShowManageCustomers: PropTypes.func,
  showManageCustomers: PropTypes.bool,
  selectedObject: PropTypes.object,
  setSelectedObject: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default ManageCustomers;
