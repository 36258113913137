import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Link, Outlet } from 'react-router-dom';
import logoLogin from 'assets/img/logo-avs.png';

const AuthSimpleLayoutAvs = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 pb-6 pt-0">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Link to="/" className="text-decoration-none">
            <div className="d-flex flex-center fw-bolder fs-5 mb-6">
              <img className="me-2" src={logoLogin} alt="Logo" width={150} />
            </div>
          </Link>
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayoutAvs;
