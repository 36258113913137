import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as Moment from 'moment';
import SoftBadge from '../../../common/SoftBadge';
import moment from 'moment';
import { ShowPrestation } from '../../../../services/PrestationService';
import FalconCloseButton from '../../../common/FalconCloseButton';

const CalendarModalEventWaiters = ({
  showModal,
  setShowModal,
  selectedObject,
  setSelectedObject
}) => {
  const [mapCenter, setMapCenter] = useState(false);
  const [data, setData] = useState({
    id: '',
    address: '',
    date: '',
    latitude: '',
    longitude: '',
    name: '',
    notes: [],
    time: '',
    type: '',
    guest_count: '',
    category: '',
    backgroundColor: ''
  });
  useEffect(() => {
    const fetchOnePrestation = async () => {
      const responseOnePresta = await ShowPrestation(selectedObject.id);

      if (responseOnePresta.success === true) {
        const responsePresta = responseOnePresta.data;
        setData({
          id: responsePresta.id,
          address: responsePresta.address,
          date: Moment(responsePresta.date).local().format('DD/MM/YYYY'),
          time: moment(responsePresta.time, 'HH:mm:ss').format('HH:mm'),
          title: responsePresta.name.replace('Prestation de', ''),
          type: responsePresta.type,
          originalLongitude: responsePresta.longitude,
          originalLatitude: responsePresta.latitude,
          longitude: parseFloat(responsePresta.longitude),
          latitude: parseFloat(responsePresta.latitude),
          guest_count: responsePresta.guest_count,
          category: responsePresta.category.name
          /*backgroundColor: responsePresta.category.color
            ? responsePresta.category.color
            : ''*/
        });

        setMapCenter({
          lat: parseFloat(responsePresta.latitude),
          lng: parseFloat(responsePresta.longitude)
        });
      }
    };

    if (selectedObject && selectedObject.id) {
      fetchOnePrestation();
    }
  }, [selectedObject]);

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true
  };
  const containerStyle = {
    width: '100%',
    height: '250px'
  };

  const position = {
    lat: parseFloat(data.latitude),
    lng: parseFloat(data.longitude)
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      contentClassName="border"
      size={'lg'}
      centered
    >
      <Modal.Header className="bg-light px-x1 border-bottom-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Informations{' '}
        </Modal.Title>
        <FalconCloseButton
          onClick={() => {
            setSelectedObject({});
            setShowModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="px-x1 pb-x1 pt-1 fs--1">
        <Row className="mt-3">
          <Col lg={6}>
            <Col>
              {selectedObject && (
                <Col>
                  <Row>
                    <p className="fs-0">
                      Date : {data.date} à {data.time}
                    </p>
                  </Row>
                  <Row>
                    <p className="fs-0"> Événement :{data.title}</p>
                  </Row>
                  <Row>
                    <p className="fs-0">
                      Adresse :{' '}
                      {data.address && (
                        <a
                          href={`https://www.google.com/maps/place/${data.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data.address}
                        </a>
                      )}
                    </p>
                  </Row>{' '}
                  <Row>
                    <Col>
                      {' '}
                      <p className="fs-0"> Convives : {data.guest_count}</p>
                    </Col>
                    <Col>
                      {' '}
                      <>
                        <span
                          className="rounded-circle d-inline-block me-2"
                          style={{
                            backgroundColor: data.backgroundColor,
                            height: '12px',
                            width: '12px',
                            position: 'relative',
                            top: '1px'
                          }}
                        ></span>
                        {data.category ? data.category : ''}
                        <div className="d-block mt-1">
                          <SoftBadge bg="primary" className="">
                            {data.type ? data.type : ''}
                          </SoftBadge>
                        </div>
                      </>
                    </Col>
                  </Row>
                </Col>
              )}
            </Col>
          </Col>
          <Col lg={6}>
            {mapCenter !== false && (
              <GoogleMap
                center={mapCenter}
                options={mapOptions}
                zoom={15}
                mapContainerStyle={containerStyle}
              >
                <></>
                <Marker position={position} />
              </GoogleMap>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Col>
          <Button
            variant="primary"
            className="admin-primary"
            size="sm"
            as={Link}
            to="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M16 12l-6 6V6z" fill="rgba(255,255,255,1)" />
            </svg>
            Fiche logistique
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

CalendarModalEventWaiters.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  setSelectedObject: PropTypes.func,
  selectedObject: PropTypes.object
};

export default CalendarModalEventWaiters;
