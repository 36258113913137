import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const EditProductOrder = ({
  index,
  formData,
  setFormData,
  section,
  product
}) => {
  return (
    <Fragment>
      <span className="float-end mt-1 ms-3">
        {index > 0 && (
          <span
            className="cursor-pointer"
            onClick={() => {
              let tmpFormData = {
                ...formData
              };
              let current = tmpFormData.products
                .filter(p => p.section_id === section.id)
                .sort((a, b) => {
                  return a.position - b.position;
                })[index];
              let currentPosition = current.position;
              let currentUuid = current.uuid;

              let target = tmpFormData.products
                .filter(p => p.section_id === section.id)
                .sort((a, b) => {
                  return a.position - b.position;
                })[index - 1];

              let targetPosition = target.position;
              let targetUuid = target.uuid;

              tmpFormData.products.find(p => p.uuid === currentUuid).position =
                targetPosition;

              tmpFormData.products.find(p => p.uuid === targetUuid).position =
                currentPosition;

              setFormData(tmpFormData);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
            </svg>
          </span>
        )}
        {index <
          formData.products.filter(p => p.section_id === section.id).length -
            1 && (
          <span
            className="cursor-pointer"
            onClick={() => {
              let tmpFormData = {
                ...formData
              };
              let current = tmpFormData.products
                .filter(p => p.section_id === section.id)
                .sort((a, b) => {
                  if (a.position < b.position) {
                    return -1;
                  }
                  if (a.position > b.position) {
                    return 1;
                  }
                  return 0;
                })[index];
              let currentPosition = current.position;
              let currentUuid = current.uuid;

              let target = tmpFormData.products
                .filter(p => p.section_id === section.id)
                .sort((a, b) => {
                  if (a.position < b.position) {
                    return -1;
                  }
                  if (a.position > b.position) {
                    return 1;
                  }
                  return 0;
                })[index + 1];

              let targetPosition = target.position;
              let targetUuid = target.uuid;

              tmpFormData.products.find(p => p.uuid === currentUuid).position =
                targetPosition;

              tmpFormData.products.find(p => p.uuid === targetUuid).position =
                currentPosition;

              setFormData(tmpFormData);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
            </svg>
          </span>
        )}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="16"
        height="16"
        className="cursor-pointer mt-2 me-0 float-start"
        onClick={() => {
          let tmpFormData = {
              ...formData
            },
            tmpProducts = [...formData.products];
          tmpFormData.products = tmpProducts.filter(
            p => p.uuid !== product.uuid
          );
          setFormData(tmpFormData);
        }}
      >
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
          fill="rgba(229,47,18,1)"
        />
      </svg>
    </Fragment>
  );
};

EditProductOrder.propTypes = {
  index: PropTypes.number,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  section: PropTypes.object,
  product: PropTypes.object
};

export default EditProductOrder;
