import React, { Fragment, useRef } from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import SoftBadge from '../../common/SoftBadge';

export default function PrintComponentLogisticSheet() {
  let componentRef = useRef();

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div>
          <Fragment>
            <Col lg={12}>
              <Table borderless responsive>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '80%' }}>
                      Vaiselle
                    </th>
                    <th scope="col" style={{ width: '150px' }}>
                      Pièces
                    </th>
                    <th scope="col" style={{ width: '150px' }}>
                      Check
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Fourchette
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        <strong>220</strong>
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Couteau
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        200
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Verre à eau
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        200
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Non
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Verre à vin
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        300
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={12}>
              <Table borderless responsive className={'mb-3'}>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '80%' }}>
                      Boisson
                    </th>
                    <th scope="col" style={{ width: '150px' }}>
                      Litres
                    </th>
                    <th scope="col" style={{ width: '150px' }}>
                      Check
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Coca
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        200
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Bière
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        300
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Crément
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        <strong>15</strong>
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Non
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Jus d’orange
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        20
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Oui
                      </SoftBadge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={12}>
              <Card.Title
                as={'h5'}
                style={{ padding: '12px 12px 12px 20px', margin: '0px' }}
              >
                Liste des maitres d’hotel reliés à la prestation
              </Card.Title>
              <Table borderless responsive className={'mb-3'}>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '90%' }}>
                      Prénom et Nom
                    </th>
                    <th scope="col">Heures</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Marc Dupont
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        200
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        Jean Dupont
                      </SoftBadge>
                    </td>
                    <td>
                      <SoftBadge
                        bg="info"
                        className="me-2 w-100 text-justify p-3"
                      >
                        300
                      </SoftBadge>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <div className="page-break"></div>
          </Fragment>
        </div>
      );
    }
  }
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button
            size="sm"
            variant="primary"
            className="me-2 admin-primary"
            type="submit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '4px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
            Imprimer la fiche
          </Button>
        )}
        content={() => componentRef}
      />
      <div className="d-none">
        <ComponentToPrint ref={el => (componentRef = el)} />
      </div>
    </div>
  );
}
