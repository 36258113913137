import React, { useEffect, useState } from 'react';
import { Offcanvas, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FalconCloseButton from '../../../common/FalconCloseButton';
import PropTypes from 'prop-types';
import { IndexPrestationsType } from '../../../../services/PrestationTypeService';
import ManageType from './ManageType';
const IndexTypePrestation = ({ showIndexType, setShowIndexType }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [showManageType, setShowManageType] = useState(false);
  /*  const [errorForm, setErrorForm] = useState(false);*/
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    const fetchType = async () => {
      const responseTypes = await IndexPrestationsType();
      if (responseTypes.success === true) {
        setTypes(responseTypes.data);
      }
    };

    fetchType();
  }, [trigger]);

  return (
    <>
      {/* ----- canva put et post type  -----  */}
      {showManageType && selectedType && (
        <ManageType
          showManageType={showManageType}
          setShowManageType={setShowManageType}
          selectedType={selectedType}
          setTrigger={setTrigger}
          /*      formData={formData}
          setFormData={setFormData}*/
        />
      )}
      {/* --- canva delete ---- */}
      {/* {showCanvaDelete && (
        <DeleteType
          showCanvaDelete={showCanvaDelete}
          setShowCanvaDelete={setShowCanvaDelete}
          selectedType={selectedType}
          setTrigger={setTrigger}
        />
      )}*/}

      {/*  ----- index des type de prestation ------ */}
      {showIndexType && (
        <Offcanvas
          placement={'end'}
          show={showIndexType}
          onHide={() => {
            setShowIndexType(false);
            window.location.reload();
          }}
          className={'w-50'}
        >
          <Offcanvas.Header className="pb-0">
            <Offcanvas.Title>
              Gestion des type de prestation
              <a
                className="cursor-pointer ms-2"
                onClick={() => {
                  setShowManageType(true);
                  setSelectedType({});
                }}
              >
                <small>+ Ajouter</small>
              </a>{' '}
            </Offcanvas.Title>
            <FalconCloseButton
              onClick={() => {
                setShowIndexType(false);
              }}
            />
          </Offcanvas.Header>

          <Offcanvas.Body>
            {types && types.length > 0 && (
              <Table>
                <tbody>
                  {types.map((type, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <span
                          className="rounded-circle d-inline-block me-2"
                          style={{
                            backgroundColor: type.color,
                            height: '18px',
                            width: '18px',
                            position: 'relative',
                            top: '1px'
                          }}
                        ></span>
                      </td>
                      <td>{type.name}</td>

                      <td className="text-end">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="hi">Modifier</Tooltip>}
                        >
                          <a
                            onClick={() => {
                              setSelectedType(type);
                              setShowManageType(true);
                            }}
                            className="cursor-pointer me-3"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                fill="rgba(44,123,229,1)"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>

                        {/* <span>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="hi">Supprimer</Tooltip>}
                          >
                            <a
                              href="#"
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedType(type);
                                setShowCanvaDelete(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '1px',
                                  marginLeft: '3px'
                                }}
                              >
                                <path
                                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                  fill="#e63757"
                                />
                              </svg>
                            </a>
                          </OverlayTrigger>
                        </span>*/}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};
export default IndexTypePrestation;

IndexTypePrestation.propTypes = {
  showIndexType: PropTypes.bool,
  setShowIndexType: PropTypes.func
};
