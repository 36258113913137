import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';

const BreadCrumbLibrary = ({
  history,
  setCurrentFolder,
  setBreadcrumbHistory,
  setSelectedObject,
  setTrigger
}) => {
  return (
    <Breadcrumb className={'p-3 pt-0'}>
      <Breadcrumb.Item
        href="#"
        className={'prevent-select'}
        onClick={() => {
          setCurrentFolder({});
          setBreadcrumbHistory([]);
          setSelectedObject({});
        }}
      >
        Accueil
      </Breadcrumb.Item>
      {history.map((folder, index) => (
        <Breadcrumb.Item
          href="#"
          className={'prevent-select'}
          key={index}
          onClick={() => {
            setCurrentFolder(folder);
            let tmpHistory = [...history];
            tmpHistory = tmpHistory.splice(0, index + 1);
            setBreadcrumbHistory(tmpHistory);
            setSelectedObject({});
            setTrigger(Date.now());
          }}
        >
          {folder.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

// write BreadCrumb proptypes
BreadCrumbLibrary.propTypes = {
  history: PropTypes.array.isRequired,
  setCurrentFolder: PropTypes.func.isRequired,
  setBreadcrumbHistory: PropTypes.func.isRequired,
  setSelectedObject: PropTypes.func.isRequired,
  setTrigger: PropTypes.func.isRequired
};

export default BreadCrumbLibrary;
