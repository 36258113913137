import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  PutPrestation,
  ShowPrestation
} from '../../../../services/PrestationService';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';
import FalconCloseButton from '../../../common/FalconCloseButton';
import Select from 'react-select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { IndexCustomer } from '../../../../services/CustomerService';
import { IndexPrestationsType } from '../../../../services/PrestationTypeService';
import DatePicker from 'react-datepicker';
import * as Moment from 'moment';
import moment from 'moment/moment';
import hours from '../../commons/data/hours';
import { IndexUser } from '../../../../services/UserService';

const ManageInfoPrestation = ({
  showManagePrestation,
  setShowManagePrestation,
  prestation,
  setParentTrigger
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [labelType, setLabelType] = useState(false);
  const [modifyLoc, setModifyLoc] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    address: '',
    address_details: '',
    name: '',
    type: '',
    date: '',
    time: '',
    time_team: '',
    time_team_end: '',
    guest_count: '',
    latitude: '',
    longitude: ''
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let tmpFormData = { ...formData };
    tmpFormData.date = Moment(tmpFormData.date).local().format('YYYY-MM-DD');
    tmpFormData.type_id = tmpFormData.type.value;
    tmpFormData.context = 'base';
    tmpFormData.customer_id = tmpFormData.customer?.value;
    tmpFormData.user_id = tmpFormData.user?.value;
    tmpFormData.time = moment(tmpFormData.time, 'HH:mm').format('HH:mm:ss');
    tmpFormData.time_team = moment(tmpFormData.time_team, 'HH:mm').format(
      'HH:mm:ss'
    );
    tmpFormData.time_team_end = moment(
      tmpFormData.time_team_end,
      'HH:mm'
    ).format('HH:mm:ss');
    let response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      toast('Prestation enregistrée');
      setIsLoadingSubmit(true);
      setErrorForm(false);
      setShowManagePrestation(false);
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue');
      setErrorForm(response.validator);
    }
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const fetchPrestation = async () => {
      setIsLoading(true);
      const response = await ShowPrestation(prestation.id);
      let responsePresta = response.data;
      if (response.success === true) {
        let tmpArray = [];
        let tmpType = [];
        tmpArray.push(response.data.type);
        tmpArray.map(type => {
          tmpType.push({
            value: type?.id,
            label: (
              <>
                <span
                  style={{
                    backgroundColor: type?.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                  className="rounded-circle d-inline-block me-2"
                ></span>
                {type?.name}
              </>
            )
            /*   label: type.name*/
          });
        });
        setIsLoading(false);
        setFormData({
          name: responsePresta.name,
          address: responsePresta.address,
          address_details: responsePresta.address_details,
          type: tmpType,
          date: new Date(responsePresta.date),
          time: moment(responsePresta.time, 'HH:mm:ss').format('HH:mm'),
          time_team: moment(responsePresta.time_team, 'HH:mm:ss').format(
            'HH:mm'
          ),
          time_team_end: moment(
            responsePresta.time_team_end,
            'HH:mm:ss'
          ).format('HH:mm'),
          guest_count: responsePresta.guest_count,
          latitude: responsePresta.latitude,
          longitude: responsePresta.longitude,
          customer: {
            value: responsePresta.customer?.id,
            label: responsePresta.customer?.name
          },
          contact_name: responsePresta.contact_name,
          contact_phone: responsePresta.contact_phone,
          user: {
            value: responsePresta.user?.id,
            label: `${responsePresta.user?.firstname} ${responsePresta.user?.lastname}`
          }
        });
      } else {
        toast('Une erreur est survenue');
      }
    };
    const fetchType = async () => {
      const responseTypes = await IndexPrestationsType();
      if (responseTypes.success === true) {
        /*     setOriginalType(responseTypes.data);*/
        let tmpTypes = [];
        responseTypes.data.map(type => {
          tmpTypes.push({
            value: type.id,
            label: (
              <>
                <span
                  style={{
                    backgroundColor: type.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                  className="rounded-circle d-inline-block me-2"
                ></span>
                {type.name}
              </>
            )

            /* label: type.name*/
          });
        });
        setLabelType(tmpTypes);
      }
    };
    const fetchCustomers = async () => {
      const responseCustomers = await IndexCustomer(true);
      if (responseCustomers.success === true) {
        let tmpCustomer = [];
        responseCustomers.data.map(customer => {
          tmpCustomer.push({
            value: customer.id,
            label: customer.name
          });
        });
        setCustomers(tmpCustomer);
      }
    };
    const fetchUsers = async () => {
      const responseUsers = await IndexUser();
      if (responseUsers.success === true) {
        let tmpUsers = [];
        responseUsers.data
          .filter(user => user.role_id === 1)
          .map(user => {
            tmpUsers.push({
              value: user.id,
              label: `${user.firstname} ${user.lastname}`
            });
          });
        setUsers(tmpUsers);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchType();
    fetchUsers();
    fetchCustomers();
    if (prestation.id) {
      fetchPrestation();
    }
  }, []);

  return (
    <>
      {prestation && showManagePrestation && (
        <Offcanvas
          className={'w-50'}
          show={showManagePrestation}
          onHide={() => {
            setShowManagePrestation(false);
            setErrorForm(false);
          }}
          placement="end"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3 className="text-primary admin-title mb-0">
                {prestation && prestation.id
                  ? 'Modifier la prestation'
                  : 'Ajouter'}
              </h3>
            </Offcanvas.Title>
            <FalconCloseButton
              onClick={() => {
                setShowManagePrestation(false);
                setErrorForm(false);
              }}
            />
          </Offcanvas.Header>
          {isLoading === true && (
            <Offcanvas.Body>
              <Row>
                <Col xs={12} className="mt-0">
                  <Spinner animation="border" role="status" size="xs">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            </Offcanvas.Body>
          )}
          {isLoading === false && (
            <Offcanvas.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              <Col md={12}>
                <Col md={12}>
                  <Form.Group controlId="search">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                          fill="rgba(93,110,130,1)"
                        />
                      </svg>
                      Sélectionner un client
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      options={customers}
                      placeholder="Nom, adresse, ville..."
                      name="search"
                      classNamePrefix="react-select"
                      value={formData.customer || ''}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          customer: value
                        });
                      }}
                    />
                  </Form.Group>
                  <Row className="mt-3" md={12}>
                    {/* ------- Nom de la prestation -------- */}
                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Nom du contact </Form.Label>
                        <Form.Control
                          placeholder="Nom du contact"
                          name="contact_name"
                          value={formData.contact_name}
                          onChange={handleFieldChange}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Numéro de téléphone du contact </Form.Label>
                        <Form.Control
                          placeholder="Numéro de téléphone du contact"
                          name="contact_phone"
                          value={formData.contact_phone}
                          onChange={handleFieldChange}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={'mt-3'}>
                    {/* ------- Nom de la prestation -------- */}
                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>Nom de la prestation</Form.Label>
                        <Form.Control
                          placeholder="Écrire le nom de la prestation"
                          name="name"
                          value={formData.name}
                          onChange={handleFieldChange}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* ------ type de prestation -------*/}
                    <Col md={6}>
                      <Form.Group controlId="type">
                        <Form.Label>Type de prestation</Form.Label>
                        <Select
                          name="type"
                          closeMenuOnSelect={true}
                          options={labelType}
                          placeholder="Choisir un type..."
                          classNamePrefix="react-select"
                          value={formData.type}
                          onChange={value => {
                            setFormData({
                              ...formData,
                              type: value
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                {/*---------- Lieu de la prestation ---------   */}
                <Row>
                  <Col md={6} className={'mt-3'}>
                    <Form.Group controlId="address">
                      <Form.Label>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                            fill="rgba(94,110,130,1)"
                          />
                        </svg>{' '}
                        {/*---------- Lieu de la prestation ---------   */}
                        Lieu de la prestation{' '}
                        <a
                          className="cursor-pointer pb-1 ps-1"
                          onClick={() => {
                            setModifyLoc(true);
                          }}
                        >
                          <small>+ Modifier le lieu</small>
                        </a>
                      </Form.Label>
                      {prestation.id && !modifyLoc ? (
                        <Col>
                          <p>
                            <a
                              href={`https://www.google.com/maps/place/${formData.address}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {formData.address}
                            </a>
                          </p>
                        </Col>
                      ) : (
                        <Col>
                          <GooglePlacesAutocomplete
                            apiOptions={{
                              language: 'fr',
                              region: 'fr'
                            }}
                            selectProps={{
                              noOptionsMessage: () =>
                                'Tapez un lieu dans la barre de recherche',
                              loadingMessage: () => 'Chargement en cours...',
                              placeholder: 'Commencez à chercher...',
                              onChange: object =>
                                geocodeByPlaceId(object.value.place_id)
                                  .then(results =>
                                    setFormData({
                                      ...formData,
                                      address: results[0].formatted_address,
                                      latitude:
                                        results[0].geometry.location.lat(),
                                      longitude:
                                        results[0].geometry.location.lng()
                                    })
                                  )
                                  .catch(error => console.error(error))
                            }}
                          />
                        </Col>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className={'mt-3'}>
                    <Form.Group controlId="name">
                      <Form.Label>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="17"
                          height="17"
                          style={{ marginRight: '4px', marginBottom: '2px' }}
                        >
                          <path
                            d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                            fill="rgba(94,110,130,1)"
                          />
                        </svg>{' '}
                        Complément d'adresse
                      </Form.Label>
                      <Form.Control
                        placeholder="Compléter l'adresse..."
                        name="address_details"
                        value={formData.address_details}
                        onChange={handleFieldChange}
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Row className="mt-3">
                <Col md={3}>
                  <Form.Group controlId="date">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Date
                    </Form.Label>
                    <DatePicker
                      showYearDropdown
                      name="date"
                      dateFormat="dd/MM/yyyy"
                      locale="fr"
                      className="form-control"
                      placeholderText="Date de début"
                      selected={formData.date}
                      value={formData.date}
                      onChange={date => {
                        setFormData({
                          ...formData,
                          date: date
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                {/*---- horaire -----*/}
                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Horaire
                    </Form.Label>
                    <Form.Select
                      name="time"
                      onChange={handleFieldChange}
                      value={formData.time}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Horaire arrivée AVS
                    </Form.Label>
                    <Form.Select
                      name="time_team"
                      onChange={handleFieldChange}
                      value={formData.time_team}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Horaire départ AVS
                    </Form.Label>
                    <Form.Select
                      name="time_team_end"
                      onChange={handleFieldChange}
                      value={formData.time_team_end}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/*---- convives ----*/}
                <Col md={3}>
                  <Form.Group controlId="guest_count">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>
                      Convives
                    </Form.Label>
                    <Form.Control
                      name="guest_count"
                      value={formData.guest_count}
                      onChange={handleFieldChange}
                      type="number"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="search" className="mt-4 mb-3">
                <Form.Label>
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    style={{ marginRight: '4px', marginBottom: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                      fill="rgba(93,110,130,1)"
                    />
                  </svg>
                  Prestation suivie par
                </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={users}
                  placeholder="Nom, prénom..."
                  name="search"
                  classNamePrefix="react-select"
                  value={formData.user || ''}
                  onChange={value => {
                    setFormData({
                      ...formData,
                      user: value
                    });
                  }}
                />
              </Form.Group>
              {/* ----- submit -----*/}
              <Button
                variant="primary"
                className="admin-primary mt-3"
                size="sm"
                onClick={handleSubmit}
                disabled={isLoadingSubmit}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Enregistrer les modifications
              </Button>
            </Offcanvas.Body>
          )}
        </Offcanvas>
      )}
    </>
  );
};

export default ManageInfoPrestation;

ManageInfoPrestation.propTypes = {
  setShowManagePrestation: PropTypes.func,
  showManagePrestation: PropTypes.bool,
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func
};
