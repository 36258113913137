import React, { useEffect, useState } from 'react';
import Calendar from './calendar/Calendar';
import {
  Card,
  Col,
  Row,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ExportPrestations,
  IndexPrestation,
  IndexPrestationArchive
} from '../../../services/PrestationService';
import { toast } from 'react-toastify';
import ListServices from './calendar/ListServices';
import IndexTypePrestation from './type';
import IconAlert from '../../common/IconAlert';
import PropTypes from 'prop-types';
import { IndexProductionZone } from '../../../services/ProductionZoneService';

const IndexService = ({ isArchive }) => {
  const [showIndexType, setShowIndexType] = useState(false);
  const [eventsDisplayMode, setEventsDisplayMode] = useState('calendar');
  const [exportUrl, setExportUrl] = useState('');
  const [prestations, setPrestations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const isColorLight = hex => {
    if (hex === undefined || hex === null) return false;
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex?.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex?.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex?.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };
  const navigate = useNavigate();
  const exportPrestations = async () => {
    const responseExport = await ExportPrestations();
    if (responseExport.success === true) {
      setExportUrl(responseExport.data);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  useEffect(() => {
    const fetchPrestations = async () => {
      setIsLoading(true);
      let responsePrestation = false;
      if (isArchive === true) {
        responsePrestation = await IndexPrestationArchive();
      } else {
        responsePrestation = await IndexPrestation();
      }
      if (responsePrestation.success === true) {
        setIsLoading(false);
        let prestationsArray = [];
        responsePrestation.data?.map(presta => {
          let backgroundColor = '#ccf2e1';
          if (
            !localStorage.getItem('selectedColorViewAVS') ||
            (localStorage.getItem('selectedColorViewAVS') &&
              localStorage.getItem('selectedColorViewAVS') === 'type')
          ) {
            backgroundColor = presta.type?.color;
          } else if (
            localStorage.getItem('selectedColorViewAVS') &&
            localStorage.getItem('selectedColorViewAVS') === 'status'
          ) {
            backgroundColor = presta.status?.color;
          }
          prestationsArray.push({
            id: presta.id,
            title: `${presta.name.replace('Prestation de', ' ')}`,
            icon: (
              <>
                {presta.status &&
                  ['validee', 'a-facturer', 'finalisee'].includes(
                    presta.status.slug
                  ) && (
                    <svg
                      style={{
                        width: '18px'
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill={
                          isColorLight(backgroundColor) ? '#000000' : '#FFFFFF'
                        }
                      ></path>
                    </svg>
                  )}
              </>
            ),
            address: presta.address,
            start: `${presta.date}`,
            end: `${presta.date}`,
            time: presta.time,
            typeName: presta.type?.name,
            typeColor: presta.type?.color,
            statusName: presta.status?.name,
            statusColor: presta.status?.color,
            status: presta.status.name ? presta.status.name : '',
            originalType: presta.type,
            guest_count: presta.guest_count,
            location_lat: presta.latitude,
            location_lng: presta.longitude,
            type: presta?.type?.name ? presta.type.name : '',
            backgroundColor: backgroundColor,
            textColor: isColorLight(backgroundColor) ? '#000000' : '#FFFFFF'
          });
          setPrestations(prestationsArray);
        });
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };
    if (localStorage.getItem('avsEventsDisplayMode')) {
      setEventsDisplayMode(localStorage.getItem('avsEventsDisplayMode'));
    } else {
      localStorage.setItem('calendar', 'eventsDisplayMode');
    }
    const fetchAllProductionZones = async () => {
      const responseProductionZones = await IndexProductionZone();
      if (responseProductionZones.success === true) {
        setZones(responseProductionZones.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchAllProductionZones();
    fetchPrestations();
  }, [isArchive]);
  return (
    <>
      {showIndexType && (
        <IndexTypePrestation
          showIndexType={showIndexType}
          setShowIndexType={setShowIndexType}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-sm-between align-items-center">
            <Col lg={5}>
              <h3 className="text-primary admin-title mb-0">
                {isArchive === true
                  ? 'Archives'
                  : eventsDisplayMode === 'list'
                  ? 'Liste des prestations'
                  : 'Calendrier'}
              </h3>
            </Col>

            <Col lg={7} className="d-flex justify-content-end">
              {!isArchive && (
                <DropdownButton
                  as={ButtonGroup}
                  variant="falcon-default"
                  size="sm"
                  style={{ position: 'static', zIndex: '1' }}
                  title={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Fiches de production
                    </>
                  }
                  className="me-2"
                >
                  {zones.map((zone, index) => (
                    <Dropdown.Item
                      eventKey="1"
                      key={index}
                      onClick={() => {
                        navigate(`/productions/${zone.id}`);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{
                          marginBottom: '2px',
                          marginRight: '2px'
                        }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                      </svg>
                      {zone.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}
              {!isArchive && (
                <DropdownButton
                  as={ButtonGroup}
                  variant="falcon-default"
                  size="sm"
                  style={{ position: 'static', zIndex: '1' }}
                  title={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      Gérer
                    </>
                  }
                  className="me-2"
                >
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      setShowIndexType(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{
                        marginBottom: '2px',
                        marginRight: '2px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                    </svg>{' '}
                    Gestion des types de prestation
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      exportPrestations();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{
                        marginBottom: '2px',
                        marginRight: '2px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM4 4.73481V19.2656L14 20.6942V3.30624L4 4.73481ZM17 19.0002H20V5.00022H17V3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V19.0002ZM10.2 12.0002L13 16.0002H10.6L9 13.7145L7.39999 16.0002H5L7.8 12.0002L5 8.00022H7.39999L9 10.2859L10.6 8.00022H13L10.2 12.0002Z" />
                    </svg>{' '}
                    Exporter le calendrier
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      localStorage.setItem('avsEventsDisplayMode', 'list');
                      setTimeout(() => {
                        window.location.href = '/calendrier/archives';
                      }, 350);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{
                        marginBottom: '2px',
                        marginRight: '2px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M3 10H2V4.00293C2 3.44903 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.43788 22 4.00293V10H21V20.0015C21 20.553 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5525 3 20.0015V10ZM19 10H5V19H19V10ZM4 5V8H20V5H4ZM9 12H15V14H9V12Z" />
                    </svg>{' '}
                    Voir les prestations archivées
                  </Dropdown.Item>
                </DropdownButton>
              )}

              {isArchive && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="hi">
                      Revenir à la liste des prestations
                    </Tooltip>
                  }
                >
                  <Button
                    className="ms-2"
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      navigate('/calendrier');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" />
                    </svg>
                  </Button>
                </OverlayTrigger>
              )}
              {!isArchive && (
                <div
                  className="px-0 theme-control-toggle float-end pe-2 ps-2"
                  onClick={() => {
                    setEventsDisplayMode(
                      eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                    );
                    localStorage.setItem(
                      'avsEventsDisplayMode',
                      eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                    );
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="hi">
                        Affichage en mode{' '}
                        {eventsDisplayMode === 'list' ? 'calendrier' : 'liste'}
                      </Tooltip>
                    }
                  >
                    <div className="theme-control-toggle-label">
                      <FontAwesomeIcon
                        icon={
                          eventsDisplayMode === 'list' ? 'calendar-alt' : 'list'
                        }
                        transform="shrink-7"
                        className="fs-4"
                        style={{ padding: '3px' }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              )}
              {!isArchive && (
                <Button
                  variant="primary"
                  className="admin-primary flex-between-end"
                  size="sm"
                  as={Link}
                  to="/prestation/ajouter"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Créer une prestation
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {exportUrl && (
        <IconAlert
          variant="success"
          dismissible
          onClose={() => setExportUrl('')}
          className="mt-3"
        >
          <p className="mb-0">
            Export Excel du calendrier disponible :{' '}
            <a href={exportUrl}>cliquez ici pour le télécharger</a>
          </p>
        </IconAlert>
      )}

      {/*  --------   Calendrier des administrateur ------------    */}
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {!isLoading && eventsDisplayMode === 'calendar' && (
        <Calendar prestations={prestations} isLoading={isLoading} />
      )}

      {/* ---- Format list des prestations -----*/}
      {!isLoading && eventsDisplayMode === 'list' && (
        <>
          {prestations.length > 0 && (
            <ListServices
              prestations={prestations}
              isLoading={isLoading}
              isArchive={isArchive}
            />
          )}
        </>
      )}
    </>
  );
};

IndexService.propTypes = {
  isArchive: PropTypes.bool
};

export default IndexService;
