import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';
import { PutContact, PostContact } from '../../../services/ContactService';

const ManageContact = ({
  setGrandParentTrigger,
  showModifyContact,
  setShowModifyContact,
  selectedCustomer,
  selectedParentCustomer,
  selectedContact,
  setTrigger,
  setSelectedContact
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    customer_id: '',
    lastname: '',
    firstname: '',
    email: ''
  });
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let response = false;
    let tmpFormData = { ...formData };
    tmpFormData = {
      customer_id: selectedCustomer.id,
      email: tmpFormData.email,
      lastname: tmpFormData.lastname,
      firstname: tmpFormData.firstname
    };
    if (selectedContact && selectedContact.id) {
      response = await PutContact(selectedContact.id, tmpFormData);
    } else {
      response = await PostContact(tmpFormData);
    }
    if (response.success === true) {
      setShowModifyContact(false);
      toast(
        `le contact ${tmpFormData.firstname} ${tmpFormData.lastname}  a été ${
          selectedContact && selectedContact.id ? 'modifié' : 'ajouté'
        }`
      );
      setTrigger(Date.now());
      setGrandParentTrigger(Date.now());
      /* setShowModifyContact(false);*/
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };
  useEffect(() => {
    if (selectedContact && selectedContact.id) {
      setFormData({
        ...formData,
        customer_id:
          selectedCustomer &&
          selectedCustomer.id &&
          selectedParentCustomer &&
          selectedParentCustomer.id,
        lastname: selectedContact.lastname,
        firstname: selectedContact.firstname,
        email: selectedContact.email
      });
    } else {
      setFormData({
        ...formData,
        customer_id:
          selectedCustomer &&
          selectedCustomer.id &&
          selectedParentCustomer &&
          selectedParentCustomer.id,
        lastname: '',
        firstname: '',
        email: ''
      });
    }
  }, [selectedContact]);

  return (
    <>
      {showModifyContact && (
        <Offcanvas
          show={showModifyContact}
          onHide={() => {
            setShowModifyContact(false);
            setSelectedContact({});
          }}
          placement={'end'}
        >
          <Offcanvas.Header
            closeButton={() => {
              setShowModifyContact(false);
              setSelectedContact({});
            }}
          >
            <Offcanvas.Title>
              <h3 className="text-primary admin-title mb-0">
                {selectedContact && selectedContact.id
                  ? 'Modifier le contact'
                  : `Ajouter un contact`}
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <Col>
                <Form.Group controlId="lastname">
                  <Form.Label>Nom du contact :</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>{' '}
              <Col className="mt-2">
                <Form.Group controlId="firstname">
                  <Form.Label>Prénom du contact :</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2">
                <Form.Group controlId="name">
                  <Form.Label>Email du contact :</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  {selectedContact && selectedContact.id
                    ? 'Enregistrer les modifications'
                    : 'Ajouter le contact'}
                </Button>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageContact.propTypes = {
  selectedContact: PropTypes.object,
  selectedCustomer: PropTypes.object,
  selectedParentCustomer: PropTypes.object,
  showModifyContact: PropTypes.bool,
  setShowModifyContact: PropTypes.func,
  setTrigger: PropTypes.func,
  setGrandParentTrigger: PropTypes.func,
  setSelectedContact: PropTypes.func
};

export default ManageContact;
