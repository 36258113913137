import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Spinner
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  PostFolder,
  PutFolder,
  IndexFolder,
  DeleteFolder
} from '../../../services/FolderService';
import { DeleteFile, PostFile, PutFile } from '../../../services/FileService';
import OffCanvaFolder from './folder/OffCanvaFolder';
import OffCanvaFile from './file/OffCanvaFile';
import OffCanvaFolderDelete from './folder/OffCanvaFolderDelete';
import BreadCrumbLibrary from './breadcrumb';
import DisplayFolder from './DisplayFolder';
import DisplayFile from './DisplayFile';
import OffCanvaFileDelete from './file/OffCanvaFileDelete';

const Manage = () => {
  const [showCanvaFolder, setShowCanvaFolder] = useState(false); // Bool ouvre et ferme le canva pour les folders
  const [showCanvaFolderDelete, setShowCanvaFolderDelete] = useState(false); // Bool ouvre et ferme le canva pour delete le folders
  const [showCanvaFile, setShowCanvaFile] = useState(false); // Bool ouvre et ferme le canva pour les files
  const [showCanvaFileDelete, setShowCanvaFileDelete] = useState(false); // Bool ouvre et ferme le canva pour delete les files
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState(false); // Pour seléctionner un dossier
  const [trigger, setTrigger] = useState(Date.now()); // Mise à jour automatique d'un composant
  const [objects, setObjects] = useState([]); // Tout les dossiers ( folders )
  const [currentFolder, setCurrentFolder] = useState({}); // Tout les dossiers ( folders )
  const [splittedFolders, setSplittedFolders] = useState([]);
  const [breadcrumbHistory, setBreadcrumbHistory] = useState([]);
  const [errorFormFolders, setErrorFormFolders] = useState(false);
  const [errorFormFiles, setErrorFormFiles] = useState(false);
  const [formDataFolder, setFormDataFolder] = useState({
    name: '',
    parent_id: ''
  });
  const [formDataFile, setFormDataFile] = useState({
    name: '',
    url: '',
    folder_id: ''
  });

  useEffect(() => {
    const fetchFolders = async () => {
      setIsLoading(true);
      const responseFolders = await IndexFolder();
      if (responseFolders.success === true) {
        setIsLoading(false);
        setObjects(responseFolders.data.filter(f => !f.parent_id));

        let tmpTreeView = [];
        let splittedFolders = [];
        responseFolders.data
          .filter(f => !f.parent_id)
          .map(object => {
            getRecursiveFolders(object, tmpTreeView);
            getRecursiveFoldersAll(object, splittedFolders);
          });
        setSplittedFolders(splittedFolders);

        if (currentFolder && currentFolder.id) {
          setCurrentFolder(
            splittedFolders.find(f => f.id === currentFolder.id)
          );
          setSelectedObject({});
        }

        let selected = document.getElementsByClassName('bg-soft-info');
        Array.from(selected).forEach(item => {
          item.classList.remove('bg-soft-info');
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchFolders();
  }, [trigger]);
  // Fonction recursive
  const getRecursiveFolders = (folder, folders) => {
    let tmpChildrens = [];
    if (folder.childrens && folder.childrens.length > 0) {
      folder.childrens.map(children => {
        getRecursiveFolders(children, tmpChildrens);
      });
    }
    folders.push({
      name: folder.name,
      id: folder.id,
      children: tmpChildrens
    });
  };

  /* Fonction récursive */
  const getRecursiveFoldersAll = (folder, folders) => {
    folders.push(folder);
    if (folder.childrens && folder.childrens.length > 0) {
      folder.childrens.map(children => {
        getRecursiveFoldersAll(children, folders);
      });
    }
  };

  const handleFieldChange = e => {
    setFormDataFolder({
      ...formDataFolder,
      [e.target.name]: e.target.value
    });
  };

  const handleFieldChangeFile = e => {
    setFormDataFile({
      ...formDataFile,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitFolders = async e => {
    e.preventDefault();
    setIsLoading(true);

    let response = false;
    if (selectedObject.id) {
      response = await PutFolder(selectedObject.id, formDataFolder);
    } else {
      response = await PostFolder(formDataFolder);
    }
    setErrorFormFolders(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Dossier enregistrée.`;
      toast(successMessage);
      setTimeout(function () {
        setShowCanvaFolder(false);
      }, 1000);
      setFormDataFolder({
        name: '',
        parent_id: ''
      });
      setTrigger(Date.now());
      if (currentFolder.id) {
        setCurrentFolder(splittedFolders.find(f => f.id === currentFolder.id));
      }
    } else {
      toast('Un problème est survenue, veuillez réesayer plus tard');
    }
  };

  const handleDeleteFolder = async folder => {
    const response = await DeleteFolder(folder.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Dossier supprimée avec succès');
      setTimeout(function () {
        setShowCanvaFolderDelete(false);
      }, 1000);
      setSelectedObject({});
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
      toast('Un problème est survenue, veuillez réesayer plus tard');
    }
  };

  const handleSubmitFiles = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (selectedObject.id) {
      response = await PutFile(selectedObject.id, formDataFile);
    } else {
      response = await PostFile(formDataFile);
    }
    setErrorFormFiles(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Fichier enregistrée.`;
      toast(successMessage);
      setTimeout(function () {
        setShowCanvaFile(false);
      }, 1000);
      setFormDataFile({
        name: '',
        url: '',
        folder_id: ''
      });
      setTrigger(Date.now());
    } else {
      toast('Un problème est survenue, veuillez réesayer plus tard');
    }
  };

  const handleDeleteFile = async file => {
    const response = await DeleteFile(file.id);
    if (response.success === true) {
      setIsLoading(false);
      toast('Fichier supprimée avec succès');
      setTimeout(function () {
        setShowCanvaFileDelete(false);
      }, 1000);
      setSelectedObject({});
      setTrigger(Date.now());
    } else {
      setIsLoading(false);
      toast('Un problème est survenue, veuillez réesayer plus tard');
    }
  };

  return (
    <>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}

      {/*    ------- Canva pour la création et édition des dossiers -------*/}
      {showCanvaFolder && (
        <>
          <OffCanvaFolder
            showCanvaFolder={showCanvaFolder}
            setShowCanvaFolder={setShowCanvaFolder}
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
            formDataFolder={formDataFolder}
            setFormDataFolder={setFormDataFolder}
            splittedFolders={splittedFolders}
            handleSubmitFolders={handleSubmitFolders}
            handleFieldChange={handleFieldChange}
            currentFolder={currentFolder}
            errorFormFolders={errorFormFolders}
            setErrorFormFolders={setErrorFormFolders}
          />
        </>
      )}

      {showCanvaFolderDelete && (
        <>
          <OffCanvaFolderDelete
            handleDeleteFolder={handleDeleteFolder}
            selectedObject={selectedObject}
            showCanvaFolderDelete={showCanvaFolderDelete}
            setShowCanvaFolderDelete={setShowCanvaFolderDelete}
          />
        </>
      )}

      {/* ------- Canva pour la création et édition des fichiers -------*/}

      {showCanvaFile && (
        <>
          <OffCanvaFile
            showCanvaFile={showCanvaFile}
            setShowCanvaFile={setShowCanvaFile}
            formDataFile={formDataFile}
            setFormDataFile={setFormDataFile}
            handleFieldChangeFile={handleFieldChangeFile}
            handleSubmitFiles={handleSubmitFiles}
            selectedObject={selectedObject}
            currentFolder={currentFolder}
            setSelectedObject={setSelectedObject}
            errorFormFiles={errorFormFiles}
            setErrorFormFiles={setErrorFormFiles}
          />
        </>
      )}

      {showCanvaFileDelete && (
        <>
          <OffCanvaFileDelete
            handleDeleteFile={handleDeleteFile}
            selectedObject={selectedObject}
            showCanvaFileDelete={showCanvaFileDelete}
            setShowCanvaFileDelete={setShowCanvaFileDelete}
          />
        </>
      )}

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={5}>
              <h3 className="text-primary admin-title mb-0">
                {currentFolder && currentFolder.id && (
                  <Button
                    variant="falcon-primary"
                    className="me-3 mb-1"
                    onClick={() => {
                      let parent = splittedFolders.find(
                        folder => folder.id === currentFolder.parent_id
                      );
                      if (
                        parent &&
                        (parent.id === null || parent.id === undefined)
                      ) {
                        setCurrentFolder({});
                      } else {
                        setCurrentFolder(parent);
                      }
                      let tmpHistory = [...breadcrumbHistory];
                      tmpHistory.pop();
                      setBreadcrumbHistory(tmpHistory);
                      setSelectedObject({});
                      setTrigger(Date.now());
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                  </Button>
                )}
                {currentFolder && currentFolder.id
                  ? currentFolder.name
                  : 'Médiathèque'}
              </h3>
            </Col>
            <Col xs={7} className="text-end">
              {selectedObject && selectedObject.id && (
                <>
                  <DropdownButton
                    variant="falcon-default"
                    size="sm"
                    title={'Actions'}
                    as={ButtonGroup}
                    className={'ms-3'}
                    style={{ position: 'static', zIndex: '10000' }}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        if (selectedObject && selectedObject.files) {
                          setShowCanvaFolder(true);
                        } else {
                          setShowCanvaFile(true);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{
                          marginBottom: '2px',
                          marginRight: '2px'
                        }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z" />
                      </svg>
                      Modifier
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        if (selectedObject && selectedObject.files) {
                          setShowCanvaFolderDelete(true);
                        } else {
                          setShowCanvaFileDelete(true);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{
                          marginBottom: '2px',
                          marginRight: '2px'
                        }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" />
                      </svg>
                      Supprimer
                    </Dropdown.Item>
                  </DropdownButton>
                </>
              )}
              {currentFolder && currentFolder.id && (
                <Button
                  variant="primary"
                  className="ms-3 admin-primary"
                  size="sm"
                  onClick={() => {
                    setSelectedObject({});
                    setShowCanvaFile(true);

                    let selected =
                      document.getElementsByClassName('bg-soft-info');
                    Array.from(selected).forEach(item => {
                      item.classList.remove('bg-soft-info');
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path
                      d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Ajouter un fichier
                </Button>
              )}
              <Button
                variant="primary"
                className="ms-3 admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowCanvaFolder(true);

                  let selected =
                    document.getElementsByClassName('bg-soft-info');
                  Array.from(selected).forEach(item => {
                    item.classList.remove('bg-soft-info');
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Créer un dossier
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body className="overflow-hidden px-lg-3">
          {objects.length > 0 && (
            <>
              {currentFolder &&
              currentFolder.id &&
              currentFolder !== undefined ? (
                <Row>
                  <BreadCrumbLibrary
                    history={breadcrumbHistory}
                    setCurrentFolder={setCurrentFolder}
                    setBreadcrumbHistory={setBreadcrumbHistory}
                    setSelectedObject={setSelectedObject}
                    setTrigger={setTrigger}
                  />

                  {currentFolder.childrens?.map((folder, index) => (
                    <>
                      <DisplayFolder
                        key={index}
                        folder={folder}
                        index={index}
                        setCurrentFolder={setCurrentFolder}
                        setBreadcrumbHistory={setBreadcrumbHistory}
                        breadcrumbHistory={breadcrumbHistory}
                        currentFolder={currentFolder}
                        setSelectedObject={setSelectedObject}
                      />
                    </>
                  ))}

                  {currentFolder.files?.map((file, index) => (
                    <DisplayFile
                      key={index}
                      file={file}
                      index={index}
                      setSelectedObject={setSelectedObject}
                    />
                  ))}
                </Row>
              ) : (
                <Row>
                  {objects.map((folder, index) => (
                    <DisplayFolder
                      key={index}
                      folder={folder}
                      index={index}
                      setCurrentFolder={setCurrentFolder}
                      setBreadcrumbHistory={setBreadcrumbHistory}
                      breadcrumbHistory={breadcrumbHistory}
                      currentFolder={currentFolder}
                      setSelectedObject={setSelectedObject}
                    />
                  ))}
                </Row>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Manage;
