import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import {
  IndexResourceCategories,
  PostResourceCategories,
  PutResourceCategories
} from '../../../../services/ResourceCategoriesService';

const ManageResourceCategory = ({
  showManageCategories,
  setShowManageCategories,
  selectedCategory,
  setLabelCategoriesResource,
  setTrigger,
  handleGetCategory
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedCategory.id) {
      response = await PutResourceCategories(selectedCategory.id, formData);
    } else {
      response = await PostResourceCategories(formData);
    }
    if (response.success === true) {
      setIsLoadingSubmit(true);
      toast('Catégorie enregistrée');
      setTrigger(Date.now());
      setShowManageCategories(false);

      const responseCategory = await IndexResourceCategories();
      if (responseCategory.success === true) {
        let categoryArray = [];
        responseCategory.data.map(cat => {
          handleGetCategory(cat.id, cat.name);
          categoryArray.push({
            value: cat.id,
            label: cat.name
          });
        });
        setLabelCategoriesResource(categoryArray);
      }
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory.id) {
      setFormData({
        ...formData,
        name: selectedCategory.name
      });
    }
  }, []);

  return (
    <>
      <Offcanvas
        show={showManageCategories}
        onHide={() => {
          setShowManageCategories(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowManageCategories(false);
          }}
        >
          <Offcanvas.Title>
            {selectedCategory.id
              ? 'Modifier la catégorie'
              : `Ajouter une catégorie`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="name">
                  <Form.Label>Nom de la catégorie :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Boisson, Vaisselle, Mobilier ..."
                    value={formData.name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  {selectedCategory.id
                    ? 'Enregistrer les modifications'
                    : 'Enregistrer la catégorie'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

//create props
ManageResourceCategory.propTypes = {
  selectedCategory: PropTypes.object,
  setShowManageCategories: PropTypes.func,
  showManageCategories: PropTypes.bool,
  setTrigger: PropTypes.func,
  setLabelCategoriesResource: PropTypes.func,
  handleGetCategory: PropTypes.func
};

export default ManageResourceCategory;
