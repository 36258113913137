import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PostNotes, PutNotes } from '../../../services/NoteService';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';

const NotesModal = ({
  noteableType,
  model,
  setTrigger,
  context,
  setModalShow,
  modalShow,
  selectedNote
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    noteable_type: noteableType,
    noteable_id: model.id,
    content: '',
    context: context,
    show_on_sheet: 1
  });

  const handleSubmit = async e => {
    setIsLoadingSubmit(true);
    e.preventDefault();
    let response = false;
    if (selectedNote && selectedNote.id) {
      response = await PutNotes(selectedNote && selectedNote.id, {
        ...formData,
        show_on_sheet: 1
      });
    } else {
      response = await PostNotes({
        ...formData,
        show_on_sheet: 1
      });
    }
    if (response.success === true) {
      toast('Note enregistrée');
      setModalShow(false);
      setTrigger(Date.now());
      setFormData({
        ...formData,
        content: ''
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (selectedNote && selectedNote.content) {
      setFormData({
        ...formData,
        content: selectedNote.content
      });
    }
  }, [selectedNote, formData.show_on_sheet]);

  return (
    <>
      <Button
        variant="primary"
        className="admin-primary ms-0 mb-3"
        size="sm"
        onClick={() => setModalShow(true)}
      >
        + Ajouter une note
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Note </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/*{context === 'products' && (
            <Form.Check
              type="checkbox"
              id="defaultCheckbox1"
              label="Relier cette note à la fiche de production ?"
              onChange={e => {
                if (e.target.checked) {
                  setFormData({
                    ...formData,
                    show_on_sheet: '1'
                  });
                } else {
                  setFormData({
                    ...formData,
                    show_on_sheet: '0'
                  });
                }
              }}
              defaultChecked={selectedNote && selectedNote.show_on_sheet === 1}
            />
          )}*/}
          {/*{context === 'resources' && (
            <Form.Check
              type="checkbox"
              id="defaultCheckbox1"
              label="Relier cette note à la fiche logistique ?"
              defaultChecked={
                selectedNote &&
                (selectedNote.show_on_sheet === 1 ||
                  selectedNote.show_on_sheet === '1')
              }
              onChange={e => {
                if (e.target.checked) {
                  setFormData({
                    ...formData,
                    show_on_sheet: '1'
                  });
                } else {
                  setFormData({
                    ...formData,
                    show_on_sheet: '0'
                  });
                }
              }}
            />
          )}*/}
          <Col>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form>
              <Form.Group controlId="content">
                <Form.Label>Contenu :</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="content"
                  placeholder="..."
                  value={formData.content}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Form>
          </Col>
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={isLoadingSubmit} onClick={handleSubmit} size="sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
            Enregistrer la note
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NotesModal.propTypes = {
  noteableType: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  setTrigger: PropTypes.func.isRequired,
  context: PropTypes.string,
  checkNoteProductionProduct: PropTypes.bool,
  modalShow: PropTypes.bool,
  selectedNote: PropTypes.object,
  setModalShow: PropTypes.func
};

export default NotesModal;
