import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'products/categories';

export async function IndexProductCategories() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowProductCategory(id, role_id) {
  return modelService.ShowModel(
    `${model}/${id}${role_id && `?role_id=${role_id}`}`
  );
}

export async function PutProductCategories(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostProductCategories(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteProductCategories(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
