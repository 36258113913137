import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Card,
  Col,
  Row,
  Button,
  Form,
  Offcanvas
} from 'react-bootstrap';
import FormAlert from '../../commons/FormAlert';
import { toast } from 'react-toastify';
import { StoreConsumption } from '../../../../services/PrestationSheetService';
const ManagePrestationSheet = ({ prestation, setParentTrigger }) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [formData, setFormData] = useState({
    resources: [],
    butlers: []
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let tmpCategories = [];
    prestation.resources.map(r => {
      tmpCategories.push(r.category);
    });
    tmpCategories = tmpCategories.filter(
      (thing, index, self) =>
        index === self.findIndex(t => t.name === thing.name)
    );
    setCategories(tmpCategories);

    let tmpResources = [];
    prestation.resources?.map(r => {
      tmpResources.push({
        resource_id: r.id,
        consumed: r.pivot.consumed
      });
    });

    let tmpButlers = [];
    prestation.butlers?.map(b => {
      tmpButlers.push({
        butler_id: b.id,
        worked_hours: b.pivot.worked_hours
      });
    });

    setFormData({
      resources: tmpResources,
      butlers: tmpButlers
    });
  }, [prestation]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    const response = await StoreConsumption(prestation.id, formData);
    setErrorForm(response.validator);
    if (response.success === true) {
      toast(`Informations enregistrées`);
      setParentTrigger(Date.now());
      setErrorForm(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmit(false);
  };

  const handleValidate = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let response = await StoreConsumption(prestation.id, {
      ...formData,
      is_service_sheet_validated: true
    });
    setErrorForm(response.validator);
    if (response.success === true) {
      toast(`Informations enregistrées`);
      setParentTrigger(Date.now());
      setErrorForm(false);
      setShowValidate(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmit(false);
  };

  return (
    <>
      <Card className="mt-3 mb-3">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Col className="d-flex ">
                <h5 className="pt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    style={{
                      marginBottom: '2px',
                      marginRight: '3px'
                    }}
                  >
                    <path
                      d="M5 18.89H6.41421L15.7279 9.57629L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6474L16.435 3.21233C16.8256 2.8218 17.4587 2.8218 17.8492 3.21233L20.6777 6.04075C21.0682 6.43128 21.0682 7.06444 20.6777 7.45497L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33365L15.7279 6.74786Z"
                      fill="#4d5969"
                    ></path>
                  </svg>
                  Compléter la fiche prestation
                </h5>
              </Col>
            </Accordion.Header>
            <Accordion.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              {categories.map((category, index) => (
                <Row key={index}>
                  <Col>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: '400px' }}>
                            <b>{category.name}</b>
                          </th>
                          <th scope="col" style={{ width: '80px' }}>
                            Quantité
                          </th>
                          <th scope="col">Consommé</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prestation.resources
                          .filter(r => r.category.id === category.id)
                          .map((resource, index) => (
                            <tr key={index}>
                              <td style={{ width: '400px' }} className="pt-3">
                                {resource.service_sheet_name}
                              </td>
                              <td style={{ width: '80px' }} className="pt-3">
                                {resource.pivot?.quantity}
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  disabled={
                                    prestation.is_service_sheet_validated === 1
                                  }
                                  name="default_price"
                                  placeholder={resource.pivot?.quantity}
                                  value={
                                    formData.resources.find(
                                      r => r.resource_id === resource.id
                                    )?.consumed
                                  }
                                  onChange={e => {
                                    let tmpResources = formData.resources;
                                    tmpResources.find(
                                      r => r.resource_id === resource.id
                                    ).consumed = e.target.value;
                                    setFormData({
                                      ...formData,
                                      resources: tmpResources
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              ))}

              {/*{prestation.butlers?.length > 0 && (
                <Row>
                  <Col>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: '400px' }}>
                            <b>Ressources humaines</b>
                          </th>
                          <th scope="col" style={{ width: '150px' }}>
                            Heures
                          </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {prestation.butlers.map((butler, index) => (
                          <tr key={index}>
                            <td style={{ width: '400px' }} className="pt-3">
                              {butler.firstname} {butler.lastname}
                              {butler.pivot.mission && (
                                <small>{butler.pivot.mission}</small>
                              )}
                            </td>
                            <td style={{ width: '150px' }}>
                              <Form.Control
                                type="number"
                                disabled={
                                  prestation.is_service_sheet_validated === 1
                                }
                                name="default_price"
                                placeholder="4"
                                value={
                                  formData.butlers.find(
                                    b => b.butler_id === butler.id
                                  )?.worked_hours
                                }
                                onChange={e => {
                                  let tmpButlers = formData.butlers;
                                  tmpButlers.find(
                                    b => b.butler_id === butler.id
                                  ).worked_hours = e.target.value;
                                  setFormData({
                                    ...formData,
                                    butlers: tmpButlers
                                  });
                                }}
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              )}*/}
              {prestation.is_service_sheet_validated === 0 && (
                <Col>
                  <Button
                    variant="primary"
                    className="admin-primary mt-3"
                    size="sm"
                    disabled={isLoadingSubmit}
                    onClick={handleSubmit}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      style={{
                        marginBottom: '2px',
                        marginRight: '3px'
                      }}
                    >
                      <path
                        d="M20 2C20.5523 2 21 2.44772 21 3V6.757L19 8.757V4H5V20H19V17.242L21 15.242V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761ZM13 12V14H8V12H13ZM16 8V10H8V8H16Z"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                    Enregistrer comme brouillon
                  </Button>
                  <Button
                    variant="falcon-default"
                    className="mt-3 ms-3"
                    size="sm"
                    disabled={isLoadingSubmit}
                    onClick={() => {
                      setShowValidate(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      style={{
                        marginBottom: '2px',
                        marginRight: '3px'
                      }}
                    >
                      <path
                        d="M22 14H20V7.23792L12.0718 14.338L4 7.21594V19H14V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V14ZM4.51146 5L12.0619 11.662L19.501 5H4.51146ZM19 22L15.4645 18.4645L16.8787 17.0503L19 19.1716L22.5355 15.636L23.9497 17.0503L19 22Z"
                        fill="#4d5969"
                      ></path>
                    </svg>
                    Valider et envoyer
                  </Button>
                  <Offcanvas
                    show={showValidate}
                    onHide={() => {
                      setShowValidate(false);
                    }}
                    placement={'end'}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <p>
                        Valider la fiche et l'envoyer à l'administrateur ? Vous
                        ne pourrez plus la modifier.
                      </p>
                      <Button
                        disabled={isLoadingSubmit}
                        size="sm"
                        variant="falcon-danger"
                        className="me-2"
                        onClick={handleValidate}
                      >
                        Oui, valider la fiche
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          setShowValidate(false);
                        }}
                      >
                        Annuler
                      </Button>
                    </Offcanvas.Body>
                  </Offcanvas>
                </Col>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </>
  );
};

ManagePrestationSheet.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func
};

export default ManagePrestationSheet;
