import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'products/batches';

export async function IndexProductBatches() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowProductBatches(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutProductBatches(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostProductBatches(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteProductBatches(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
