import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import ManageHeaderService from './components/ManageHeaderService';
import ManageInfoService from './components/ManageInfoService';
import ManageForwardedEmailsService from './components/ManageForwardedEmailsService';
import ManageProductService from './components/products/ManageProductService';
import ManageLogisticService from './components/ManageLogisticService';
import ManageLogisticSheet from './components/ManageLogisticSheet';
import ManageBrochuresEstimates from './components/ManageBrochuresEstimates';
import ManageButtlersService from './components/ManageButlersService';
import ManageVehiclesService from './components/ManageVehiclesService';
import { ShowPrestation } from '../../../services/PrestationService';
import ManagePrestationSheet from './components/ManagePrestationSheet';
import ManageBillingSheet from './components/ManageBillingSheet';
import ManagePennylaneInvoice from './components/ManagePennylaneInvoice';

const ManageService = () => {
  const { id } = useParams();
  const [prestation, setPrestation] = useState({});
  const [trigger, setTrigger] = useState(Date.now);
  const [labelResources, setLabelResources] = useState([]);
  const [butlersFromPresta, setButlersFromPresta] = useState([]); // récupère tout les maitre d'hotel relié à la prestation
  const [vehiclesFromPresta, setVehiclesFromPresta] = useState([]); // récupère tout les véhicules  relié à la prestation
  const [statusFromPresta, setStatutFromPresta] = useState([]); // récupère le statut de la prestation
  const [productsFromPresta, setProductsFromPresta] = useState([]); // récupère les produits relié de la prestation

  useEffect(() => {
    /* ---- récupère une prestation avec l'id de l'url ------*/
    const fetchPrestation = async () => {
      const response = await ShowPrestation(id);
      if (response.success === true) {
        const responseData = response.data;
        setPrestation(responseData);

        /*fecth les produits de la prestation*/
        if (responseData.products) {
          let arrayProducts = [];
          responseData.products?.map(product => {
            arrayProducts.push({
              value: product.id,
              label: `${product.booklet_name}`
            });
          });
          setProductsFromPresta(arrayProducts);
        }

        /* récupère les véhicules relié à la prestation*/
        if (responseData.vehicles) {
          let vehiclesArray = [];
          responseData.vehicles?.map(vehicle => {
            vehiclesArray.push({
              value: vehicle.id,
              label: `${vehicle.name}`,
              name: vehicle.name
            });
          });
          setVehiclesFromPresta(vehiclesArray);
        }
        // récupère les maitres d'hotel relié à la prestation
        if (responseData.butlers) {
          let butlerArray = [];
          responseData.butlers?.map(butler => {
            butlerArray.push({
              value: butler.id,
              label: `${butler.firstname} ${butler.lastname}`,
              lastname: butler.lastname,
              firstname: butler.firstname,
              email: butler.email,
              name: `${butler.firstname} ${butler.lastname}`,
              start_at: butler.pivot.start_at
                ? Moment(butler.pivot.start_at).local().toDate()
                : new Date(),
              worked_hours: butler.pivot.worked_hours,
              mission: butler.pivot.mission || butler.default_mission
            });
          });
          setButlersFromPresta(butlerArray);
        }
        // récupère le statut de la prestation
        if (responseData.status) {
          let statusArray = [];
          statusArray.push({
            value: responseData.status.id,
            label: `${responseData.status.name}`
          });
          setStatutFromPresta(statusArray);
        }
      } else {
        toast('Une erreur est survenue');
      }
    };

    if (id) {
      fetchPrestation();
    }
  }, [trigger]);
  return (
    <>
      {prestation && prestation.id && (
        <Row>
          <Col xs={12}>
            {/*-----  Header des prestations ----- */}
            <ManageHeaderService
              prestation={prestation}
              setTrigger={setTrigger}
            />
          </Col>
          <Col className="pe-1" xs={12} md={7} xl={7}>
            {/* -------- Accordéons Information sur la prestation --------  */}
            <ManageInfoService
              prestation={prestation}
              setParentTrigger={setTrigger}
              statusFromPresta={statusFromPresta}
            />
          </Col>
          <Col xs={12} md={5} xl={5} className="ps-1">
            <ManageForwardedEmailsService
              setParentTrigger={setTrigger}
              prestation={prestation}
            />
          </Col>
          <Col xs={12}>
            {/* ------- Accordéons gestions des produits ----- */}
            <ManageProductService
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
            {/*----- Ressources logistiques ------- */}
            <ManageLogisticService
              prestation={prestation}
              labelResources={labelResources}
              setLabelResources={setLabelResources}
              setParentTrigger={setTrigger}
            />
            <ManageLogisticSheet
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
            <ManagePrestationSheet
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
            {/*----- Accordéons plaquette et devis ------- */}
            <ManageBrochuresEstimates
              setParentTrigger={setTrigger}
              prestation={prestation}
              productsFromPresta={productsFromPresta}
            />
            {/* -- accordéon associer des maitres d'hotel --*/}
            <ManageButtlersService
              prestation={prestation}
              setParentTrigger={setTrigger}
              butlersFromPresta={butlersFromPresta}
            />
            {/* -- accordéon associer des véhicules --*/}
            <ManageVehiclesService
              prestation={prestation}
              setParentTrigger={setTrigger}
              vehiclesFromPresta={vehiclesFromPresta}
            />
            <ManageBillingSheet
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
            <ManagePennylaneInvoice
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ManageService;
