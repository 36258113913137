import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DisplayFile = ({ index, file, setSelectedObject }) => {
  return (
    <Col xs={2} key={index} className={index > 5 ? 'p-3' : 'p-3'}>
      <Card
        id={'idFile' + index}
        className={'cursor-pointer'}
        onClick={() => {
          let selected = document.getElementsByClassName('bg-soft-info');
          Array.from(selected).forEach(item => {
            item.classList.remove('bg-soft-info');
          });
          document
            .getElementById('idFile' + index)
            .classList.add('bg-soft-info');
          setSelectedObject(file);
        }}
        onDoubleClick={() => {
          if (file.url && file.url !== '') window.open(file.url, '_blank');
        }}
      >
        <Card.Body>
          <Row>
            <Col lg={12}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className={'me-2'}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M9 2.003V2h10.998C20.55 2 21 2.455 21 2.992v18.016a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6-5.997zM5.83 8H9V4.83L5.83 8zM11 4v5a1 1 0 0 1-1 1H5v10h14V4h-8z"
                  fill="rgba(97,110,128,1)"
                />
              </svg>
              <span className={'prevent-select me-2 displayColorTextSelect'}>
                {file.name}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

DisplayFile.propTypes = {
  index: PropTypes.number,
  file: PropTypes.object,
  setSelectedObject: PropTypes.func
};

export default DisplayFile;
