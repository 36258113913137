import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import TableBackCustomers from './table';
import { IndexCustomer, SyncCustomer } from '../../../services/CustomerService';
import { toast } from 'react-toastify';
import show from '../waiters/calendar/Show';
import ManageCustomers from './manage';

const IndexCustomers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialApiCustomers, setInitialApiCustomers] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [showManageCustomers, setShowManageCustomers] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      if (initialApiCustomers.length > 0) {
        setIsLoading(false);
      }
      const responseCustomers = await IndexCustomer();
      if (responseCustomers.success === true) {
        setInitialApiCustomers(responseCustomers.data);
        setIsLoading(false);
      }
    };
    fetchCustomers();
  }, [trigger]);
  return (
    <>
      {show && (
        <ManageCustomers
          showManageCustomers={showManageCustomers}
          setShowManageCustomers={setShowManageCustomers}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          setParentTrigger={setTrigger}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des clients
              </h3>
            </Col>
            <Col lg={6} className="text-end">
              <Button
                size="sm"
                onClick={() => {
                  setShowManageCustomers(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                Ajouter
              </Button>
              <Button
                variant="primary"
                className="ms-3 admin-primary"
                size="sm"
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const responseObjects = await SyncCustomer();
                  if (responseObjects.success === true) {
                    setIsLoading(false);
                    setTrigger(Date.now());
                    toast('Synchronisation effectuée avec succès');
                  } else {
                    toast(
                      'Une erreur est survenue, veuillez réessayer plus tard'
                    );
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M12 4C9.25144 4 6.82508 5.38626 5.38443 7.5H8V9.5H2V3.5H4V5.99936C5.82381 3.57166 8.72764 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4ZM4 12C4 16.4183 7.58172 20 12 20C14.7486 20 17.1749 18.6137 18.6156 16.5H16V14.5H22V20.5H20V18.0006C18.1762 20.4283 15.2724 22 12 22C6.47715 22 2 17.5228 2 12H4Z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Synchroniser Sage
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiCustomers.length > 0 && (
        <TableBackCustomers
          initialApiCustomers={initialApiCustomers}
          setParentTrigger={setTrigger}
          setSelectedObject={setSelectedObject}
          setShowManageCustomers={setShowManageCustomers}
        />
      )}
    </>
  );
};

export default IndexCustomers;
