import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { PutPrestation } from '../../../../services/PrestationService';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';
import { IndexResources } from '../../../../services/RessourceService';
import CanvaNotes from '../../canvas-components/canvaNotes';
import ManageResource from '../../resources/ManageResource';

const ManageLogisticService = ({
  prestation,
  labelResources,
  setLabelResources,
  setParentTrigger
}) => {
  const [showCanvaAddResource, setShowCanvaAddResource] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [formData, setFormData] = useState({
    resources: []
  });

  // scroll to top si errorForm === true
  const myRef = useRef(null);
  const useMountEffect = fun => useEffect(fun, []);
  const executeScroll = () => myRef.current.scrollIntoView();
  useMountEffect(executeScroll); // Scroll on mount

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let tmpFormData = { ...formData };
    tmpFormData = {
      ...tmpFormData,
      resources: tmpFormData.resources?.map(r => {
        return {
          resource_id: r.resource_id,
          quantity: r.quantity,
          price: r.price,
          discount: r.discount,
          vat: r.vat,
          consumed: r.consumed
        };
      }),
      context: 'resources'
    };
    let response = false;

    response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      toast('Ressources enregistrées');
      setParentTrigger(Date.now());
      setTimeout(() => {
        setTrigger(Date.now());
      }, 1000);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
      executeScroll();
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    const fetchResources = async () => {
      const responseResources = await IndexResources();
      if (responseResources.success === true) {
        let tmpLabelResources = [];
        responseResources.data.map(resource => {
          let label = '';
          if (resource.category?.name === 'Logistique') {
            label = resource.invoice_name + ' (LOGISTIQUE)';
          } else {
            label = resource.invoice_name;
          }
          tmpLabelResources.push({
            value: resource.id,
            label: label,
            name: resource.invoice_name,
            price: resource.default_price,
            vat: resource.default_vat
          });
        });
        setLabelResources(tmpLabelResources);
      }
    };
    let tmpFormData = { ...formData };
    tmpFormData.resources = [];
    prestation.resources.map(resource => {
      tmpFormData.resources.push({
        select: {
          value: resource.id,
          label: resource.invoice_name
        },
        id: resource.id,
        resource_id: resource.id,
        quantity: resource.pivot.quantity,
        price: resource.pivot.price,
        discount: resource.pivot.discount || 0,
        vat: resource.pivot.vat,
        consumed: resource.pivot.consumed
      });
    });
    setFormData(tmpFormData);
    fetchResources();
  }, [trigger]);

  return (
    <>
      <Col>
        <Card className="mt-3">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Col className="d-flex" md="6">
                  <h5 className="pt-1">Ressources logistiques</h5>
                </Col>
              </Accordion.Header>
              <Accordion.Body ref={myRef}>
                {errorForm && (
                  <div className="mt-3" id="errorForm">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Col xs={12}>
                  <Col className="text-end">
                    <CanvaNotes
                      setTrigger={setParentTrigger}
                      model={prestation}
                      noteableType={'prestation'}
                      context={'resources'}
                    />
                  </Col>
                </Col>
                <Col xs={12}>
                  {formData.resources.map((resource, index) => (
                    <Row
                      className="align-items-center mb-4"
                      key={index}
                      id={`resource-${index}`}
                    >
                      <Col lg={1}>
                        {
                          // arrow up to move up the resource and arrow down to move down the resource :
                        }
                        {index !== 0 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="cursor-pointer mt-4 me-3"
                            onClick={() => {
                              let tmpFormData = { ...formData };
                              let tmpResource = tmpFormData.resources[index];
                              tmpFormData.resources[index] =
                                tmpFormData.resources[index - 1];
                              tmpFormData.resources[index - 1] = tmpResource;
                              setFormData(tmpFormData);
                            }}
                          >
                            <path
                              d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z"
                              fill="rgba(94,110,130,1)"
                            />
                          </svg>
                        )}
                        {index !== formData.resources.length - 1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="cursor-pointer mt-4 me-3"
                            onClick={() => {
                              let tmpFormData = { ...formData };
                              let tmpResource = tmpFormData.resources[index];
                              tmpFormData.resources[index] =
                                tmpFormData.resources[index + 1];
                              tmpFormData.resources[index + 1] = tmpResource;
                              setFormData(tmpFormData);
                            }}
                          >
                            <path
                              d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
                              fill="rgba(94,110,130,1)"
                            />
                          </svg>
                        )}
                      </Col>
                      <Col lg={10}>
                        <Form.Group controlId="label">
                          <Form.Label className="mb-0">
                            Sélectionner une <b>ressource</b> :
                            <a
                              onClick={() => {
                                setShowCanvaAddResource(true);
                              }}
                              className="cursor-pointer ms-3 float-end"
                              style={{ position: 'relative' }}
                            >
                              <small>+ Créer une ressource</small>
                            </a>
                          </Form.Label>
                          <Select
                            className="mt-2"
                            closeMenuOnSelect={true}
                            options={labelResources}
                            value={resource.select}
                            placeholder="Sélectionner une ressource ..."
                            classNamePrefix="react-select"
                            onChange={object => {
                              let selectedResource = labelResources.find(
                                resource => resource.value === object.value
                              );
                              if (selectedResource) {
                                let tmpFormData = { ...formData };
                                tmpFormData.resources[index].select = object;
                                tmpFormData.resources[index].id =
                                  selectedResource.value;
                                tmpFormData.resources[index].resource_id =
                                  selectedResource.value;
                                tmpFormData.resources[index].quantity = 0;
                                tmpFormData.resources[index].price =
                                  selectedResource.price;
                                tmpFormData.resources[index].discount = 0;
                                tmpFormData.resources[index].vat =
                                  selectedResource.vat;
                                setFormData(tmpFormData);
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          className="float-end cursor-pointer mt-4 me-3"
                          onClick={() => {
                            let tmpFormData = { ...formData };
                            tmpFormData.resources.splice(index, 1);
                            setFormData(tmpFormData);
                          }}
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                            fill="rgba(229,47,18,1)"
                          />
                        </svg>
                      </Col>
                      {resource.select?.value && (
                        <Row className="mt-3">
                          <Col lg={1}></Col>
                          <Col lg={2}>
                            {' '}
                            <Form.Group controlId="quantity">
                              <Form.Label>Quantité</Form.Label>
                              <Form.Control
                                type="number"
                                min={1}
                                name="quantity"
                                value={resource.quantity}
                                placeholder="Quantité de la ressource"
                                onChange={e => {
                                  let tmpFormData = { ...formData };
                                  tmpFormData.resources[index].quantity =
                                    e.target.value;
                                  setFormData(tmpFormData);
                                }}
                              />
                            </Form.Group>
                          </Col>{' '}
                          <Col lg={2}>
                            {' '}
                            <Form.Group controlId="price">
                              <Form.Label>Prix €</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                placeholder="Prix"
                                onChange={e => {
                                  let tmpFormData = { ...formData };
                                  tmpFormData.resources[index].price =
                                    e.target.value;
                                  setFormData(tmpFormData);
                                }}
                                value={resource.price}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={2}>
                            {' '}
                            <Form.Group controlId="price">
                              <Form.Label>Remise %</Form.Label>
                              <Form.Control
                                type="number"
                                name="discount"
                                placeholder="Remise"
                                onChange={e => {
                                  let tmpFormData = { ...formData };
                                  tmpFormData.resources[index].discount =
                                    e.target.value;
                                  setFormData(tmpFormData);
                                }}
                                value={resource.discount}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={1}>
                            {' '}
                            <Form.Group controlId="vat">
                              <Form.Label>TVA :</Form.Label>
                              <Form.Select
                                value={resource.vat}
                                type="number"
                                name="vat"
                                placeholder="Taux TVA"
                                onChange={e => {
                                  let tmpFormData = { ...formData };
                                  tmpFormData.resources[index].vat =
                                    e.target.value;
                                  setFormData(tmpFormData);
                                }}
                              >
                                {resource.vat === '10.00' ? (
                                  <>
                                    <option value={'10.00'}>10 %</option>
                                    <option value={'5.50'}>5,50 %</option>
                                    <option value={'20.00'}>20 %</option>
                                  </>
                                ) : resource.vat === '20.00' ? (
                                  <>
                                    <option value={'20.00'}>20 %</option>
                                    <option value={'5.50'}>5,50 %</option>
                                    <option value={'10.00'}>10 %</option>
                                  </>
                                ) : (
                                  <>
                                    <option value={'5.50'}>5,50 %</option>
                                    <option value={'20.00'}>20 %</option>
                                    <option value={'10.00'}>10 %</option>
                                  </>
                                )}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col lg={2}>
                            {' '}
                            <Form.Group controlId="quantity">
                              <Form.Label>Consommation</Form.Label>
                              <Form.Control
                                type="number"
                                min={1}
                                name="consumed"
                                value={resource.consumed}
                                placeholder="Quantité de la ressource"
                                onChange={e => {
                                  let tmpFormData = { ...formData };
                                  tmpFormData.resources[index].consumed =
                                    e.target.value;
                                  setFormData(tmpFormData);
                                }}
                              />
                            </Form.Group>
                          </Col>{' '}
                        </Row>
                      )}
                      <Col lg="12" className="pb-0 pt-3 mb-0 mt-3">
                        <div
                          style={{
                            borderBottom: '3px dashed #d8e2ef'
                          }}
                        ></div>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col className="mt-4">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        onClick={() => {
                          const newArray = { ...formData };
                          if (!newArray.resources) {
                            newArray.resources = [];
                          }
                          newArray.resources.push({
                            select: {},
                            id: '',
                            quantity: 10,
                            price: '',
                            vat: ''
                          });
                          setFormData(newArray);
                          setTimeout(function () {
                            document
                              .getElementById(
                                `resource-${formData.resources.length - 1}`
                              )
                              .classList.add('content-clignote');
                            document
                              .getElementById(
                                `resource-${formData.resources.length - 1}`
                              )
                              .scrollIntoView();
                          }, 150);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                            fill="rgba(94,110,130,1)"
                          />
                        </svg>{' '}
                        Ajouter une ressource
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {formData.resources.length >= 0 && (
                  <Col className="d-flex justify-content-start mt-3" md="12">
                    <Button
                      disabled={isLoadingSubmit}
                      size="sm"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>{' '}
                      Enregistrer les ressources{' '}
                    </Button>
                  </Col>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
      {/*------------------          Offcanva création d'une ressource        ---------------------*/}
      {showCanvaAddResource && (
        <ManageResource
          showResource={showCanvaAddResource}
          setShowResource={setShowCanvaAddResource}
          setLabelResources={setLabelResources}
          selectedObject={{}}
        />
      )}
    </>
  );
};

ManageLogisticService.propTypes = {
  prestation: PropTypes.object,
  labelResources: PropTypes.array,
  setLabelResources: PropTypes.func,
  setParentTrigger: PropTypes.func,
  fromButler: PropTypes.bool
};

export default ManageLogisticService;
