import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { IndexProduct } from '../../../services/ProductService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';
import {
  DeleteProductBatches,
  PostProductBatches,
  PutProductBatches,
  ShowProductBatches
} from '../../../services/ProductBatchService';
import ManageProduct from '../products/Manage';
import { IndexSeasons } from '../../../services/ProductSeasonsService';
import ManageProductSeasons from '../products/seasons/ManageProductSeasons';

const ManageProductBatches = ({
  showCanvaProduct,
  setShowCanvaProduct,
  selectedObject,
  setParentTrigger
}) => {
  const [labelSeasonsProduct, setLabelSeasonsProduct] = useState([]);
  const [labelProduct, setLabelProduct] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showCanvaManageProduct, setShowCanvaManageProduct] = useState(false);
  const [showManageSeasons, setShowManageSeasons] = useState(false); // ouverture et fermuture du Manage de seasons
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingSubmitDelete, setIsLoadingSubmitDelete] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [formData, setFormData] = useState({
    booklet_name: '',
    invoice_name: '',
    default_price: '',
    default_vat: 20,
    reference: '',
    products: [],
    seasons: []
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    let tmpFormData = {
      ...formData,
      seasons: formData.seasons.map(season => season.value)
    };

    if (tmpFormData.products.length > 0) {
      tmpFormData.products = tmpFormData.products.map(product => {
        return {
          product_id: product.value,
          quantity: product.quantity,
          default_unit: product.default_unit
        };
      });
    }

    if (selectedObject.id) {
      response = await PutProductBatches(
        selectedObject && selectedObject.id,
        tmpFormData
      );
    } else {
      response = await PostProductBatches(tmpFormData);
    }
    if (response.success === true) {
      setIsLoadingSubmit(true);
      toast('Lot de produits enregistré');
      setParentTrigger(Date.now());
      setTrigger(Date.now());
      setShowCanvaProduct(false);
      setFormData({});
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  const deleteProductBatches = async () => {
    const responseDeleteProduct = await DeleteProductBatches(selectedObject.id);
    setIsLoadingSubmitDelete(true);
    if (responseDeleteProduct.success === true) {
      toast('Lot de produits supprimé');
      setParentTrigger(Date.now());
      setShowCanvaProduct(false);
      setShowDelete(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmitDelete(false);
  };

  useEffect(() => {
    const fetchOneProduct = async () => {
      const responseOneProduct = await ShowProductBatches(selectedObject.id);
      if (responseOneProduct.success === true) {
        let dataProduct = responseOneProduct.data;
        let productsArray = [];
        responseOneProduct.data.products.map(product => {
          productsArray.push({
            value: product.id,
            label: product.production_sheet_name,
            quantity: product.pivot.quantity,
            default_unit: product.pivot.default_unit
          });
        });
        setLabelProduct(productsArray);

        /*--------  Seasons ----- */
        let seasonsArray = [];
        responseOneProduct.data.seasons.map(season => {
          seasonsArray.push({
            value: season.id,
            label: season.name
          });
        });

        setFormData({
          booklet_name: dataProduct.booklet_name,
          invoice_name: dataProduct.invoice_name,
          default_price: dataProduct.default_price,
          default_vat: dataProduct.default_vat,
          products: productsArray,
          seasons: seasonsArray,
          reference: dataProduct.reference
        });
      }
    };

    const fetchAllProduct = async () => {
      const responseProduct = await IndexProduct();
      if (responseProduct.success === true) {
        let tmpProductArray = [];
        responseProduct.data.map(product => {
          tmpProductArray.push({
            value: product.id,
            label: product.production_sheet_name,
            quantity: 1,
            default_unit: product.default_unit
          });
        });
        setLabelProduct(tmpProductArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    const fetchAllProductSeasons = async () => {
      const responseProductSeasons = await IndexSeasons();
      if (responseProductSeasons.success === true) {
        let tmpSeasonsArray = [];
        responseProductSeasons.data.map(season => {
          tmpSeasonsArray.push({
            value: season.id,
            label: season.name
          });
        });

        setLabelSeasonsProduct(tmpSeasonsArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchAllProduct();
    fetchAllProductSeasons();
    if (selectedObject && selectedObject.id) {
      fetchOneProduct();
      fetchAllProduct();
    }
  }, [selectedObject, trigger]);

  return (
    <>
      {showCanvaManageProduct && (
        <ManageProduct
          showCanvaProduct={showCanvaManageProduct}
          setShowCanvaProduct={setShowCanvaManageProduct}
          setParentTrigger={setTrigger}
          setFormParentProductBatches={setFormData}
          formParentProductBatches={formData}
        />
      )}

      {/* ---- Manage de gestion des diffférentes saisons -----*/}
      {showManageSeasons && (
        <ManageProductSeasons
          showManageSeasons={showManageSeasons}
          setShowManageSeasons={setShowManageSeasons}
          formParentProduct={formData}
          setFormParentProduct={setFormData}
          setLabelSeasonsProduct={setLabelSeasonsProduct}
        />
      )}

      {selectedObject && (
        <>
          {selectedObject.id && (
            <Offcanvas
              show={showDelete}
              onHide={() => {
                setShowDelete(false);
              }}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <p>
                  Supprimer le lot de produits :
                  <span style={{ fontWeight: 'bold' }}>
                    {selectedObject.booklet_name}
                  </span>
                  ?
                </p>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Button
                  disabled={isLoadingSubmitDelete}
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    deleteProductBatches();
                  }}
                >
                  Oui, supprimer le lot
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          )}

          {showCanvaProduct && (
            <Offcanvas
              className="w-50"
              show={showCanvaProduct}
              onHide={() => {
                setShowCanvaProduct(false);
              }}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <h3 className="text-primary admin-title mb-0">
                  {selectedObject.id
                    ? 'Modifier le lot de produits'
                    : `Ajouter un lot de produits`}
                </h3>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group controlId="reference">
                        <Form.Label>Référence :</Form.Label>
                        <Form.Control
                          type="text"
                          name="reference"
                          placeholder="Choisir la référence"
                          value={formData.reference || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="default_price">
                        <Form.Label>Prix :</Form.Label>
                        <Form.Control
                          type="number"
                          name="default_price"
                          placeholder="Choisir son prix"
                          value={formData.default_price || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="default_vat">
                        <Form.Label>Tranche TVA :</Form.Label>
                        <Form.Select
                          name="default_vat"
                          placeholder="Tranche TVA"
                          value={formData.default_vat || ''}
                          onChange={handleFieldChange}
                        >
                          {formData.default_vat === '5.50' ? (
                            <>
                              <option value="5.50">5,50%</option>
                              <option value="10">10%</option>
                              <option value="20">20%</option>
                            </>
                          ) : formData.default_vat === '10.00' ? (
                            <>
                              <option value="10">10%</option>
                              <option value="5.50">5.50%</option>
                              <option value="20">20%</option>
                            </>
                          ) : (
                            <>
                              <option value="20">20%</option>
                              <option value="5.50">5.50%</option>
                              <option value="10">10%</option>
                            </>
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Form.Group controlId="products">
                        <Form.Label>
                          Produits :
                          <a
                            className="cursor-pointer ms-2"
                            onClick={() => {
                              setShowCanvaManageProduct(true);
                            }}
                          >
                            <small>Créer un produit</small>
                          </a>
                        </Form.Label>
                        <Select
                          isMulti
                          closeMenuOnSelect={true}
                          options={labelProduct}
                          placeholder="Produits.."
                          name="products"
                          classNamePrefix="react-select"
                          value={formData.products}
                          onChange={value => {
                            setFormData({
                              ...formData,
                              products: value
                            });
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {formData.products && formData.products.length > 0 && (
                    <Row className="mt-3">
                      <Col xs={12}>
                        <Table bordered className="admin-table mt-4">
                          <thead>
                            <tr>
                              <th scope="col">Produit</th>
                              <th scope="col">
                                Quantité <small>pour un lot</small>
                              </th>
                              <th scope="col">Unité</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.products.map((product, index) => (
                              <tr key={index}>
                                <td>{product.label}</td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    placeholder={'1'}
                                    value={product.quantity}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        products: formData.products.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                quantity: e.target.value
                                              };
                                            }
                                            return item;
                                          }
                                        )
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Select
                                    value={product.default_unit}
                                    onChange={e => {
                                      setFormData({
                                        ...formData,
                                        products: formData.products.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                default_unit: e.target.value
                                              };
                                            }
                                            return item;
                                          }
                                        )
                                      });
                                    }}
                                    className="form-select"
                                    aria-label="default_unit"
                                    name="default_unit"
                                  >
                                    <option
                                      disabled={
                                        product.default_unit ? true : false
                                      }
                                    >
                                      Choisir ...
                                    </option>
                                    <option value="piece">Pièce</option>
                                    <option value="part">Part</option>
                                    <option value="kilo">Kilo</option>
                                    <option value="liter">Litre</option>
                                  </Form.Select>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-3">
                    <Col>
                      {' '}
                      <Form.Group controlId="seasons">
                        <Form.Label>
                          Saisons :
                          <a
                            onClick={() => {
                              setShowManageSeasons(true);
                            }}
                            className="cursor-pointer ms-2"
                          >
                            <small>Créer une saison</small>
                          </a>{' '}
                        </Form.Label>
                        <Select
                          closeMenuOnSelect={true}
                          isMulti
                          options={labelSeasonsProduct}
                          placeholder="Saison(s) du produit..."
                          name="seasons"
                          classNamePrefix="react-select"
                          onChange={value => {
                            setFormData({
                              ...formData,
                              seasons: value
                            });
                          }}
                          value={formData.seasons}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Col
                    style={{ borderTop: 'dashed 1px #d8e2ef' }}
                    className="my-4"
                  ></Col>
                  <Col>
                    <Form.Group className="mt-2" controlId="booklet_name">
                      <Form.Label>Dénomination de la plaquette :</Form.Label>
                      <Form.Control
                        type="text"
                        name="booklet_name"
                        placeholder="Nom sur la plaquette"
                        value={formData.booklet_name || ''}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-3">
                    <Form.Group className="mt-2" controlId="invoice_name">
                      <Form.Label>Dénomination du devis :</Form.Label>
                      <Form.Control
                        type="text"
                        name="invoice_name"
                        placeholder="Nom sur le devis"
                        value={formData.invoice_name || ''}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-3">
                    <Button
                      disabled={isLoadingSubmit}
                      onClick={e => {
                        handleSubmit(e);
                      }}
                      size="sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                      {selectedObject.id
                        ? ' Enregistrer les modifications'
                        : ' Enregistrer le nouveau lot de produits'}
                    </Button>
                    {selectedObject.id && (
                      <Button
                        size="sm"
                        variant="danger"
                        className="me-0 float-end"
                        type="button"
                        onClick={() => {
                          setShowDelete(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '2px',
                            marginRight: '3px'
                          }}
                        >
                          <path
                            d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Supprimer
                      </Button>
                    )}
                  </Col>
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </>
      )}
    </>
  );
};

ManageProductBatches.propTypes = {
  showCanvaProduct: PropTypes.bool,
  setShowCanvaProduct: PropTypes.func,
  selectedObject: PropTypes.object,
  initialApiProducts: PropTypes.array,
  setParentTrigger: PropTypes.func
};

export default ManageProductBatches;
