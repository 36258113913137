import React, { useEffect, useState } from 'react';
import { Offcanvas, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IndexResourceCategories } from '../../../../services/ResourceCategoriesService';
import OffCanvaCategoriesDelete from './OffCanvaCategoriesDelete';
import ManageResourceCategory from './ManageResourceCategory';
import { IndexResources } from '../../../../services/RessourceService';

const IndexRessourceCategories = ({
  showResourceCategories,
  setShowResourceCategories,
  setTriggerParent
}) => {
  const [categories, setCategories] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showManageCategories, setShowManageCategories] = useState(false);
  const [ressources, setRessources] = useState([{}]);

  useEffect(() => {
    const fetchResourceCategories = async () => {
      const responseCategories = await IndexResourceCategories();
      if (responseCategories.success === true) {
        setCategories(responseCategories.data);
      }
    };

    const fetchResources = async () => {
      const responseRessources = await IndexResources();
      if (responseRessources.success === true) {
        setRessources(responseRessources.data);
      }
    };

    fetchResourceCategories();
    fetchResources();
  }, [trigger]);

  const isCategoryUsedByRessource = category => {
    let isUsed = false;
    ressources.forEach(ressource => {
      if (ressource.category_id === category.id) {
        isUsed = true;
      }
    });
    return isUsed;
  };

  return (
    <>
      {showDelete && (
        <OffCanvaCategoriesDelete
          selectedCategory={selectedCategory}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          setTrigger={setTrigger}
          setTriggerParent={setTriggerParent}
        />
      )}

      {showManageCategories && (
        <ManageResourceCategory
          showManageCategories={showManageCategories}
          setShowManageCategories={setShowManageCategories}
          setTrigger={setTrigger}
          selectedCategory={selectedCategory}
        />
      )}

      <Offcanvas
        placement={'end'}
        show={showResourceCategories}
        onHide={() => {
          setShowResourceCategories(false);
        }}
        style={{ width: '30%' }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Gestion des catégories
            <a
              onClick={() => {
                setSelectedCategory({});
                setShowManageCategories(true);
              }}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>{' '}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {categories && categories.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td>{category.name}</td>
                    <td className="text-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="hi">Modifier</Tooltip>}
                      >
                        <a
                          onClick={() => {
                            setSelectedCategory(category);
                            setShowManageCategories(true);
                          }}
                          className="cursor-pointer me-3"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            style={{
                              marginBottom: '1px',
                              marginLeft: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                              fill="rgba(44,123,229,1)"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>
                      <span>
                        {isCategoryUsedByRessource(category) ? (
                          <>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="hi">
                                  Impossible de supprimer cette catégorie car
                                  des ressources y sont attacher
                                </Tooltip>
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                style={{
                                  marginBottom: '1px',
                                  marginLeft: '3px'
                                }}
                              >
                                <path
                                  fill="#b5b5b5"
                                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                />
                              </svg>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="hi">Supprimer</Tooltip>}
                            >
                              <a
                                href="#"
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedCategory(category);
                                  setShowDelete(true);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="16"
                                  height="16"
                                  style={{
                                    marginBottom: '1px',
                                    marginLeft: '3px'
                                  }}
                                >
                                  <path
                                    fill="#e63757"
                                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                  />
                                </svg>
                              </a>
                            </OverlayTrigger>
                          </>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

IndexRessourceCategories.propTypes = {
  showResourceCategories: PropTypes.bool,
  setShowResourceCategories: PropTypes.func,
  setTriggerParent: PropTypes.func
};

export default IndexRessourceCategories;
