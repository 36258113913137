import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { LogOutUserService } from '../../../services/AuthService';

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li" id="top-profile-dropdown">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#"
        className="pe-0 ps-2 nav-link"
      >
        {localStorage.getItem('user_avs') &&
        JSON.parse(localStorage.getItem('user_avs')).profile_picture_url !==
          undefined &&
        JSON.parse(localStorage.getItem('user_avs')).profile_picture_url !==
          null ? (
          <Avatar
            src={
              JSON.parse(localStorage.getItem('user_avs')).profile_picture_url
            }
          />
        ) : localStorage.getItem('user_avs') ? (
          <Avatar
            name={`${JSON.parse(localStorage.getItem('user_avs')).firstname} ${
              JSON.parse(localStorage.getItem('user_avs')).lastname
            }`}
          />
        ) : (
          ''
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} onClick={() => LogOutUserService()} to="#">
            Déconnexion
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
