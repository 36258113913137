import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'products';

export async function IndexProduct() {
  return modelService.IndexModel(`${model}`);
}

export async function IndexProductAndBatch(prestationId) {
  return modelService.IndexModel(
    `${model}?withbatch=true&prestation_id=${prestationId}`
  );
}

export async function ShowProduct(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutProduct(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostProduct(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteProduct(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
