import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import TableBackIngredients from '../ingredients/table';
import { IndexIngredient } from '../../../services/IngredientsService';
import ManageIngredient from './Manage';

const IndexIngredients = () => {
  const [initialApiIngredients, setInitialApiIngredients] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [showCanvaIngredient, setShowCanvaIngredient] = useState(false); // ouvre et ferme le Manage OffCanva des ingrédients
  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    const fetchIngredient = async () => {
      const responseIngredient = await IndexIngredient();
      if (responseIngredient.success === true) {
        setInitialApiIngredients(responseIngredient.data);
      }
    };

    fetchIngredient();
  }, [trigger]);

  return (
    <>
      {/*  ---- affiche le Manage des ingrédients ---- */}
      {showCanvaIngredient && (
        <ManageIngredient
          showCanvaIngredient={showCanvaIngredient}
          setShowCanvaIngredient={setShowCanvaIngredient}
          selectedObject={selectedObject}
          setTrigger={setTrigger}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row lg={12} className="justify-content-between align-items-center">
            <Col lg={4}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des ingrédients
              </h3>
            </Col>
            <Col xs={8} className="text-end">
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowCanvaIngredient(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un ingrédient
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialApiIngredients.length > 0 && (
        <TableBackIngredients
          showCanvaIngredient={showCanvaIngredient}
          setShowCanvaIngredient={setShowCanvaIngredient}
          initialApiIngredients={initialApiIngredients}
          setSelectedObject={setSelectedObject}
        />
      )}
    </>
  );
};

export default IndexIngredients;
