import React, { useEffect, useState } from 'react';
import { Offcanvas, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ShowCustomer } from '../../../services/CustomerService';
import ManageContact from './ManageContact';
import CanvaDeletecontact from './deleteContact';

const CanvaContactCustomer = ({
  setParentTrigger,
  showCanvaContactCustomer,
  setShowCanvaContactCustomer,
  selectedCustomer,
  setSelectedCustomer
}) => {
  const [showModifyContact, setShowModifyContact] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());

  useEffect(() => {
    const fetchData = async () => {
      const responseContact = await ShowCustomer(selectedCustomer.id);
      if (responseContact.success === true) {
        setCustomer(responseContact.data);
      }
    };
    {
      if (selectedCustomer && selectedCustomer.id) {
        fetchData();
      }
    }
  }, [trigger, selectedCustomer]);

  return (
    <>
      {showModifyContact && (
        <ManageContact
          showModifyContact={showModifyContact}
          setShowModifyContact={setShowModifyContact}
          selectedCustomer={selectedCustomer}
          selectedContact={selectedContact}
          setTrigger={setTrigger}
          setGrandParentTrigger={setParentTrigger}
          setSelectedContact={setSelectedContact}
        />
      )}
      {showDelete && (
        <CanvaDeletecontact
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          setTrigger={setTrigger}
          setGrandParentTrigger={setParentTrigger}
        />
      )}
      <Offcanvas
        placement={'end'}
        show={showCanvaContactCustomer}
        onHide={() => {
          setShowCanvaContactCustomer(false);
          setSelectedCustomer({});
        }}
        className={'w-50'}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Contacts
            <a
              onClick={() => setShowModifyContact(true)}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        {customer && customer.id && (
          <Offcanvas.Body className="">
            {customer.contacts.length > 0 && (
              <Table responsive className="table-stats-tracks">
                <tbody>
                  {customer.contacts.map((contact, index) => (
                    <tr key={index}>
                      <td>{`${contact.firstname} ${contact.lastname}`}</td>
                      <td>{contact.email}</td>
                      <td className="text-end">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="hi">Modifier</Tooltip>}
                        >
                          <a
                            onClick={() => {
                              setShowModifyContact(true);
                              setSelectedContact(contact);
                            }}
                            className="cursor-pointer me-3"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                fill="rgba(44,123,229,1)"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                        <span>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="overlay-trigger-example">
                                Supprimer le contact
                              </Tooltip>
                            }
                          >
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedContact(contact);
                                setShowDelete(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="14"
                                height="14"
                                style={{
                                  marginBottom: '1px',
                                  marginLeft: '3px'
                                }}
                              >
                                <path
                                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                  fill="#e63757"
                                />
                              </svg>
                            </a>
                          </OverlayTrigger>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
};

export default CanvaContactCustomer;

CanvaContactCustomer.propTypes = {
  showCanvaContactCustomer: PropTypes.bool,
  setShowCanvaContactCustomer: PropTypes.func,
  setParentTrigger: PropTypes.func,
  selectedCustomer: PropTypes.object,
  setSelectedCustomer: PropTypes.func
};
