import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import TableBackResources from './table';
import { IndexResources } from '../../../services/RessourceService';
import ManageResource from './ManageResource';
import IndexRessourceCategories from './categories';

const IndexRessources = () => {
  const [initialApiResources, setInitialApiResources] = useState([]);
  const [showResource, setShowResource] = useState(false);
  const [showResourceCategories, setShowResourceCategories] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [selectedObject, setSelectedObject] = useState({});
  const [labelResources, setLabelResources] = useState({});

  useEffect(() => {
    const fetchResources = async () => {
      const responseRessources = await IndexResources();
      if (responseRessources.success === true) {
        setInitialApiResources(responseRessources.data);
      }
    };
    fetchResources();
  }, [trigger]);

  return (
    <>
      {showResourceCategories && (
        <IndexRessourceCategories
          setTriggerParent={setTrigger}
          showResourceCategories={showResourceCategories}
          setShowResourceCategories={setShowResourceCategories}
        />
      )}
      {showResource && labelResources && (
        <ManageResource
          selectedObject={selectedObject}
          setShowResource={setShowResource}
          showResource={showResource}
          setParentTrigger={setTrigger}
          setLabelResources={setLabelResources}
        />
      )}

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des ressources
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                className="w-auto me-2"
                variant="falcon-default"
                size="sm"
                transform="shrink-3"
                onClick={() => {
                  setShowResourceCategories(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    marginBottom: '2px',
                    marginRight: '2px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                </svg>
                Gestion des catégories
              </Button>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowResource(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter une ressource
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialApiResources.length > 0 && (
        <TableBackResources
          initialApiResources={initialApiResources}
          setSelectedObject={setSelectedObject}
          setShowResource={setShowResource}
        />
      )}
    </>
  );
};

export default IndexRessources;
