import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  DropdownButton,
  Form,
  Modal,
  Offcanvas,
  Dropdown
} from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import FalconCloseButton from '../../../common/FalconCloseButton';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';
import {
  DeleteBillingSheet,
  SendBillingSheet
} from '../../../../services/BillingSheetService';

const TableBillingSheet = ({ billingSheets, setParentTrigger, prestation }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [objects, setObjects] = useState([]);
  const [showSendBillingSheet, setShowSendBillingSheet] = useState(false);
  const [selectedBillingSheet, setSelectedBillingSheet] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [formData, setFormData] = useState({
    message: '',
    emails: ''
  });
  const [errorForm, setErrorForm] = useState(false);

  useEffect(() => {
    transformObjects(billingSheets);
  }, [billingSheets, prestation]);

  const closeSendModal = () => {
    setShowSendBillingSheet(false);
    setFormData({
      message: '',
      emails: ''
    });
    setSelectedBillingSheet({});
  };

  const handleSendSubmit = async () => {
    setSendLoading(true);
    const responseSendBillingSheet = await SendBillingSheet(
      selectedBillingSheet.id,
      {
        message: formData.message,
        emails: formData.emails
      }
    );
    setErrorForm(responseSendBillingSheet.validator);
    if (responseSendBillingSheet.success === true) {
      toast('Fiche facturation envoyée');
      setShowSendBillingSheet(false);
      setParentTrigger(Date.now());
      setSendLoading(false);
      closeSendModal();
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setSendLoading(false);
  };

  const deleteBillingSheet = async id => {
    const responsedeleteBillingSheet = await DeleteBillingSheet(id);
    if (responsedeleteBillingSheet.success === true) {
      toast('Fiche facturation supprimée');
      setShowDelete(false);
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const transformObjects = objects => {
    let transformedApiArray = [];
    objects.map(obj => {
      transformedApiArray.push({
        name: obj.name,
        created_at: moment(obj.created_at).local().format('DD/MM/YYYY HH:mm'),
        actions: (
          <>
            {obj && (
              <>
                <DropdownButton
                  as={ButtonGroup}
                  variant="falcon-default"
                  size="sm"
                  style={{ position: 'static', zIndex: '10000' }}
                  title={
                    <>
                      Actions
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '0px' }}
                      >
                        <path
                          d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                    </>
                  }
                  className="me-2"
                >
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      window.open(obj.file_url, '_blank');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      style={{
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"
                        fill="#000000"
                      />
                    </svg>{' '}
                    Télécharger le PDF
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      setShowSendBillingSheet(true);
                      setSelectedBillingSheet(obj);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      style={{
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"
                        fill="#000000"
                      />
                    </svg>{' '}
                    Envoyer par email
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="1"
                    className="text-danger"
                    onClick={() => {
                      setSelectedBillingSheet(obj);
                      setShowDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      style={{
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                        fill="#e63757"
                      />
                    </svg>{' '}
                    Supprimer la fiche
                  </Dropdown.Item>
                </DropdownButton>
              </>
            )}
          </>
        )
      });
    });
    setObjects(transformedApiArray);
  };

  return (
    <Col xs={12} className="mt-3">
      <Table data={objects} />
      <Modal
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showSendBillingSheet}
        onHide={() => closeSendModal()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Col xs={11}>
            <b>Envoyer la fiche</b>
          </Col>
          <FalconCloseButton
            className="me-3"
            onClick={() => closeSendModal()}
          />
        </Modal.Header>
        <Modal.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form>
            <Form.Group controlId="content">
              <Form.Label>Message personnalisé :</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                name="content"
                placeholder="..."
                value={formData.message}
                onChange={e => {
                  setFormData({
                    ...formData,
                    message: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="content" className="mt-3">
              <Form.Label>
                Envoyer à des adresses libres{' '}
                <small>séparer les adresses email par des virgules</small> :
              </Form.Label>
              <Form.Control
                name="emails"
                value={formData.emails}
                onChange={e => {
                  setFormData({
                    ...formData,
                    emails: e.target.value
                  });
                }}
                type="text"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              closeSendModal();
            }}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="admin-primary m-lg-2"
            disabled={sendLoading}
            onClick={() => {
              handleSendSubmit();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"
                fill="rgba(255,255,255,1)"
              />
            </svg>{' '}
            Envoyer la fiche
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setSelectedBillingSheet(null);
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Supprimer la fiche ?</p>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteBillingSheet(selectedBillingSheet.id);
            }}
          >
            Oui, supprimer la fiche
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </Col>
  );
};
function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Titre'
      },
      {
        accessor: 'created_at',
        Header: 'Date de création',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'actions',
        Header: ''
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func
};

TableBillingSheet.propTypes = {
  billingSheets: PropTypes.array,
  setParentTrigger: PropTypes.func,
  prestation: PropTypes.object
};

export default TableBillingSheet;
