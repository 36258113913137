import React, { useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Tab,
  Tabs
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ManageBrochure from './ManageBrochure';
import TableBooklet from './TableBooklet';
import CanvaNotes from '../../canvas-components/canvaNotes';
import ManageEstimate from './ManageEstimate';
import TableQuote from './TableQuote';
import FalconCloseButton from '../../../common/FalconCloseButton';
import FormAlert from '../../commons/FormAlert';
import { SendBookletQuote } from '../../../../services/BookletService';
import { toast } from 'react-toastify';
const ManageBrochuresEstimates = ({
  setParentTrigger,
  prestation,
  productsFromPresta
}) => {
  const [showSend, setShowSend] = React.useState(false);
  const [errorForm, setErrorForm] = React.useState(null);
  const [formData, setFormData] = useState({
    message: '',
    emails: ''
  });
  const [sendLoading, setSendLoading] = useState(false);

  const handleSendSubmit = async () => {
    setSendLoading(true);
    const response = await SendBookletQuote(prestation.id, {
      message: formData.message,
      emails: formData.emails
    });
    setErrorForm(response.validator);
    if (response.success === true) {
      toast('Documents envoyés');
      setShowSend(false);
      setParentTrigger(Date.now());
      setSendLoading(false);
      setFormData({
        message: '',
        emails: ''
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setSendLoading(false);
  };
  return (
    <>
      <Col>
        <Card className="mt-3">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="">
              <Accordion.Header>
                <Col className="d-flex">
                  <h5 className="pt-1">Plaquette et devis</h5>
                </Col>
              </Accordion.Header>
              <Accordion.Body>
                <Button
                  variant="falcon-default"
                  className="mb-3"
                  size="sm"
                  onClick={() => {
                    setShowSend(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"
                      fill="#000000"
                    />
                  </svg>{' '}
                  Envoyer les documents par mail
                </Button>
                <Modal
                  backdrop="static"
                  keyboard={false}
                  size="lg"
                  show={showSend}
                  onHide={() => setShowSend(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header>
                    <Col xs={11}>
                      <b>Envoyer la plaquette et le devis</b>
                    </Col>
                    <FalconCloseButton
                      className="me-3"
                      onClick={() => setShowSend(false)}
                    />
                  </Modal.Header>
                  <Modal.Body>
                    {errorForm && (
                      <div className="mt-3">
                        <FormAlert variant="danger" data={errorForm} />
                      </div>
                    )}
                    <Form>
                      <Form.Group controlId="content">
                        <Form.Label>Message personnalisé :</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          name="content"
                          placeholder="..."
                          value={formData.message}
                          onChange={e => {
                            setFormData({
                              ...formData,
                              message: e.target.value
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="content" className="mt-3">
                        <Form.Label>
                          Envoyer à des adresses libres{' '}
                          <small>
                            séparer les adresses email par des virgules
                          </small>{' '}
                          :
                        </Form.Label>
                        <Form.Control
                          name="emails"
                          value={formData.emails}
                          onChange={e => {
                            setFormData({
                              ...formData,
                              emails: e.target.value
                            });
                          }}
                          type="text"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      onClick={() => {
                        setShowSend(false);
                      }}
                    >
                      Annuler
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      className="admin-primary m-lg-2"
                      disabled={sendLoading}
                      onClick={() => {
                        handleSendSubmit();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>{' '}
                      Envoyer la plaquette et le devis
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Tabs
                  className="mb-3"
                  defaultActiveKey="plaquette"
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    eventKey="plaquette"
                    title="La plaquette"
                    className="border-bottom border-x pb-3 px-3 pt-4"
                  >
                    <Col className="text-end">
                      <CanvaNotes
                        model={prestation}
                        noteableType={'prestation'}
                        setTrigger={setParentTrigger}
                        context={'brochures'}
                      />
                    </Col>
                    <Col
                      className={
                        prestation.booklets && prestation.booklets.length > 0
                          ? 'mb-4'
                          : ''
                      }
                    >
                      <ManageBrochure
                        setParentTrigger={setParentTrigger}
                        prestation={prestation}
                        action={'add'}
                        productsFromPresta={productsFromPresta}
                      />
                    </Col>
                    {prestation.booklets && prestation.booklets.length > 0 && (
                      <TableBooklet
                        booklets={prestation.booklets}
                        setParentTrigger={setParentTrigger}
                        prestation={prestation}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="devis"
                    title="Le devis"
                    className="border-bottom border-x pb-3 px-3 pt-4"
                  >
                    <Col className="text-end">
                      <CanvaNotes
                        model={prestation}
                        noteableType={'prestation'}
                        setTrigger={setParentTrigger}
                        context={'devis'}
                      />
                    </Col>
                    <Col>
                      <ManageEstimate
                        setParentTrigger={setParentTrigger}
                        prestation={prestation}
                      />
                    </Col>
                    {prestation.quotes && prestation.quotes.length > 0 && (
                      <TableQuote
                        quotes={prestation.quotes}
                        setParentTrigger={setParentTrigger}
                        prestation={prestation}
                      />
                    )}
                  </Tab>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
    </>
  );
};

export default ManageBrochuresEstimates;

ManageBrochuresEstimates.propTypes = {
  setParentTrigger: PropTypes.func,
  prestation: PropTypes.object,
  productsFromPresta: PropTypes.array
};
