import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import TableBackProducts from './table';
import { IndexProduct } from '../../../services/ProductService';
import ManageProduct from './Manage';
import IndexProductCategoriesCanvas from './categories';
import IndexProductSeasonsCanvas from './seasons';
import ManageResource from '../resources/ManageResource';
import IndexProductZonesCanvas from './zones';

const IndexProducts = () => {
  const [initialApiProducts, setInitialApiProducts] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [showCanvaProduct, setShowCanvaProduct] = useState(false); // ouvre et ferme le Manage OffCanva des produits
  const [selectedObject, setSelectedObject] = useState({});
  const [showProductsCategories, setShowProductsCategories] = useState(false); // afffiche l'index des catégories
  const [showProductsZones, setShowProductsZones] = useState(false); // afffiche l'index des catégories
  const [showProductsSeasons, setShowProductsSeasons] = useState(false); // affiche l'index des seasons
  const [showResource, setShowResource] = useState(false); // ouvre et ferme le Manage des ressources

  useEffect(() => {
    const fetchProducts = async () => {
      const responseProduct = await IndexProduct();
      if (responseProduct.success === true) {
        setInitialApiProducts(responseProduct.data);
      }
    };
    fetchProducts();
  }, [trigger]);

  return (
    <>
      {/* ---- Affiche l'index des saisons des produits ---- */}
      {showProductsSeasons && (
        <IndexProductSeasonsCanvas
          showProductsSeasons={showProductsSeasons}
          setShowProductsSeasons={setShowProductsSeasons}
          setParentTrigger={setTrigger}
        />
      )}

      {/* ---- affiche l'index des catégoeries des produits ---- */}
      {showProductsCategories && (
        <IndexProductCategoriesCanvas
          showProductsCategories={showProductsCategories}
          setShowProductsCategories={setShowProductsCategories}
          setParentTrigger={setTrigger}
        />
      )}
      {showProductsZones && (
        <IndexProductZonesCanvas
          showProductsZones={showProductsZones}
          setShowProductsZones={setShowProductsZones}
          setParentTrigger={setTrigger}
        />
      )}
      {/*  ---- affiche le Manage des produits ---- */}
      {showCanvaProduct && selectedObject && (
        <ManageProduct
          showCanvaProduct={showCanvaProduct}
          setShowCanvaProduct={setShowCanvaProduct}
          selectedObject={selectedObject}
          setParentTrigger={setTrigger}
        />
      )}

      {showResource && (
        <ManageResource
          showResource={showResource}
          setShowResource={setShowResource}
        />
      )}

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row lg={12} className="justify-content-between align-items-center">
            <Col lg={4}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des produits
              </h3>
            </Col>
            <Col xs={8} className="text-end">
              <DropdownButton
                as={ButtonGroup}
                variant="falcon-default"
                size="sm"
                title={
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path
                        d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                        fill="rgba(75,74,77,1)"
                      />
                    </svg>{' '}
                    Gestions
                  </>
                }
                className="me-2"
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    setShowProductsSeasons(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                  </svg>{' '}
                  Gestion des saisons
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    setShowProductsCategories(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                  </svg>{' '}
                  Gestion des catégories
                </Dropdown.Item>{' '}
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    setShowProductsZones(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                  </svg>{' '}
                  Gestion des zones
                </Dropdown.Item>{' '}
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    window.location.href = '/ingredients';
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                  </svg>{' '}
                  Gestion des ingrédients
                </Dropdown.Item>{' '}
              </DropdownButton>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowCanvaProduct(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un produit
              </Button>
            </Col>
          </Row>

          {initialApiProducts.length > 0 && (
            <TableBackProducts
              showCanvaProduct={showCanvaProduct}
              setShowCanvaProduct={setShowCanvaProduct}
              initialApiProducts={initialApiProducts}
              setSelectedObject={setSelectedObject}
              selectedObject={selectedObject}
              setParentTrigger={setTrigger}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default IndexProducts;
