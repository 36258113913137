import React, { useEffect, useState } from 'react';
import { Offcanvas, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IndexProductionZone } from '../../../../services/ProductionZoneService';
import OffCanvaProductZonesDelete from './OffCanvaProductZonesDelete';
import ManageProductZone from './ManageProductZone';

const IndexProductZonesCanvas = ({
  showProductsZones,
  setShowProductsZones,
  setParentTrigger
}) => {
  const [zones, setZones] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [selectedZone, setSelectedZone] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showManagezones, setShowManagezones] = useState(false);
  useEffect(() => {
    const fetchProductzones = async () => {
      const responsezones = await IndexProductionZone();
      if (responsezones.success === true) {
        setZones(responsezones.data);
      }
    };
    fetchProductzones();
  }, [trigger]);

  return (
    <>
      {showDelete && (
        <OffCanvaProductZonesDelete
          selectedZone={selectedZone}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          setTrigger={setTrigger}
          setGrandParentTrigger={setParentTrigger}
        />
      )}

      {showManagezones && (
        <ManageProductZone
          showManagezones={showManagezones}
          setShowManagezones={setShowManagezones}
          setTrigger={setTrigger}
          selectedZone={selectedZone}
        />
      )}

      <Offcanvas
        placement={'end'}
        show={showProductsZones}
        onHide={() => {
          setShowProductsZones(false);
          window.location.reload();
        }}
        style={{ width: '30%' }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Gestion des zones
            <a
              onClick={() => {
                setSelectedZone({});
                setShowManagezones(true);
              }}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>{' '}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {zones && zones.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <tbody>
                {zones.map((category, index) => (
                  <tr key={index}>
                    <td>{category.name}</td>
                    <td className="text-end">
                      <span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="hi">Modifier</Tooltip>}
                        >
                          <a
                            onClick={() => {
                              setSelectedZone(category);
                              setShowManagezones(true);
                            }}
                            className="cursor-pointer me-3"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                fill="rgba(44,123,229,1)"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="hi">Supprimer</Tooltip>}
                        >
                          <a
                            href="#"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedZone(category);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

IndexProductZonesCanvas.propTypes = {
  showProductsZones: PropTypes.bool,
  setShowProductsZones: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default IndexProductZonesCanvas;
