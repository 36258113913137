import { Alert, Button, Card, Col, Row } from 'react-bootstrap';

import React, { useEffect, useState } from 'react';
import SoftBadge from '../../../common/SoftBadge';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Moment from 'moment';
import { ShowPrestation } from '../../../../services/PrestationService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import SoftBadgeWithStyle from '../../commons/SoftBadgeWithStyle';
import ManagePrestationSheet from '../prestation-sheet/manage';
import ManageLogisticService from '../../service/components/ManageLogisticService';
import ManageLogisticSheet from '../../service/components/ManageLogisticSheet';

const ManageInfoPrestaButler = () => {
  const { id } = useParams();
  const [prestation, setPrestation] = useState({});
  const [trigger, setTrigger] = useState(Date.now);
  const [labelResources, setLabelResources] = useState([]);

  useEffect(() => {
    /* ---- récupère une prestation avec l'id de l'url ------*/
    const fetchPrestation = async () => {
      const response = await ShowPrestation(id);
      if (response.success === true) {
        /* ---- récupère la prestation*/
        const responseData = response.data;

        setPrestation(responseData);
      } else {
        toast('Une erreur est survenue');
      }
    };
    if (id) {
      fetchPrestation();
    }
  }, [trigger]);

  const isColorLight = hex => {
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true
  };
  const containerStyle = {
    width: '100%',
    height: '100%'
  };
  const mapCenter = {
    lat: parseFloat(prestation.latitude),
    lng: parseFloat(prestation.longitude)
  };

  const position = {
    lat: parseFloat(prestation.latitude),
    lng: parseFloat(prestation.longitude)
  };
  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-sm-between align-items-center">
            <Col>
              <h3 className="mb-0"> {prestation && prestation.name}</h3>
              {prestation.type && (
                <SoftBadgeWithStyle
                  style={{
                    backgroundColor: prestation.type.color,
                    color: isColorLight(prestation.type.color)
                      ? '#000000'
                      : '#FFFFFF'
                  }}
                >
                  {prestation.type.name}
                </SoftBadgeWithStyle>
              )}
              {prestation.date && (
                <SoftBadge bg="primary" className="text-capitalize ms-3">
                  {Moment(prestation.date).local().format('DD/MM/YYYY')}
                </SoftBadge>
              )}
            </Col>
            <Col lg={6} className="text-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  window.location.href = '/maitre-d-hotel';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Retour
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setTrigger(Date.now());
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M12 4C9.25144 4 6.82508 5.38626 5.38443 7.5H8V9.5H2V3.5H4V5.99936C5.82381 3.57166 8.72764 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4ZM4 12C4 16.4183 7.58172 20 12 20C14.7486 20 17.1749 18.6137 18.6156 16.5H16V14.5H22V20.5H20V18.0006C18.1762 20.4283 15.2724 22 12 22C6.47715 22 2 17.5228 2 12H4Z"
                    fill="#4d5969"
                  />
                </svg>
                Rafraîchir
              </Button>
              {prestation &&
                prestation.booklets &&
                prestation.booklets.length > 0 && (
                  <Button
                    variant="falcon-default"
                    size="sm"
                    as={Link}
                    to={prestation.booklets[0].file_url}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"
                        fill="#4d5969"
                      />
                    </svg>
                    Plaquette
                  </Button>
                )}
              {prestation &&
                prestation.quotes &&
                prestation.quotes.length > 0 && (
                  <Button
                    variant="falcon-default"
                    className="ms-2"
                    size="sm"
                    as={Link}
                    to={prestation.quotes[0].file_url_without_prices}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"
                        fill="#4d5969"
                      />
                    </svg>
                    Devis
                  </Button>
                )}
              {prestation &&
                prestation.logistic_sheets &&
                prestation.logistic_sheets.filter(l => l.is_valid === 1)
                  .length > 0 && (
                  <Button
                    variant="falcon-default"
                    className="ms-2"
                    size="sm"
                    as={Link}
                    to={
                      prestation.logistic_sheets.filter(
                        l => l.is_valid === 1
                      )[0].file_url
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M8.96456 18C8.72194 19.6961 7.26324 21 5.5 21C3.73676 21 2.27806 19.6961 2.03544 18H1V6C1 5.44772 1.44772 5 2 5H16C16.5523 5 17 5.44772 17 6V8H20L23 12.0557V18H20.9646C20.7219 19.6961 19.2632 21 17.5 21C15.7368 21 14.2781 19.6961 14.0354 18H8.96456ZM15 7H3V15.0505C3.63526 14.4022 4.52066 14 5.5 14C6.8962 14 8.10145 14.8175 8.66318 16H14.3368C14.5045 15.647 14.7296 15.3264 15 15.0505V7ZM17 13H21V12.715L18.9917 10H17V13ZM17.5 19C18.1531 19 18.7087 18.5826 18.9146 18C18.9699 17.8436 19 17.6753 19 17.5C19 16.6716 18.3284 16 17.5 16C16.6716 16 16 16.6716 16 17.5C16 17.6753 16.0301 17.8436 16.0854 18C16.2913 18.5826 16.8469 19 17.5 19ZM7 17.5C7 16.6716 6.32843 16 5.5 16C4.67157 16 4 16.6716 4 17.5C4 17.6753 4.03008 17.8436 4.08535 18C4.29127 18.5826 4.84689 19 5.5 19C6.15311 19 6.70873 18.5826 6.91465 18C6.96992 17.8436 7 17.6753 7 17.5Z"
                        fill="#4d5969"
                      />
                    </svg>
                    Fiche logistique
                  </Button>
                )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body className="p-0">
          <Row>
            <Col xs={6} className="px-5 py-4">
              <h5 className="mt-2 mb-4">Informations générales</h5>
              {prestation.customer && (
                <>
                  <span className="text-600 fw-medium fs--1 d-block mb-1">
                    Client :{' '}
                  </span>
                  <h5 className="text-800 mb-0">{prestation.customer.name}</h5>
                </>
              )}
              {prestation.address && (
                <>
                  <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                    Lieu :{' '}
                  </span>
                  <h6 className="text-800 mb-0">
                    <a
                      href={`https://www.google.com/maps/place/${prestation.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {prestation.address}
                    </a>{' '}
                    <small>{prestation.address_details}</small>
                  </h6>
                </>
              )}
              <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                Date / heure :{' '}
              </span>
              <h5 className="text-800 mb-0">
                {Moment(prestation.date).local().format('DD/MM/YYYY')} -{' '}
                {moment(prestation.time, 'HH:mm:ss').format('HH')}
                {':'}
                {moment(prestation.time, 'HH:mm:ss').format('mm')}{' '}
                <small>
                  <small>
                    Heure arrivée AVS :{' '}
                    {moment(prestation.time_team, 'HH:mm:ss').format('HH')}
                    {':'}
                    {moment(prestation.time_team, 'HH:mm:ss').format('mm')}
                  </small>
                  <br />
                  <small>
                    Heure départ AVS :{' '}
                    {moment(prestation.time_team_end, 'HH:mm:ss').format('HH')}
                    {':'}
                    {moment(prestation.time_team_end, 'HH:mm:ss').format('mm')}
                  </small>
                </small>
              </h5>
              <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                Nombre de convives :{' '}
              </span>
              <h5 className="text-800 mb-3">{prestation.guest_count}</h5>

              {prestation.notes && prestation.notes.length > 0 && (
                <Row>
                  {prestation.notes.map((note, index) => (
                    <div key={index}>
                      <Col xs={12}>
                        <Alert variant={'primary'}>
                          <Row>
                            <Col xs={12} className="text-align-start">
                              {note.content}
                            </Col>
                            <Col xs={12} className="text-align-end mt-3">
                              <small>
                                {Moment(note.created_at)
                                  .local()
                                  .format('DD/MM/yyyy')}
                              </small>
                            </Col>
                          </Row>
                        </Alert>
                      </Col>
                    </div>
                  ))}
                </Row>
              )}

              {/*<Table responsive>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '80%', paddingLeft: 0 }}>
                      Ressources humaines :
                    </th>
                    <th scope="col" style={{ width: '150px', paddingLeft: 0 }}>
                      Heure début
                    </th>
                  </tr>
                </thead>
                {prestation.butlers?.length > 0 && (
                  <tbody>
                    {prestation.butlers.map((butler, index) => (
                      <tr key={index}>
                        <td style={{ paddingLeft: 0 }}>
                          {butler.firstname} {butler.lastname}{' '}
                          {butler.pivot.mission && (
                            <small>{butler.pivot.mission}</small>
                          )}
                        </td>
                        <td style={{ paddingLeft: 0 }}>
                          {butler.pivot.start_at && (
                            <span>
                              {moment(butler.pivot.start_at).format(
                                'DD/MM HH:mm'
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>*/}

              {prestation.vehicles?.length > 0 && (
                <>
                  <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                    Véhicules :{' '}
                  </span>
                  <h5 className="text-800 mb-3">
                    <small>
                      {prestation.vehicles.map((vehicle, index) => (
                        <span key={index}>
                          {vehicle.name}
                          {prestation.vehicles.length > 1 &&
                            index < prestation.vehicles.length - 1 &&
                            ', '}
                        </span>
                      ))}
                    </small>
                  </h5>
                </>
              )}
            </Col>
            <Col xs={6} className="py-0 pe-3">
              {prestation &&
                prestation.latitude &&
                prestation.longitude &&
                mapCenter !== false && (
                  <GoogleMap
                    center={mapCenter}
                    options={mapOptions}
                    zoom={15}
                    mapContainerStyle={containerStyle}
                  >
                    <MarkerF position={position} />
                  </GoogleMap>
                )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {prestation && prestation.resources && (
        <ManagePrestationSheet
          prestation={prestation}
          setParentTrigger={setTrigger}
        />
      )}
      {prestation &&
        prestation.id &&
        localStorage.getItem('user_avs') &&
        JSON.parse(localStorage.getItem('user_avs')).is_master_butler !== 0 && (
          <>
            <ManageLogisticService
              prestation={prestation}
              labelResources={labelResources}
              setLabelResources={setLabelResources}
              setParentTrigger={setTrigger}
            />
            <ManageLogisticSheet
              prestation={prestation}
              setParentTrigger={setTrigger}
            />
          </>
        )}
    </>
  );
};

ManageInfoPrestaButler.propTypes = {
  prestation: PropTypes.object
};

export default ManageInfoPrestaButler;
