import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'users';

export async function IndexUser() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowUser(id, role_id) {
  return modelService.ShowModel(
    `${model}/${id}${role_id && `?role_id=${role_id}`}`
  );
}

export async function PutUser(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostUser(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteUser(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
