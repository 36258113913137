import { Button, Form, Offcanvas } from 'react-bootstrap';
import FalconCloseButton from '../../../common/FalconCloseButton';
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';

const OffCanvaFolder = ({
  showCanvaFolder,
  setShowCanvaFolder,
  selectedObject,
  setSelectedObject,
  formDataFolder,
  setFormDataFolder,
  splittedFolders,
  handleSubmitFolders,
  handleFieldChange,
  currentFolder,
  errorFormFolders,
  setErrorFormFolders
}) => {
  useEffect(() => {
    if (selectedObject && selectedObject.id) {
      setFormDataFolder({
        ...formDataFolder,
        parent_id: selectedObject.id
      });
    } else if (currentFolder && currentFolder.id) {
      setFormDataFolder({
        ...formDataFolder,
        parent_id: currentFolder.id
      });
    }
  }, []);
  return (
    <>
      <Offcanvas
        placement="end"
        show={showCanvaFolder}
        onShow={() => {
          let obj = splittedFolders.filter(
            o => parseInt(o.id) === parseInt(selectedObject.id)
          )[0];
          if (obj) {
            setFormDataFolder({
              name: obj.name,
              parent_id: obj.parent_id
            });
          }
        }}
        onHide={() => {
          setShowCanvaFolder(false);
          setFormDataFolder({});
          setErrorFormFolders(false);
        }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {selectedObject.id ? `Modifier le dossier` : 'Ajouter un dossier'}
          </Offcanvas.Title>
          <FalconCloseButton
            onClick={() => {
              setShowCanvaFolder(false);
              setErrorFormFolders(false);
              setFormDataFolder({
                name: '',
                parent_id: ''
              });
              setSelectedObject({});
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorFormFolders && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorFormFolders} />
            </div>
          )}
          <Form onSubmit={handleSubmitFolders}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nom du dossier</Form.Label>
              <Form.Control
                onChange={handleFieldChange}
                name="name"
                type="text"
                placeholder=" "
                value={formDataFolder.name}
              />
            </Form.Group>
            {/*<Col xs={12}>
              <Form.Group controlId="parent_id">
                {selectedObject && !selectedObject.id && (
                  <>
                    <Form.Label>
                      Catégorie <b>parent</b> :
                    </Form.Label>
                    <Form.Select
                      aria-label="Dossier parent"
                      name="parent_id"
                      value={formDataFolder.parent_id}
                      onChange={e => {
                        handleFieldChange(e);
                      }}
                    >
                      <option value="">Aucune</option>
                      {objects
                        .filter(o => !o.parent_id)
                        .map((f, index) => (
                          <RecursiveFolder
                            folder={f}
                            level={0}
                            key={index}
                            selectedObject={selectedObject}
                          />
                        ))}
                    </Form.Select>
                  </>
                )}
              </Form.Group>
            </Col>*/}
            <Button
              className="mt-3"
              onClick={e => {
                handleSubmitFolders(e);
              }}
              variant="primary"
            >
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const RecursiveFolder = ({ folder, level, selectedObject }) => {
  return (
    <Fragment>
      <option
        disabled={
          selectedObject &&
          selectedObject.id &&
          parseInt(selectedObject.id) === parseInt(folder.id)
        }
        value={folder.id}
      >
        {getRecursiveFolderSpaces(level)}
        {folder.name}
      </option>
      {folder.childrens &&
        folder.childrens.length > 0 &&
        folder.childrens.map((children, index) => (
          <RecursiveFolder
            key={index}
            folder={children}
            level={level + 1}
            selectedObject={selectedObject}
          />
        ))}
    </Fragment>
  );
};

const getRecursiveFolderSpaces = level => {
  let spaces = '';
  for (let i = 0; i < level; i++) {
    spaces += '--';
  }
  return `${spaces} `;
};

RecursiveFolder.propTypes = {
  folder: PropTypes.object,
  level: PropTypes.number,
  disabled: PropTypes.any,
  selectedObject: PropTypes.any
};
OffCanvaFolder.propTypes = {
  showCanvaFolder: PropTypes.bool,
  splittedFolders: PropTypes.array,
  selectedObject: PropTypes.object,
  setFormDataFolder: PropTypes.func,
  setShowCanvaFolder: PropTypes.func,
  setSelectedObject: PropTypes.func,
  handleSubmitFolders: PropTypes.func,
  handleFieldChange: PropTypes.func,
  formDataFolder: PropTypes.object,
  currentFolder: PropTypes.object,
  errorFormFolders: PropTypes.bool || PropTypes.object,
  setErrorFormFolders: PropTypes.func
};
export default OffCanvaFolder;
