import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'roles';

export async function IndexRole() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowRole(id, role_id) {
  return modelService.ShowModel(
    `${model}/${id}${role_id && `?role_id=${role_id}`}`
  );
}

export async function PutRole(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostRole(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteRole(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
