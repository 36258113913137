export default function RedirectIfAuthenticated(navigate) {
  if (
    localStorage.getItem('accessToken_avs') &&
    localStorage.getItem('user_avs')
  ) {
    const user = JSON.parse(localStorage.getItem('user_avs'));
    if (user && [1, 3, 4].includes(user.role_id)) {
      navigate('/calendrier');
    } else if (user && user.role_id === 2) {
      navigate('/maitre-d-hotel');
    }
  }
}
