import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'logistic-sheets';

export async function GenerateLogisticSheetFile(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutLogisticSheet(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function SendLogisticSheet(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function DeleteLogisticSheet(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
