import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PostVehicles, PutVehicles } from '../../../services/VehiclesService';
import { toast } from 'react-toastify';
import FormAlert from '../commons/FormAlert';

const ManageCanvaVehicles = ({
  showVehicle,
  setShowVehicle,
  selectedObject,
  setParentTrigger,
  setShowDelete,
  setGrandParentTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    name: ''
  });

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;

    if (selectedObject && selectedObject.id) {
      response = await PutVehicles(
        selectedObject && selectedObject.id,
        formData
      );
    } else {
      response = await PostVehicles(formData);
    }

    if (response.success === true) {
      toast('Véhicule enregistré');
      setShowVehicle(false);
      setParentTrigger(Date.now());
      setGrandParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setFormData({
      name: selectedObject && selectedObject.name
    });
  }, []);

  return (
    <>
      {/* canva pour créer et modifier un véhicule*/}
      <Offcanvas
        placement={'end'}
        show={showVehicle}
        onHide={() => {
          setShowVehicle(false);
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject && selectedObject.id
              ? 'Modifier le véhicule'
              : 'Ajouter un véhicule'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Nom du véhicule</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Ford focus ..."
                value={formData.name || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Form>

          <Col className="mt-3">
            <Button
              onClick={e => {
                handleSubmit(e);
              }}
              size="sm"
            >
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              {selectedObject && selectedObject.id
                ? ' Enregistrer les modifications'
                : ' Enregistrer le vévicule'}
            </Button>
            {selectedObject.id && (
              <Button
                size="sm"
                variant="danger"
                className="me-0 float-end"
                type="button"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px',
                    marginRight: '3px'
                  }}
                >
                  <path
                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            )}
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageCanvaVehicles.propTypes = {
  showVehicle: PropTypes.bool,
  setShowVehicle: PropTypes.func,
  setParentTrigger: PropTypes.func,
  setShowDelete: PropTypes.func,
  setGrandParentTrigger: PropTypes.func,
  selectedObject: PropTypes.object
};
export default ManageCanvaVehicles;
