import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DeleteProductCategories } from '../../../../services/ProductCategoriesService';

const OffCanvaProductCategoriesDelete = ({
  showDelete,
  setShowDelete,
  selectedCategory,
  setTrigger,
  setGrandParentTrigger
}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const deleteResourceCategories = async () => {
    const response = await DeleteProductCategories(selectedCategory.id);
    if (response.success === true) {
      setIsLoadingSubmit(true);
      setShowDelete(false);
      toast(`Catégorie ${selectedCategory.name} supprimé`);
      setTrigger(Date.now());
      setGrandParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <Offcanvas
      show={showDelete}
      onHide={() => {
        setShowDelete(false);
      }}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>
          Supprimer la catégorie :{' '}
          <span style={{ fontWeight: 'bold' }}>{selectedCategory.name}</span> ?
        </p>
        <Button
          disabled={isLoadingSubmit}
          size="sm"
          variant="falcon-danger"
          className="me-2"
          onClick={() => {
            deleteResourceCategories();
          }}
        >
          Oui, supprimer la catégorie
        </Button>
        <Button
          size="sm"
          variant="falcon-default"
          className="me-2"
          onClick={() => {
            setShowDelete(false);
          }}
        >
          Annuler
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

OffCanvaProductCategoriesDelete.propTypes = {
  showDelete: PropTypes.bool,
  setShowDelete: PropTypes.func,
  selectedCategory: PropTypes.object.isRequired,
  setTrigger: PropTypes.func.isRequired,
  setGrandParentTrigger: PropTypes.func
};

export default OffCanvaProductCategoriesDelete;
