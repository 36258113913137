import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DeleteSeasons } from '../../../../services/ProductSeasonsService';
const OffCanvaProductSeasonDelete = ({
  showDelete,
  setShowDelete,
  selectedSeason,
  setTrigger,
  setGrandParentTrigger
}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const deleteResourceSeason = async () => {
    const response = await DeleteSeasons(selectedSeason.id);
    if (response.success === true) {
      setIsLoadingSubmit(true);
      setShowDelete(false);
      toast(`Saison ${selectedSeason.name} supprimé`);
      setTrigger(Date.now());
      setGrandParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <Offcanvas
      show={showDelete}
      onHide={() => {
        setShowDelete(false);
      }}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>
          Supprimer la saison :{' '}
          <span style={{ fontWeight: 'bold' }}> {selectedSeason.name} </span> ?
        </p>
        <Button
          disabled={isLoadingSubmit}
          size="sm"
          variant="falcon-danger"
          className="me-2"
          onClick={() => {
            deleteResourceSeason();
          }}
        >
          Oui, supprimer la saison
        </Button>
        <Button
          size="sm"
          variant="falcon-default"
          className="me-2"
          onClick={() => {
            setShowDelete(false);
          }}
        >
          Annuler
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

OffCanvaProductSeasonDelete.propTypes = {
  showDelete: PropTypes.bool,
  setShowDelete: PropTypes.func,
  selectedSeason: PropTypes.object.isRequired,
  setTrigger: PropTypes.func,
  setGrandParentTrigger: PropTypes.func
};

export default OffCanvaProductSeasonDelete;
