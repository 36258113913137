import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'files';

export async function IndexFile() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowFile(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostFile(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutFile(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteFile(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
