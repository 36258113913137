import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import FalconCloseButton from '../../../common/FalconCloseButton';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FileUploader from '../../users/fileUpload/FileUploader';
import FormAlert from '../../commons/FormAlert';

const OffCanvaFile = ({
  showCanvaFile,
  setShowCanvaFile,
  formDataFile,
  setFormDataFile,
  handleFieldChangeFile,
  handleSubmitFiles,
  selectedObject,
  currentFolder,
  errorFormFiles,
  setErrorFormFiles
}) => {
  useEffect(() => {
    if (selectedObject && selectedObject.id) {
      setFormDataFile({
        ...formDataFile,
        name: selectedObject.name,
        url: selectedObject.url,
        folder_id: selectedObject.folder_id
      });
    } else if (currentFolder && currentFolder.id) {
      setFormDataFile({
        ...formDataFile,
        folder_id: currentFolder.id
      });
    }
  }, []);
  return (
    <>
      <Offcanvas
        placement="end"
        show={showCanvaFile}
        onHide={() => {
          setShowCanvaFile(false);
          setFormDataFile({});
          setErrorFormFiles(false);
          /*
          setFormDataFile({
            name: '',
            url: '',
            folder_id: ''
          });*/
        }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {selectedObject && selectedObject.id
              ? 'Modifier le fichier'
              : 'Ajouter un fichier'}
          </Offcanvas.Title>
          <FalconCloseButton
            onClick={() => {
              setShowCanvaFile(false);
              setErrorFormFiles(false);
              setFormDataFile({
                name: '',
                url: '',
                folder_id: ''
              });
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorFormFiles && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorFormFiles} />
            </div>
          )}
          <Form onSubmit={handleSubmitFiles}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nom du fichier</Form.Label>
              <Form.Control
                onChange={handleFieldChangeFile}
                name="name"
                type="text"
                placeholder=" "
                value={formDataFile.name}
              />
            </Form.Group>
            {/*  {!selectedObject && (*/}
            <Col xs={12}>
              <Form.Group controlId="parent_id">
                <FileUploader
                  pathToUpload="sharedfiles"
                  formData={formDataFile}
                  setFormData={setFormDataFile}
                  format="any"
                  databaseField="url"
                  cropperEnabled={false}
                  progressBar={true}
                />
              </Form.Group>
            </Col>
            {/*    )}*/}
            <Button
              className="mt-3"
              onClick={e => {
                handleSubmitFiles(e);
              }}
              variant="primary"
            >
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

OffCanvaFile.propTypes = {
  showCanvaFile: PropTypes.bool,
  setShowCanvaFile: PropTypes.func,
  formDataFile: PropTypes.object,
  setFormDataFile: PropTypes.func,
  handleFieldChangeFile: PropTypes.func,
  handleSubmitFiles: PropTypes.func,
  selectedObject: PropTypes.object,
  currentFolder: PropTypes.object,
  errorFormFiles: PropTypes.bool || PropTypes.object,
  setErrorFormFiles: PropTypes.func
};

export default OffCanvaFile;
