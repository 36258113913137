import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import React from 'react';
import { Nav } from 'react-bootstrap';
import MagicBellNotifications from './MagicBell';

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {localStorage.getItem('user_avs') &&
        JSON.parse(localStorage.getItem('user_avs')).magic_bell_uuid !==
          undefined &&
        JSON.parse(localStorage.getItem('user_avs')).magic_bell_uuid !==
          null && (
          <span style={{ position: 'relative', bottom: '2px', right: '4px' }}>
            <MagicBellNotifications />
          </span>
        )}
      {/*<NotificationDropdown />*/}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
