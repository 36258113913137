import React from 'react';
import MagicBell, {
  FloatingNotificationInbox
} from '@magicbell/magicbell-react';
export default function MagicBellNotifications() {
  return (
    <MagicBell
      apiKey="525edc08ff040836e56b0a7d8165568283497198"
      userExternalId={
        JSON.parse(localStorage.getItem('user_avs')).magic_bell_uuid
      }
      theme={theme}
      locale="en"
    >
      {props => (
        <FloatingNotificationInbox width={400} height={500} {...props} />
      )}
    </MagicBell>
  );
}
const theme = {
  header: {
    fontFamily: 'inherit',
    backgroundColor: '#FFFFFF',
    textColor: '#1A1A1A',
    fontSize: '15px',
    borderRadius: '16px'
  },
  notification: {
    default: {
      fontSize: '14px',
      borderRadius: '16px',
      margin: '8px',
      backgroundColor: '#FFFFFF',
      textColor: '#3A424D',
      fontFamily: 'inherit'
    },
    unread: {
      backgroundColor: '#F8F5FF',
      textColor: '#3A424D',
      hover: { backgroundColor: '#F2EDFC' },
      state: { color: '#BE9039' }
    },
    unseen: {
      backgroundColor: '#F8F5FF',
      textColor: '#3A424D',
      hover: { backgroundColor: '#F2EDFC' },
      state: { color: '#BE9039' }
    }
  },
  icon: { borderColor: '#BE9039', width: '26px' },
  unseenBadge: { backgroundColor: '#BE9039' },
  banner: {
    backgroundColor: '#F8F5FF',
    textColor: '#3A424D',
    fontSize: '14px'
  },
  dialog: {
    backgroundColor: '#F5F5F5',
    textColor: '#313131',
    accentColor: '#BE9039'
  },
  footer: {
    fontFamily: 'inherit',
    backgroundColor: '#FFFFFF',
    textColor: '#1A1A1A',
    fontSize: '15px',
    borderRadius: '16px'
  }
};
