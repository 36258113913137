import React from 'react';
import { Accordion, Card, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CanvaNotes from '../../canvas-components/canvaNotes';
import GenerateBillingSheet from './GenerateBillingSheet';
import TableBillingSheet from './TableBillingSheet';
const ManageBillingSheet = ({ setParentTrigger, prestation }) => {
  return (
    <>
      <Col>
        <Card className="mt-3">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="">
              <Accordion.Header>
                <Col className="d-flex">
                  <h5 className="pt-1">Fiche de facturation</h5>
                </Col>
              </Accordion.Header>
              <Accordion.Body>
                <Col className="text-end">
                  <CanvaNotes
                    model={prestation}
                    noteableType={'prestation'}
                    setTrigger={setParentTrigger}
                    context={'billing_sheet'}
                  />
                </Col>
                <Col>
                  <GenerateBillingSheet
                    setParentTrigger={setParentTrigger}
                    prestation={prestation}
                  />
                </Col>
                {prestation.billing_sheets &&
                  prestation.billing_sheets.length > 0 && (
                    <TableBillingSheet
                      billingSheets={prestation.billing_sheets}
                      setParentTrigger={setParentTrigger}
                      prestation={prestation}
                    />
                  )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
    </>
  );
};

export default ManageBillingSheet;

ManageBillingSheet.propTypes = {
  setParentTrigger: PropTypes.func,
  prestation: PropTypes.object
};
