import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { IndexProduct } from '../../../../services/ProductService';
import { IndexSeasons } from '../../../../services/ProductSeasonsService';
import FalconCloseButton from '../../../common/FalconCloseButton';
import {
  PostProductBatches,
  ShowProductBatches
} from '../../../../services/ProductBatchService';
import FormAlert from '../../commons/FormAlert';
const ManageProductBatchesPrestation = ({
  showCanvaAddBatchFromPrestation,
  setShowCanvaAddBatchFromPrestation,
  selectedProductInput,
  prestation,
  setParentTrigger
}) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [labelSeasonsProduct, setLabelSeasonsProduct] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [labelProduct, setLabelProduct] = useState([]);
  const [formData, setFormData] = useState({
    booklet_name: '',
    invoice_name: '',
    default_price: '',
    default_vat: 20,
    reference: '',
    products: [],
    seasons: []
  });
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  /*  const handleDelete = async () => {
    const deleteBatch = async () => {
      const response = await DeleteProductBatches(selectedBatch.id);
      if (response.success === true) {
        toast('Lot supprimé avec succès');
        setShowDelete(false);
        setShowCanvaAddBatchFromPrestation(false);
        setTrigger(Date.now());
        setParentTrigger(Date.now());
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (selectedBatch.id) {
      deleteBatch();
    }
  };*/

  useEffect(() => {
    const fetchOneBatch = async () => {
      const response = await ShowProductBatches(selectedProductInput.id);
      /*    let tmpSelectedBatch = [];
      tmpSelectedBatch.push({
        id: response.data.id,
        value: response.data.id,
        label: response.data.booklet_name
      });*/

      let tmpProduct = [];
      if (response.data.products.length > 0) {
        response.data.products.map(product => {
          tmpProduct.push({
            value: product.id,
            label: product.production_sheet_name,
            quantity: product.pivot.quantity,
            default_unit: product.pivot.default_unit
          });
        });
      }

      let tmpSeasons = [];
      if (response.data.seasons.length > 0) {
        response.data.seasons.map(season => {
          tmpSeasons.push({
            value: season.id,
            label: season.name
          });
        });
      }

      if (response.success === true) {
        setFormData({
          ...formData,
          booklet_name: response.data.booklet_name,
          invoice_name: response.data.invoice_name,
          default_price: response.data.default_price,
          default_vat: response.data.default_vat,
          reference: response.data.reference,
          prestation_id: response.data.prestation_id,
          products: tmpProduct,
          seasons: tmpSeasons
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchOneBatch();

    const fetchAllProduct = async () => {
      const responseProduct = await IndexProduct();
      if (responseProduct.success === true) {
        let tmpProductArray = [];
        responseProduct.data.map(product => {
          tmpProductArray.push({
            value: product.id,
            label: product.production_sheet_name,
            quantity: 1,
            default_unit: product.default_unit
          });
        });
        setLabelProduct(tmpProductArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchAllProductSeasons = async () => {
      const responseProductSeasons = await IndexSeasons();
      if (responseProductSeasons.success === true) {
        let tmpSeasonsArray = [];
        responseProductSeasons.data.map(season => {
          tmpSeasonsArray.push({
            value: season.id,
            label: season.name
          });
        });

        setLabelSeasonsProduct(tmpSeasonsArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchAllProduct();
    fetchAllProductSeasons();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    let tmpFormData = {
      ...formData,
      prestation_id: prestation.id,
      seasons: formData.seasons.map(season => season.value),
      products: formData.products.map(product => {
        return {
          product_id: product.value,
          quantity: product.quantity,
          default_unit: product.default_unit
        };
      }),
      /*  products: formData.products.map(product => product.value),*/
      context: 'products'
    };
    let response = false;
    response = await PostProductBatches(tmpFormData);
    if (response.success === true) {
      toast('Lot de produit personalisé avec succès');
      setShowCanvaAddBatchFromPrestation(false);
      setTrigger(Date.now());
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenu');
    }
    setErrorForm(response.validator);
  };

  return (
    <>
      {showCanvaAddBatchFromPrestation && (
        <Offcanvas
          className="w-50"
          placement="end"
          show={showCanvaAddBatchFromPrestation}
          onHide={() => {
            setShowCanvaAddBatchFromPrestation(false);
          }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Dupliquer le lot</Offcanvas.Title>
            <FalconCloseButton
              onClick={() => {
                setShowCanvaAddBatchFromPrestation(false);
                setFormData({});
              }}
            />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="reference">
                    <Form.Label>Référence :</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference"
                      placeholder="Choisir la référence"
                      value={formData.reference || ''}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="default_price">
                    <Form.Label>Prix :</Form.Label>
                    <Form.Control
                      type="number"
                      name="default_price"
                      placeholder="Choisir son prix"
                      value={formData.default_price || ''}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="default_vat">
                    <Form.Label>Tranche TVA :</Form.Label>
                    <Form.Select
                      name="default_vat"
                      placeholder="Tranche TVA"
                      value={formData.default_vat || ''}
                      onChange={handleFieldChange}
                    >
                      {formData.default_vat === '5.50' ? (
                        <>
                          <option value="5.50">5,50%</option>
                          <option value="10">10%</option>
                          <option value="20">20%</option>
                        </>
                      ) : formData.default_vat === '10.00' ? (
                        <>
                          <option value="10">10%</option>
                          <option value="5.50">5.50%</option>
                          <option value="20">20%</option>
                        </>
                      ) : (
                        <>
                          <option value="20">20%</option>
                          <option value="5.50">5.50%</option>
                          <option value="10">10%</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group controlId="products">
                    <Form.Label>Produits :</Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={true}
                      options={labelProduct}
                      placeholder="Produits.."
                      name="products"
                      classNamePrefix="react-select"
                      value={formData.products}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          products: value
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>
              {formData.products && formData.products.length > 0 && (
                <Row className="mt-3">
                  <Col xs={12}>
                    <Table bordered className="admin-table mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Produit</th>
                          <th scope="col">
                            Quantité <small>pour un lot</small>
                          </th>
                          <th scope="col">Unité</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.products.map((product, index) => (
                          <tr key={index}>
                            <td>{product.label}</td>
                            <td>
                              <Form.Control
                                type="number"
                                name="quantity"
                                placeholder={'1'}
                                value={product.quantity}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    products: formData.products.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            quantity: e.target.value
                                          };
                                        }
                                        return item;
                                      }
                                    )
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Select
                                value={product.default_unit}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    products: formData.products.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            default_unit: e.target.value
                                          };
                                        }
                                        return item;
                                      }
                                    )
                                  });
                                }}
                                className="form-select"
                                aria-label="default_unit"
                                name="default_unit"
                              >
                                <option
                                  disabled={product.default_unit ? true : false}
                                >
                                  Choisir ...
                                </option>
                                <option value="piece">Pièce</option>
                                <option value="part">Part</option>
                                <option value="kilo">Kilo</option>
                                <option value="liter">Litre</option>
                              </Form.Select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  {' '}
                  <Form.Group controlId="seasons">
                    <Form.Label>Saisons :</Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      isMulti
                      options={labelSeasonsProduct}
                      placeholder="Saison(s) du produit..."
                      name="seasons"
                      classNamePrefix="react-select"
                      onChange={value => {
                        setFormData({
                          ...formData,
                          seasons: value
                        });
                      }}
                      value={formData.seasons}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>
              <Col
                style={{ borderTop: 'dashed 1px #d8e2ef' }}
                className="my-4"
              ></Col>

              <Col>
                <Form.Group className="mt-2" controlId="booklet_name">
                  <Form.Label>Dénomination de la plaquette :</Form.Label>
                  <Form.Control
                    type="text"
                    name="booklet_name"
                    placeholder="Nom sur la plaquette"
                    value={formData.booklet_name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>

              <Col className="mt-3">
                <Form.Group className="mt-2" controlId="invoice_name">
                  <Form.Label>Dénomination du devis :</Form.Label>
                  <Form.Control
                    type="text"
                    name="invoice_name"
                    placeholder="Nom sur le devis"
                    value={formData.invoice_name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-3">
                <Button
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  size="sm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  {selectedProductInput && selectedProductInput.id
                    ? ' Enregistrer les modifications'
                    : ' Enregistrer le nouveau lot de produits'}
                </Button>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageProductBatchesPrestation.propTypes = {
  showCanvaAddBatchFromPrestation: PropTypes.bool,
  setShowCanvaAddBatchFromPrestation: PropTypes.func,
  setParentTrigger: PropTypes.func,
  handleCloneProduct: PropTypes.func,
  selectedProductInput: PropTypes.object,
  productClonedPosition: PropTypes.number,
  prestation: PropTypes.object
};

export default ManageProductBatchesPrestation;
