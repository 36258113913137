import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Dropdown
} from 'react-bootstrap';
import FileUploader from '../../users/fileUpload/FileUploader';
import {
  PostBooklet,
  PutBooklet,
  ShowBooklet
} from '../../../../services/BookletService';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';
import FalconCloseButton from '../../../common/FalconCloseButton';

const ManageBrochure = ({
  setParentTrigger,
  prestation,
  selectedObject,
  action
}) => {
  const [showCanvaBrochure, setShowCanvaBrochure] = useState(false);
  const [formData, setFormData] = useState({
    prestation_id: prestation.id,
    cover_image_url: '',
    cover_title: prestation.name,
    cover_subtitle: '',
    price_display: 'including',
    show_title: '1'
  });
  /* const [labelProducts, setLabelProducts] = useState([]);*/
  const [isSaving, setIsSaving] = useState(false);
  const [errorFormBooklet, setErrorFormBooklet] = useState(false);

  const handleSubmit = async () => {
    setIsSaving(true);
    const response = await PostBooklet({
      prestation_id: formData.prestation_id,
      cover_image_url: formData.cover_image_url,
      cover_title: formData.cover_title,
      price_display: formData.price_display,
      show_title: formData.show_title
    });
    setErrorFormBooklet(response.validator);
    if (response.success === true) {
      const responsePutBookletGeneratePdf = await PutBooklet(response.data.id, {
        context: 'generatePdf'
      });
      if (responsePutBookletGeneratePdf.success === true) {
        toast('La plaquette a bien été créée');
        setParentTrigger(Date.now());
        closeModal();
        setIsSaving(false);
      } else {
        toast('Une erreur est survenue, veuillez réesayer plus tard');
      }
    } else {
      toast('Une erreur est survenue, veuillez réesayer plus tard');
    }
  };

  const closeModal = async () => {
    setShowCanvaBrochure(false);
    setFormData({
      prestation_id: prestation.id,
      cover_image_url: '',
      cover_title: prestation.name,
      price_display: 'including',
      show_title: '1'
    });
  };

  const fetchOneBooklet = async id => {
    const responseOneBooklet = await ShowBooklet(id);
    if (responseOneBooklet.success === true) {
      let response = responseOneBooklet.data;
      setFormData({
        prestation_id: prestation.id,
        cover_image_url: response.cover_image_url,
        cover_title: response.cover_title,
        price_display: response.price_display,
        show_title: response.show_title
      });
    }
  };

  return (
    <>
      {action === 'add' ? (
        <Button
          variant="falcon-default"
          size="sm"
          onClick={() => {
            setShowCanvaBrochure(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
              fill="rgba(94,110,130,1)"
            />
          </svg>{' '}
          Créer une plaquette
        </Button>
      ) : action === 'duplicate' ? (
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            setShowCanvaBrochure(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            style={{ marginBottom: '2px' }}
          >
            <path
              d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
              fill="#000000"
            ></path>
          </svg>{' '}
          Dupliquer la plaquette
        </Dropdown.Item>
      ) : (
        ''
      )}

      <Modal
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showCanvaBrochure}
        onShow={() => {
          if (action === 'duplicate' && selectedObject && selectedObject.id) {
            fetchOneBooklet(selectedObject.id);
          }
        }}
        onHide={() => closeModal()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Col xs={11}>
            <b>
              {isSaving
                ? 'Enregistrement de la plaquette, veuillez ne pas quitter la page...'
                : 'Plaquette'}
            </b>
          </Col>
          <FalconCloseButton className="me-3" onClick={() => closeModal()} />
        </Modal.Header>
        {isSaving ? (
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <div className="d-block mt-3">
                  <Spinner animation="border" role="status" size="xxs">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          <Fragment>
            <Modal.Body>
              <Row className="">
                <Col xs={12}>
                  {errorFormBooklet && (
                    <div className="mt-3">
                      <FormAlert variant="danger" data={errorFormBooklet} />
                    </div>
                  )}
                </Col>
                <Col md="12" className="">
                  <Form.Group controlId="vimeo_id">
                    <Form.Label>Titre de la plaquette</Form.Label>
                    <Form.Control
                      required={true}
                      type="text"
                      name="cover_title"
                      placeholder="Titre de la plaquette"
                      value={formData.cover_title || ''}
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cover_title: e.target.value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                  <Form.Group controlId="category_id">
                    <Form.Label>Affichage des prix :</Form.Label>
                    <Form.Select
                      aria-label="Année"
                      name="year"
                      value={formData.price_display || 'including'}
                      onChange={e => {
                        setFormData({
                          ...formData,
                          price_display: e.target.value
                        });
                      }}
                    >
                      <option value="including">TTC</option>
                      <option value="excluding">HT</option>
                      <option value="noprice">Ne pas afficher les prix</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mt-3">
                  <Form.Group controlId="category_id">
                    <Form.Label>
                      Afficher les informations de première page{' '}
                      <small>date de prestation, nombre de convives...</small> :
                    </Form.Label>
                    <Form.Select
                      aria-label="show_title"
                      name="show_title"
                      value={formData.show_title || '1'}
                      onChange={e => {
                        setFormData({
                          ...formData,
                          show_title: e.target.value
                        });
                      }}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mt-3">
                  <Form.Label>
                    Photo de couverture <small>(page de garde)</small> :
                  </Form.Label>
                  {selectedObject && selectedObject.id ? (
                    <FileUploader
                      pathToUpload="booklets/covers"
                      formData={formData}
                      setFormData={setFormData}
                      format="img"
                      databaseField="cover_image_url"
                      cropperEnabled={true}
                      currentImage={selectedObject.cover_image_url}
                    />
                  ) : (
                    <FileUploader
                      pathToUpload="booklets/covers"
                      formData={formData}
                      setFormData={setFormData}
                      format="img"
                      databaseField="cover_image_url"
                      cropperEnabled={true}
                    />
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="text-start">
              <Button
                size="sm"
                variant="primary"
                className="admin-primary m-lg-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                {action === 'add'
                  ? 'Créer la plaquette'
                  : 'Dupliquer la plaquette'}
              </Button>
            </Modal.Footer>
          </Fragment>
        )}
      </Modal>
    </>
  );
};

export default ManageBrochure;

ManageBrochure.propTypes = {
  setParentTrigger: PropTypes.func,
  prestation: PropTypes.object,
  selectedObject: PropTypes.object,
  action: PropTypes.string
};
