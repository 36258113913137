import React, { useEffect, useState } from 'react';
import {
  DeleteModelSection,
  IndexModelSection,
  PostPrestationSection
} from '../../../../../services/PrestationSection';
import {
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip,
  Button
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const ManageSectionModel = ({ prestation, formData, setFormData }) => {
  const [showManage, setShowManage] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [models, setModels] = useState([]);

  useEffect(() => {
    const fetchModels = async () => {
      const response = await IndexModelSection();
      if (response.success === true) {
        setModels(response.data);
      }
    };
    fetchModels();
  }, [showManage, trigger]);

  const deleteTemplate = async template => {
    const response = await DeleteModelSection(template.id);
    if (response.success === true) {
      toast('Modèle supprimé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        show={showManage}
        onHide={() => {
          setShowManage(true);
        }}
        placement={'end'}
        style={{ width: '900px' }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Importer un modèle de section</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table responsive className="table-stats-tracks">
            <thead>
              <tr>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th scope="col">Création</th>
                <th scope="col">Dernière modif.</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {models.map((template, index) => (
                <tr key={index}>
                  <td>{template.name}</td>
                  <td>{template.description}</td>
                  <td>
                    {moment(template.created_at).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td>
                    {moment(template.updated_at).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td>
                    <a
                      onClick={async () => {
                        const response = await PostPrestationSection({
                          name: template.name,
                          description: template.description,
                          image_url: template.image_url,
                          prestation_id: prestation.id
                        });
                        if (response.success === true) {
                          setFormData({
                            ...formData,
                            sections: [
                              ...formData.sections,
                              {
                                id: response.data.id,
                                name: response.data.name,
                                description: response.data.description,
                                image_url: response.data.image_url
                              }
                            ]
                          });
                          toast('Modèle importé');
                        }
                      }}
                      className="cursor-pointer"
                    >
                      Choisir
                    </a>
                  </td>
                  <td>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Supprimer le modèle
                        </Tooltip>
                      }
                    >
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          deleteTemplate(template);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '1px',
                            marginLeft: '3px'
                          }}
                        >
                          <path
                            d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                            fill="#e63757"
                          />
                        </svg>
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowManage(false);
            }}
          >
            Fermer
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Button
        size="sm"
        className="ms-2"
        variant="falcon-default"
        onClick={() => {
          setShowManage(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM4 18.3851L5.76282 17H20V5H4V18.3851ZM13 11H16L12 15L8 11H11V7H13V11Z"
            fill="rgba(94,110,130,1)"
          />
        </svg>{' '}
        Importer un modèle
      </Button>
    </>
  );
};

ManageSectionModel.propTypes = {
  prestation: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};

export default ManageSectionModel;
