import React from 'react';
import { Button, Row, Card, Col, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PrintComponentLogisticSheet from './PrintPdfLogisticSheet';
import SoftBadge from '../../common/SoftBadge';

const LogisticSheet = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <Col>
              <h3 className="mb-0">Fiche de prestation M. Smith</h3>
            </Col>

            <Col className={'d-flex justify-content-end'}>
              <Button
                variant="light"
                size="sm"
                className={'mx-3'}
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                    fill="rgba(93,110,130,1)"
                  />
                </svg>
                Retour
              </Button>
              {/*              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                as={Link}
                to="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 12h3l-4 5-4-5h3V8h2v4z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Exporter la fiche (PDF)
              </Button>*/}
              <PrintComponentLogisticSheet />
            </Col>
          </Row>
        </Card.Header>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Col lg={12}>
            <Table borderless responsive>
              <thead>
                <tr>
                  <th scope="col" style={{ width: '80%' }}>
                    Vaiselle
                  </th>
                  <th scope="col" style={{ width: '150px' }}>
                    Pièces
                  </th>
                  <th scope="col" style={{ width: '150px' }}>
                    Check
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Fourchette
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      <strong>220</strong>
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Couteau
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      200
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Verre à eau
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      200
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Non
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Verre à vin
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      300
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={12}>
            <Table borderless responsive className={'mb-3'}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: '80%' }}>
                    Boisson
                  </th>
                  <th scope="col" style={{ width: '150px' }}>
                    Litres
                  </th>
                  <th scope="col" style={{ width: '150px' }}>
                    Check
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Coca
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      200
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Bière
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      300
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Crément
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      <strong>15</strong>
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Non
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Jus d’orange
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      20
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Oui
                    </SoftBadge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={12}>
            <Card.Title
              as={'h5'}
              style={{ padding: '12px 12px 12px 20px', margin: '0px' }}
            >
              Liste des maitres d’hotel reliés à la prestation
            </Card.Title>
            <Table borderless responsive className={'mb-3'}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: '90%' }}>
                    Prénom et Nom
                  </th>
                  <th scope="col">Heures</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Marc Dupont
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      200
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      Jean Dupont
                    </SoftBadge>
                  </td>
                  <td>
                    <SoftBadge
                      bg="info"
                      className="me-2 w-100 text-justify p-3"
                    >
                      300
                    </SoftBadge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default LogisticSheet;
