import React from 'react';
import Manage from './Manage';

const IndexMediaLibrary = () => {
  return (
    <>
      <Manage />
    </>
  );
};

export default IndexMediaLibrary;
