import React, { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import * as Moment from 'moment';
import {
  IndexSeasons,
  PostSeasons,
  PutSeasons,
  ShowSeason
} from '../../../../services/ProductSeasonsService';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const ManageProductSeasons = ({
  showManageSeasons,
  setShowManageSeasons,
  selectedSeason,
  formParentProduct,
  setTrigger,
  setFormParentProduct
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    start: '',
    end: '',
    recurrent: 0
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    let tmpFormData = { ...formData };
    tmpFormData.start = Moment(formData.start).local().format('YYYY-MM-DD');
    tmpFormData.end = Moment(formData.end).local().format('YYYY-MM-DD');

    if (selectedSeason && selectedSeason.id) {
      response = await PutSeasons(selectedSeason.id, tmpFormData);
    } else {
      response = await PostSeasons(tmpFormData);
    }

    if (response.success === true) {
      toast(`Saison enregistrée`);
      setIsLoadingSubmit(true);
      const responseSeasons = await IndexSeasons();
      if (responseSeasons.success === true) {
        let seasonsArray = [];
        responseSeasons.data.map(season => {
          seasonsArray.push({
            value: season.id,
            label: season.name
          });
        });
      }

      if (formParentProduct) {
        setFormParentProduct({
          ...formParentProduct,
          seasons: [
            ...formParentProduct.seasons,
            { value: response.data.id, label: response.data.name }
          ]
        });
        setShowManageSeasons(false);
        return;
      }

      if (!formParentProduct) {
        setTrigger(Date.now());
      }
      setShowManageSeasons(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  useEffect(() => {
    if (selectedSeason && selectedSeason.id) {
      setFormData({
        ...formData,
        name: selectedSeason.name
      });
    }
    const fetchSeason = async () => {
      const responseSeason = await ShowSeason(selectedSeason.id);
      if (responseSeason.success === true) {
        setFormData({
          name: responseSeason.data.name,
          start: new Date(responseSeason.data.start),
          end: new Date(responseSeason.data.end),
          recurrent: responseSeason.data.recurrent
        });
      }
    };

    if (selectedSeason && selectedSeason.id) {
      fetchSeason();
    }
  }, []);

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder="Choisir une date"
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formData.start && formData.end && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setFormData({
              ...formData,
              start: '',
              end: ''
            });
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };
  return (
    <>
      <Offcanvas
        show={showManageSeasons}
        onHide={() => {
          setShowManageSeasons(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowManageSeasons(false);
          }}
        >
          <Offcanvas.Title>
            {selectedSeason && selectedSeason.id
              ? 'Modifier la saison'
              : `Ajouter une saison`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Nom de la saison :</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Noël, Pâques, Halloween ... "
                  value={formData.name}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Col>
                <Form.Label className="mt-3">
                  Sélectionner les dates concernées
                </Form.Label>
                <DatePicker
                  className="form-control"
                  onChange={dates => {
                    const [start, end] = dates;
                    setFormData({
                      ...formData,
                      start: start,
                      end: end
                    });
                  }}
                  startDate={formData.start}
                  endDate={formData.end}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  customInput={<DatePickerInput />}
                  locale="fr"
                />
              </Col>
            </Col>
            <Col className="">
              <label>
                <input
                  type="checkbox"
                  id="recurrent"
                  name="recurrent"
                  value="1"
                  className="me-1"
                  checked={
                    formData.recurrent === 1 || formData.recurrent === '1'
                  }
                  onChange={e => {
                    setFormData({
                      ...formData,
                      recurrent: e.target.checked ? 1 : 0
                    });
                  }}
                />
                <small>
                  Saison récurrente ?{' '}
                  <small>sera renouvelée tous les ans</small>
                </small>
              </label>
            </Col>
            <Col lg={12}>
              <Button
                disabled={isLoadingSubmit}
                className="mt-3"
                onClick={e => {
                  handleSubmit(e);
                }}
                variant="primary"
              >
                {selectedSeason && selectedSeason.id
                  ? 'Enregistrer les modifications'
                  : 'Enregistrer la saison'}
              </Button>
            </Col>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageProductSeasons.propTypes = {
  selectedSeasons: PropTypes.object,
  selectedSeason: PropTypes.object,
  setShowManageSeasons: PropTypes.func,
  showManageSeasons: PropTypes.bool,
  formParentProduct: PropTypes.object,
  setFormParentProduct: PropTypes.func,
  setTrigger: PropTypes.func
};

export default ManageProductSeasons;
