import React, { Fragment, useEffect } from 'react';
import { Col, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SoftBadge from '../../../common/SoftBadge';
import DatePicker from 'react-datepicker';

const TableButtlers = ({ butlersFromPresta, setFormButlers, formButlers }) => {
  useEffect(() => {
    setFormButlers(butlersFromPresta);
  }, [butlersFromPresta]);

  return (
    <>
      {' '}
      {butlersFromPresta && butlersFromPresta.length > 0 && (
        <Fragment>
          <Col xs={12} className="mt-3">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  {/*<th scope="col">Mission</th>*/}
                  <th scope="col">Horaire</th>
                  {/*<th scope="col">Heures effectives</th>*/}
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {formButlers.map((butler, index) => (
                  <tr key={index}>
                    <td>
                      {butler.firstname} {butler.lastname}
                    </td>
                    {/*<td>
                      <Form.Control
                        type="text"
                        name="mission"
                        placeholder="Maître d'hôtel"
                        value={butler.mission || ''}
                        onChange={e => {
                          let tmp = [...formButlers];
                          tmp[index].mission = e.target.value;
                          setFormButlers(tmp);
                        }}
                      />
                    </td>*/}
                    <td>
                      <DatePicker
                        className="form-control"
                        placeholderText="Choisir une date et une heure"
                        timeCaption="Heure"
                        selected={butler.start_at}
                        dateFormat="dd/MM/yyyy HH:mm"
                        locale="fr"
                        onChange={date => {
                          let tmp = [...formButlers];
                          tmp[index].start_at = date;
                          setFormButlers(tmp);
                        }}
                        showTimeSelect
                        timeIntervals={30}
                        value={butler.start_at}
                      />
                    </td>
                    {/*<td>
                      <Form.Control
                        type="number"
                        name="worked_hours"
                        placeholder="4"
                        value={butler.worked_hours || ''}
                        onChange={e => {
                          let tmp = [...formButlers];
                          tmp[index].worked_hours = e.target.value;
                          setFormButlers(tmp);
                        }}
                      />
                    </td>*/}
                    <td>
                      <SoftBadge bg="info" className="me-2">
                        <a href={`mailto:${butler.email}`}>{butler.email}</a>
                      </SoftBadge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Fragment>
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func
};
TableButtlers.propTypes = {
  butlersFromPresta: PropTypes.array,
  trigger: PropTypes.func,
  setFormButlers: PropTypes.func,
  formButlers: PropTypes.array
};

export default TableButtlers;
