import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { IndexCustomer } from '../../../services/CustomerService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { PostPrestation } from '../../../services/PrestationService';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import moment from 'moment/moment';
import hours from '../commons/data/hours';
import FormAlert from '../commons/FormAlert';
import ManageContact from '../customers/ManageContact';
import { IndexPrestationsType } from '../../../services/PrestationTypeService';
import ManageType from './type/ManageType';
const CreateService = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [customers, setCustomers] = useState([]); // Liste tout les client d'avs
  const [labelType, setLabelType] = useState([]); // Stock tout les type de prestation avec leurs labels
  const [shearchInput, setSearchInput] = useState([]);
  const [showModifyContact, setShowModifyContact] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [selectedCustomer /*, setSelectedCustomer*/] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showManageType, setShowManageType] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    address_details: '',
    latitude: '',
    longitude: '',
    city: '',
    category_id: '',
    type_id: '',
    type: '',
    date: '',
    time: '14:00',
    time_team: '09:00',
    time_team_end: '18:00',
    guest_count: '',
    contacts: '',
    customer_id: '',
    contact_name: '',
    contact_phone: ''
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      const responseCustomers = await IndexCustomer(true);
      if (responseCustomers.success === true) {
        let tmpCustomer = [];
        responseCustomers.data.map(customer => {
          tmpCustomer.push({
            value: customer.id,
            label: customer.name
          });
        });
        setSearchInput(tmpCustomer);
        setCustomers(responseCustomers.data);
        setIsLoading(false);
      }
    };

    const fetchType = async () => {
      const responseTypes = await IndexPrestationsType();
      if (responseTypes.success === true) {
        let tmpTypes = [];
        responseTypes.data.map(type => {
          tmpTypes.push({
            value: type.id,
            label: (
              <>
                <span
                  style={{
                    backgroundColor: type.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                  className="rounded-circle d-inline-block me-2"
                ></span>
                {type.name}
              </>
            )
            /* label: type.name*/
          });
        });
        setLabelType(tmpTypes);
      }
    };
    fetchType();
    fetchCustomers();
    transforApiObjectsCustomers(customers);
  }, [trigger]);
  const handleSubmit = async e => {
    e.preventDefault();
    let tmpFormData = { ...formData };
    tmpFormData.customer_id = formData.customer.value;
    tmpFormData.type = formData.type.value;
    tmpFormData.type_id = formData.type.value;
    tmpFormData.date = Moment(tmpFormData.date).local().format('YYYY-MM-DD');
    tmpFormData.time = moment(tmpFormData.time, 'HH:mm').format('HH:mm:ss');
    tmpFormData.time_team = moment(tmpFormData.time_team, 'HH:mm').format(
      'HH:mm:ss'
    );
    tmpFormData.time_team_end = moment(
      tmpFormData.time_team_end,
      'HH:mm'
    ).format('HH:mm:ss');
    const postService = await PostPrestation(tmpFormData);
    if (postService.success === true) {
      setIsLoadingSubmit(true);
      toast('Prestation enregistrée');
      setTimeout(function () {
        window.location.href = `/prestation/${postService.data.id}`;
      }, 1500);
    } else {
      toast('Une erreur est survenue');
    }
    setErrorForm(postService.validator);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const transforApiObjectsCustomers = customers => {
    let transfomedApiObjectCustomers = [];
    customers.map(customer => {
      transfomedApiObjectCustomers.push({
        name: customer.name,
        address: customer.address,
        zip: customer.zip,
        city: customer.city,
        sage_id: customer.sage_id,
        additional_address: customer.additional_address
      });
    });
  };
  return (
    <>
      {showManageType && (
        <ManageType
          showManageType={showManageType}
          setShowManageType={setShowManageType}
          selectedType={selectedType}
          setTrigger={setTrigger}
        />
      )}
      {showModifyContact && (
        <ManageContact
          showModifyContact={showModifyContact}
          setShowModifyContact={setShowModifyContact}
          selectedCustomer={selectedCustomer}
        />
      )}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="mb-0">Création d'une prestation</h3>
        </Card.Body>
      </Card>

      {isLoading ? (
        <div className="d-block mt-3">
          <Spinner animation="border" role="status" size="xxs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Card className="mt-4">
            <Card.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              <Row>
                <Col md={12}>
                  <Form.Group controlId="search">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                          fill="rgba(93,110,130,1)"
                        />
                      </svg>
                      Sélectionner un client
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      options={shearchInput}
                      placeholder="Nom, adresse, ville..."
                      name="search"
                      classNamePrefix="react-select"
                      value={formData.customer || ''}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          customer: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3" md={12}>
                {/* ------- Nom de la prestation -------- */}
                <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Nom du contact </Form.Label>
                    <Form.Control
                      placeholder="Nom du contact"
                      name="contact_name"
                      value={formData.contact_name}
                      onChange={handleFieldChange}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Numéro de téléphone du contact </Form.Label>
                    <Form.Control
                      placeholder="Numéro de téléphone du contact"
                      name="contact_phone"
                      value={formData.contact_phone}
                      onChange={handleFieldChange}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3" md={12}>
                {/* ------- Nom de la prestation -------- */}
                <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path
                          d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 6V5h-3v2h-2V5H9v2H7V5H4v4h16zm0 2H4v8h16v-8zM6 13h5v4H6v-4z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>{' '}
                      Nom de la prestation
                    </Form.Label>
                    <Form.Control
                      placeholder="Écrire le nom de la prestation"
                      name="name"
                      value={formData.name}
                      onChange={handleFieldChange}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>

                {/* ------ type de prestation -------*/}
                <Col md={6}>
                  <Form.Group controlId="type">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path
                          d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828 12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>{' '}
                      Type de prestation{' '}
                      <a
                        className="cursor-pointer ms-2"
                        onClick={() => {
                          setShowManageType(true);
                          setSelectedType({});
                        }}
                      >
                        <small>+ Ajouter</small>
                      </a>{' '}
                    </Form.Label>
                    <Select
                      name="type"
                      closeMenuOnSelect={true}
                      options={labelType}
                      placeholder="Choisir un type..."
                      classNamePrefix="react-select"
                      value={formData.type}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          type: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className={'mt-3'}>
                  <Form.Group controlId="address">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      {/*---------- Lieu de la prestation ---------   */}
                      Lieu de la prestation{' '}
                    </Form.Label>
                    <GooglePlacesAutocomplete
                      apiOptions={{
                        language: 'fr',
                        region: 'fr'
                      }}
                      selectProps={{
                        noOptionsMessage: () =>
                          'Tapez un lieu dans la barre de recherche',
                        loadingMessage: () => 'Chargement en cours...',
                        placeholder: 'Commencez à chercher...',
                        onChange: object =>
                          geocodeByPlaceId(object.value.place_id)
                            .then(results =>
                              setFormData({
                                ...formData,
                                address: results[0].formatted_address,
                                latitude: results[0].geometry.location.lat(),
                                longitude: results[0].geometry.location.lng()
                              })
                            )
                            .catch(error => console.error(error))
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3" md={12}>
                {/* ------- Nom de la prestation -------- */}
                <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        style={{ marginRight: '4px', marginBottom: '2px' }}
                      >
                        <path
                          d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Complément d'adresse
                    </Form.Label>
                    <Form.Control
                      placeholder="Compléter l'adresse..."
                      name="address_details"
                      value={formData.address_details}
                      onChange={handleFieldChange}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              {/*--------   Séléctionner la date   ----------*/}

              <Row className="mt-3">
                <Col md={3}>
                  {' '}
                  <Form.Group controlId="date">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Date de la prestation
                    </Form.Label>
                    <DatePicker
                      showYearDropdown
                      name="date"
                      dateFormat="dd/MM/yyyy"
                      locale="fr"
                      selected={formData.date}
                      onChange={date => {
                        setFormData({
                          ...formData,
                          date: date
                        });
                      }}
                      className="form-control"
                      placeholderText="Date de début"
                      value={formData.date}
                    />
                  </Form.Group>
                </Col>
                {/*-------- Séléctionner l'horaire de la presta ----------*/}
                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Horaire de la prestation
                    </Form.Label>
                    <Form.Select
                      name="time"
                      onChange={handleFieldChange}
                      value={formData.time}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Heure d'arrivée AVS
                    </Form.Label>
                    <Form.Select
                      name="time_team"
                      onChange={handleFieldChange}
                      value={formData.time_team}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  {' '}
                  <Form.Group controlId="time">
                    <Form.Label>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>{' '}
                      Heure de départ AVS
                    </Form.Label>
                    <Form.Select
                      name="time_team_end"
                      onChange={handleFieldChange}
                      value={formData.time_team_end}
                    >
                      {hours.map((hour, index) => (
                        <option key={index} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/*-------- Séléctionner nombres de convives  ----------*/}
                <Col md={3}>
                  <Form.Group controlId="guest_count">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>
                      Nombre de convives
                    </Form.Label>
                    <Form.Control
                      name="guest_count"
                      value={formData.guest_count}
                      onChange={handleFieldChange}
                      type="number"
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md="12" className="pb-3 pt-3">
                  <div className="border-dashed-bottom"></div>
                </Col>

                {/*------ Contacts ----------*/}
                {/*<Col md={12}>
                  <Form.Group controlId="contacts">
                    <Form.Label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                          fill="rgba(94,110,130,1)"
                        />
                      </svg>
                      Séléctionner des contacts{' '}
                      <a
                        onClick={() => setShowModifyContact(true)}
                        className="cursor-pointer ms-2"
                      >
                        <small>+ Ajouter</small>
                      </a>
                    </Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={true}
                      options={contactsLabel}
                      placeholder="Rechercher"
                      name="contacts"
                      classNamePrefix="react-select"
                      value={formData.contacts || ''}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          contacts: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>*/}
              </Row>
            </Card.Body>
          </Card>
          <Button
            variant="primary"
            className="admin-primary mt-3"
            size="sm"
            onClick={handleSubmit}
            disabled={isLoadingSubmit}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
            Enregistrer la prestation et passer à l'étape suivante
          </Button>
        </>
      )}
    </>
  );
};

export default CreateService;
