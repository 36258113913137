import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormAlert from '../commons/FormAlert';
import {
  DeleteUser,
  PostUser,
  PutUser,
  ShowUser
} from '../../../services/UserService';
import PropTypes from 'prop-types';
import FileUploader from './fileUpload/FileUploader';
import { IndexRole } from '../../../services/RoleUserService';
import Select from 'react-select';

const ManageUsers = ({
  setShowManageUsers,
  showManageUsers,
  selectedObject,
  setSelectedObject,
  setParentTrigger
}) => {
  const [trigger, setTrigger] = useState(Date.now()); // Surveille et mets à jour le composant
  const [user, setUser] = useState({}); // Stock tout les utilisateurs
  const [roles, setRoles] = useState([]); // Stocke tout les roles
  const [selectedRole, setSelectedRole] = useState([]); // Contient le role séléctionné d'un utilisateur
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false); // ouvre et ferme le canva pour le delete
  const [errorForm, setErrorForm] = useState(false);

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    profile_picture_url: '',
    role_id: '',
    default_mission: '',
    is_master_butler: 0
  });
  useEffect(() => {
    const fetchRoles = async () => {
      const responseRole = await IndexRole();
      if (responseRole.success === true) {
        let tmpRole = [];
        responseRole.data.map(role => {
          tmpRole.push({
            value: role.id,
            label: role.name
          });
        });
        setRoles(tmpRole);
      } else {
        toast('Une erreur est survennu, veuillez réessayer plus tard');
      }
    };

    const fetchUser = async () => {
      const responseUser = await ShowUser(selectedObject.id);
      if (responseUser.success === true) {
        setUser(responseUser.data);
        let dataUser = responseUser.data;
        setFormData({
          firstname: dataUser.firstname,
          lastname: dataUser.lastname,
          email: dataUser.email,
          profile_picture_url: dataUser.profile_picture_url,
          role_id: dataUser.role.id,
          default_mission: dataUser.default_mission,
          is_master_butler: dataUser.is_master_butler
        });
        setSelectedRole({
          value: dataUser.role.id,
          label: dataUser.role.name
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchRoles();
    if (selectedObject && selectedObject.id) {
      fetchUser();
    }
  }, [trigger]);

  const deleteUser = async () => {
    const responseDeleteUser = await DeleteUser(
      selectedObject && selectedObject.id
    );
    if (responseDeleteUser.success === true) {
      toast('Utilisateur supprimé');
      setShowDelete(false);
      setShowManageUsers(false);
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(responseDeleteUser.validator);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (selectedObject && selectedObject.id) {
      response = await PutUser(selectedObject.id, formData);
    } else {
      response = await PostUser(formData);
    }
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      // met à jour le local storage si changement d'image du profil
      const user = JSON.parse(localStorage.getItem('user_avs'));
      const userProfilPic = formData.profile_picture_url;
      const newUserProfilPic = {
        ...user,
        profile_picture_url: userProfilPic
      };
      localStorage.setItem('user_avs', JSON.stringify(newUserProfilPic));
      setParentTrigger(Date.now());
      setShowManageUsers(false);
      setTrigger(Date.now());
      toast(`Utilisateur enregistré.`);
      setTimeout(function () {
        window.location.href = `utilisateurs`;
      }, 300);
    }
  };

  return (
    <>
      {showManageUsers && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p>
                Supprimer l'utilisateur {user.lastname} {user.firstname} ?
              </p>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteUser();
                }}
              >
                Oui, supprimer l'utilisateur
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>

          {/*--- Canva Manage users -----*/}
          <Offcanvas
            className="w-50"
            show={showManageUsers}
            onHide={() => {
              setShowManageUsers(false);
              setSelectedObject({});
            }}
            placement="end"
          >
            <Offcanvas.Header
              closeButton={() => {
                setShowManageUsers(false);
                setSelectedObject({});
              }}
            >
              <Offcanvas.Title>
                <h3 className="text-primary admin-title mb-0">
                  {selectedObject && selectedObject.id
                    ? 'Modifier les informations'
                    : `Ajouter un utilisateur`}
                </h3>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    {errorForm && (
                      <div className="mt-3">
                        <FormAlert variant="danger" data={errorForm} />
                      </div>
                    )}
                  </Col>
                  <Col lg={12}>
                    <Row>
                      {/* <Form.Group>
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{
                              marginRight: '4px',
                              marginBottom: '2px'
                            }}
                          >
                            <path
                              d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Photo de profil :
                        </Form.Label>
                        {id && user.id ? (
                          <FileUploader
                            pathToUpload="users/profilepics"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="profile_picture_url"
                            cropperEnabled={true}
                            currentImage={user.profile_picture_url}
                          />
                        ) : (
                          <FileUploader
                            pathToUpload="users/profilepics"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="profile_picture_url"
                            cropperEnabled={true}
                          />
                        )}
                      </Form.Group>*/}

                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Label>Prénom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Prénom du membre"
                            value={formData.firstname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Label>Nom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Nom du membre"
                            value={formData.lastname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="mt-3" md="6">
                        <Form.Group controlId="email">
                          <Form.Label>Email :</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Adresse email"
                            value={formData.email || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="mt-3" md="6">
                        <Form.Group controlId="role_id">
                          <Form.Label>
                            Rôle <small>(autorisations)</small> :
                          </Form.Label>
                          <Select
                            closeMenuOnSelect={true}
                            options={roles}
                            placeholder="Choisir un rôle..."
                            name="role_id"
                            classNamePrefix="react-select"
                            value={selectedRole}
                            onChange={object => {
                              setFormData({
                                ...formData,
                                role_id: object.value
                              });
                              setTimeout(function () {}, 300);
                              setSelectedRole(object);
                            }}
                          >
                            <option>Admin</option>
                            <option value={1}>Cuisine</option>
                          </Select>
                        </Form.Group>
                      </Col>
                      {formData.role_id === 2 && (
                        <Col className="mt-3" xs={12}>
                          <Form.Group controlId="lastname">
                            <Form.Label>
                              Mission par défaut lors des prestations{' '}
                              <small>facultatif</small> :
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="default_mission"
                              placeholder="Maître d'hôtel"
                              value={formData.default_mission || ''}
                              onChange={handleFieldChange}
                            />
                          </Form.Group>
                        </Col>
                      )}
                      {formData.role_id === 2 && (
                        <Col className="mt-3" xs={12}>
                          <Form.Check
                            className="ms-0"
                            type="checkbox"
                            id="defaultCheckbox"
                            onChange={e => {
                              if (e.target.checked) {
                                setFormData({
                                  ...formData,
                                  is_master_butler: 1
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  is_master_butler: 0
                                });
                              }
                            }}
                            defaultChecked={formData.is_master_butler === 1}
                            label={
                              <>
                                Est un maître d'Hôtel responsable ? (accès à la
                                gestion des resources logistiques et génération
                                des fiches logistiques{' '}
                              </>
                            }
                          />
                        </Col>
                      )}

                      <Form.Group className="mt-3">
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{
                              marginRight: '4px',
                              marginBottom: '2px'
                            }}
                          >
                            <path
                              d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Photo de profil :
                        </Form.Label>
                        {selectedObject && selectedObject.id && user.id ? (
                          <FileUploader
                            pathToUpload="users/profilepics"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="profile_picture_url"
                            cropperEnabled={true}
                            currentImage={user.profile_picture_url}
                          />
                        ) : (
                          <FileUploader
                            pathToUpload="users/profilepics"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="profile_picture_url"
                            cropperEnabled={true}
                          />
                        )}
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="flex-between-center mt-3">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{
                              marginBottom: '2px',
                              marginRight: '4px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                        {selectedObject && selectedObject.id && (
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0 float-end"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px',
                                marginRight: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Supprimer
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

ManageUsers.propTypes = {
  context: PropTypes.string,
  urlElement: PropTypes.string,
  role_id: PropTypes.number,
  setShowManageUsers: PropTypes.func,
  showManageUsers: PropTypes.bool,
  selectedObject: PropTypes.object || PropTypes.bool,
  setSelectedObject: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default ManageUsers;
