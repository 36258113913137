import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import DatePicker from 'react-datepicker';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Analytics } from '../../../services/PrestationService';

const Comptabilite = () => {
  const [initialApiLogs, setInitialApiLogs] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [dates, setdates] = useState({
    start: Moment().subtract(1, 'months').toDate(),
    end: Moment().toDate()
  });
  useEffect(() => {
    const fetchLogs = async () => {
      let start = Moment().format('YYYY-MM-DD');
      let end = Moment().format('YYYY-MM-DD');
      if (dates.start) {
        start = Moment(dates.start).format('YYYY-MM-DD');
        if (!dates.end) {
          end = Moment(dates.start).format('YYYY-MM-DD');
        }
      }
      if (dates.end) {
        end = Moment(dates.end).format('YYYY-MM-DD');
      }
      const responseLogs = await Analytics(start, end);
      if (responseLogs.success === true) {
        setInitialApiLogs(responseLogs.data);
        console.log(responseLogs.data);
      }
    };
    fetchLogs();
  }, [trigger]);
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-0">
      <Form.Control
        color="text-primary"
        placeholder="Choisir une date"
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {dates.start && dates.end && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setdates({
              ...dates,
              start: '',
              end: ''
            });
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row className="">
            <Col lg={6}>
              <h3 className="mb-0">Analytic - Comptabilité</h3>
            </Col>
          </Row>
          <Row>
            <Form>
              <Col xs={12} className="mt-3">
                <Form.Label className="mt-3">
                  Sélectionner les dates concernées
                </Form.Label>
                <Row className="align-items-center">
                  <Col xs={9}>
                    <DatePicker
                      className="form-control"
                      onChange={dates => {
                        const [start, end] = dates;
                        setdates({
                          ...dates,
                          start: start,
                          end: end
                        });
                      }}
                      startDate={dates.start}
                      endDate={dates.end}
                      selectsRange
                      dateFormat="dd/MM/yy"
                      customInput={<DatePickerInput />}
                      locale="fr"
                    />
                  </Col>
                  <Col xs={3} className="ps-0">
                    <Button
                      className="me-2"
                      variant="primary"
                      size="md"
                      icon={faFileExcel}
                      transform="shrink-3"
                      onClick={() => {
                        setTrigger(Date.now());
                      }}
                    >
                      Appliquer
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </Card.Header>
      </Card>
      {initialApiLogs.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Row>
              <Col xs={12}>
                Total devis :{' '}
                <b>
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(
                    Math.round(
                      initialApiLogs.reduce((a, b) => {
                        if (b.last_quote) {
                          let amount = parseFloat(b.last_quote.amount_ht);
                          return a + amount;
                        } else {
                          return a;
                        }
                      }, 0) * 100
                    ) / 100
                  )}
                </b>
                {' - '}
                Total factures :{' '}
                <b>
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(
                    Math.round(
                      initialApiLogs.reduce((a, b) => {
                        if (b.last_billing_sheet) {
                          let amount = parseFloat(
                            b.last_billing_sheet.amount_ht
                          );
                          return a + amount;
                        } else {
                          return a;
                        }
                      }, 0) * 100
                    ) / 100
                  )}
                </b>
              </Col>
              <Col xs={12} className="mt-4">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Prestation</th>
                      <th>Devis €HT</th>
                      <th>Facture €HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialApiLogs.map((log, index) => (
                      <tr key={index}>
                        <td>{Moment(log.date).format('DD/MM/YYYY')}</td>
                        <td>
                          <a
                            target="_blank"
                            href={`/prestation/${log.id}`}
                            rel="noreferrer"
                          >
                            {log.name}
                          </a>
                        </td>
                        <td>
                          {log.last_quote && (
                            <>
                              {new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: 'EUR'
                              }).format(
                                Math.round(
                                  parseFloat(log.last_quote.amount_ht) * 100
                                ) / 100
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {log.last_billing_sheet && (
                            <>
                              {new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: 'EUR'
                              }).format(
                                Math.round(
                                  parseFloat(log.last_billing_sheet.amount_ht) *
                                    100
                                ) / 100
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Comptabilite;
