import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'contacts';

export async function IndexContacts() {
  return modelService.IndexModel(`${model}`);
}
export async function PutContact(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostContact(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteContact(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
