import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import formatter from '../helper/formatNumber';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import { IndexResourceCategories } from '../../../services/ResourceCategoriesService';

const TableBackResources = ({
  initialApiResources,
  setShowResource,
  setSelectedObject
}) => {
  const [initialObjects, setInitialObjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [resources, setResources] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    search: '',
    categories: []
  });
  useEffect(() => {
    const fetchProductCategories = async () => {
      const responseCategories = await IndexResourceCategories();
      if (responseCategories.success === true) {
        let tmpCategories = [];
        responseCategories.data.map(c => {
          tmpCategories.push({
            value: c.name,
            label: c.name
          });
          setCategories(tmpCategories);
        });
      }
    };
    fetchProductCategories();
    transformedDataApiResource(initialApiResources);
  }, [initialApiResources]);

  const transformedDataApiResource = resources => {
    let transformedApi = [];
    resources.map(obj => {
      transformedApi.push({
        service_sheet_name: obj.service_sheet_name,
        invoice_name: obj.invoice_name,
        default_unit: (
          <span className="text-capitalize">{obj.default_unit}</span>
        ),
        default_price: formatter.format(obj.default_price),
        default_vat: Math.round(obj.default_vat * 100) / 100 + '%',
        reference: obj.reference,
        categoryArray: obj.category,
        categories:
          obj.category && obj.category.name
            ? obj.category.name
            : 'Aucune catégorie',
        action: (
          <>
            <Button
              size="sm"
              variant="primary"
              className="me-2 admin-primary"
              type="button"
              onClick={() => {
                setSelectedObject(obj);
                setShowResource(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px'
                }}
              >
                <path
                  d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
          </>
        )
      });
    });
    setResources(transformedApi);
    setInitialObjects(transformedApi);
  };

  const updateSearch = data => {
    if (data.search.length === 0 && data.categories.length === 0) {
      setResources(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.service_sheet_name &&
              obj.service_sheet_name.toLowerCase().includes(tmpSearch)) ||
            (obj.invoice_name &&
              obj.invoice_name.toLowerCase().includes(tmpSearch)) ||
            (obj.price && obj.price.toLowerCase().includes(tmpSearch)) ||
            (obj.reference &&
              obj.reference.toLowerCase().includes(tmpSearch)) ||
            (obj.categories && obj.categories.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }

      if (data.categories.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.categories.map(category => {
            if (obj.categoryArray) {
              let tmpCategory = [];
              tmpCategory.push(obj.categoryArray);
              if (tmpCategory && tmpCategory.length > 0) {
                tmpCategory.map(c => {
                  if (c.name === category.value) {
                    result = true;
                  }
                });
              }
            }
          });
          return result;
        });
      }
      setResources(tmpFilterObjects);
    }
  };

  return (
    <>
      {initialApiResources.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Row className="align-items-end">
              <Col xs={3} className="mb-3">
                <Form.Label>Rechercher</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Dénomination, prix, référence, catégories ..."
                  value={formData.search || ''}
                  onChange={event => {
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              {/* ------- Filter par catégories -------- */}
              <Col xs={3} className="mb-3">
                <Form.Label>Catégorie(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={categories}
                  placeholder="Choisir..."
                  isMulti
                  name="categories"
                  classNamePrefix="react-select"
                  value={formData.categories}
                  onChange={value => {
                    setFormData(
                      {
                        ...formData,
                        categories: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Fragment>
                <Col xs={12} className="mt-3">
                  <Table data={resources} />
                </Col>
              </Fragment>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'invoice_name',
        Header: 'Devis'
      },
      {
        accessor: 'service_sheet_name',
        Header: 'Cuisine'
      },
      {
        accessor: 'default_vat',
        Header: 'TVA'
      },
      {
        accessor: 'default_price',
        Header: 'Prix'
      },
      {
        accessor: 'reference',
        Header: 'Référence'
      },
      {
        accessor: 'categories',
        Header: 'Catégorie'
      },
      {
        accessor: 'action',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 15 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      {' '}
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
        {/*<ExportBackUsers users={data} />*/}
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

TableBackResources.propTypes = {
  initialApiResources: PropTypes.array,
  setSelectedObject: PropTypes.func,
  setShowResource: PropTypes.func
};

export default TableBackResources;
