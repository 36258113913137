import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'products/ingredients';

export async function IndexIngredient() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowIngredient(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutIngredient(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostIngredient(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteIngredient(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
