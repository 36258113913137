import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

const OffCanvaFileDelete = ({
  showCanvaFileDelete,
  setShowCanvaFileDelete,
  handleDeleteFile,
  selectedObject
}) => {
  return (
    <Offcanvas
      show={showCanvaFileDelete}
      onHide={() => {
        setShowCanvaFileDelete(false);
      }}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Button
          size="sm"
          variant="falcon-danger"
          className="me-2"
          onClick={() => {
            handleDeleteFile(selectedObject);
          }}
        >
          Oui, supprimer le fichier {selectedObject.name}
        </Button>
        <Button
          size="sm"
          variant="falcon-default"
          className="me-2"
          onClick={() => {
            setShowCanvaFileDelete(false);
          }}
        >
          Annuler
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

OffCanvaFileDelete.propTypes = {
  showCanvaFileDelete: PropTypes.bool,
  setShowCanvaFileDelete: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  selectedObject: PropTypes.object.isRequired
};

export default OffCanvaFileDelete;
