import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { IndexProductBatches } from '../../../services/ProductBatchService';
import TableProductsBatches from './table';
import ManageProductBatches from './Manage';

const IndexProductsBatches = () => {
  const [initialApiProductsBatches, setInitialApiProductsBatches] = useState(
    []
  );
  const [trigger, setTrigger] = useState(Date.now());
  const [showCanvaProduct, setShowCanvaProduct] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    const fetchProductsBatches = async () => {
      const responseProduct = await IndexProductBatches();
      if (responseProduct.success === true) {
        setInitialApiProductsBatches(responseProduct.data);
      }
    };
    fetchProductsBatches();
  }, [trigger]);

  return (
    <>
      {showCanvaProduct && (
        <ManageProductBatches
          showCanvaProduct={showCanvaProduct}
          setShowCanvaProduct={setShowCanvaProduct}
          selectedObject={selectedObject}
          setParentTrigger={setTrigger}
        />
      )}

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row lg={12} className="justify-content-between align-items-center">
            <Col lg={4}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des lots de produits
              </h3>
            </Col>
            <Col xs={8} className="text-end">
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowCanvaProduct(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un lot de produits
              </Button>
            </Col>
          </Row>

          {initialApiProductsBatches.length > 0 && (
            <TableProductsBatches
              showCanvaProduct={showCanvaProduct}
              setShowCanvaProduct={setShowCanvaProduct}
              initialApiProducts={initialApiProductsBatches}
              setSelectedObject={setSelectedObject}
              setParentTrigger={setTrigger}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default IndexProductsBatches;
