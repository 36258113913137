import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { usePagination, useSortBy, useTable } from 'react-table';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import SoftBadge from '../../../common/SoftBadge';
import * as Moment from 'moment';
import CalendarModalEventWaiters from './CalendarModalEventWaiters';
import { IndexPrestationsType } from '../../../../services/PrestationTypeService';
import { useNavigate } from 'react-router';

const ListWaiters = ({ prestations, setSelectedObject, selectedObject }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [formDataSearch, setFormDataSearch] = useStateWithCallbackLazy({
    search: '',
    start: '',
    end: '',
    address: '',
    type: []
  });

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Choisir une date'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted cursor-pointer"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formDataSearch.start && formDataSearch.end && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setFormDataSearch(
              {
                ...formDataSearch,
                start: '',
                end: ''
              },
              data => {
                updateSearch(data, initialObjects);
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  useEffect(() => {
    const fetchType = async () => {
      const responseTypes = await IndexPrestationsType();
      if (responseTypes.success === true) {
        let tmpTypes = [];
        responseTypes.data.map(t => {
          tmpTypes.push({
            value: t.id,
            label: t.name
          });
          setTypes(tmpTypes);
        });
      }
    };
    fetchType();

    let transformedApiObjects = [];
    prestations &&
      prestations.length > 0 &&
      prestations.map(presta => {
        transformedApiObjects.push({
          originalObject: presta,
          simplifiedTime: `${moment(presta.time, 'HH:mm:ss').format('HH:mm')}`,
          originalTitle: presta.title,
          title: (
            <div className="d-block mt-1 t">
              {presta.title.replace('Prestation de', '')}
            </div>
          ),
          originalAddress: presta.address,
          address: (
            <div className="d-block mt-1">
              {presta.address && (
                <a
                  href={`https://www.google.com/maps/place/${presta.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {presta.address}
                </a>
              )}
            </div>
          ),
          initialType: presta.type,
          type: (
            <>
              <span
                className="rounded-circle d-inline-block me-2"
                style={{
                  backgroundColor: presta.backgroundColor,
                  height: '12px',
                  width: '12px',
                  position: 'relative',
                  top: '1px'
                }}
              ></span>
              {presta.typeName}
            </>
          ),
          guest_count: <div className="d-block mt-1">{presta.guest_count}</div>,
          simplifiedStart: Moment(presta.start).local().format('MM/DD/YYYY'),
          simplifiedEnd: Moment(presta.end).local().format('MM/DD/YYYY'),
          date: (
            <>
              <SoftBadge bg="primary" className="text-capitalize">
                {Moment(presta.start).local().format('MMMM')}
              </SoftBadge>
              <div className="d-block mt-1">
                {`${Moment(presta.start).format('DD/MM/YYYY')}`}
                {' à '}
                {`${moment(presta.time, 'HH:mm:ss').format('HH:mm')}`}
              </div>
            </>
          ),
          actions: (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Voir la fiche de la prestation
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    navigate(`/maitre-d-hotel/prestation/${presta.id}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </>
          )
        });
      });
    setIsLoading(false);
    updateSearch(formDataSearch, transformedApiObjects);
    setObjects(transformedApiObjects);
    setInitialObjects(transformedApiObjects);
  }, []);
  const updateSearch = (data, objects) => {
    if (
      data.search.length === 0 &&
      data.address.length === 0 &&
      data.type.length === 0 &&
      !data.start &&
      !data.end
    ) {
      setObjects(objects);
    } else {
      let tmpFilterObjects = initialObjects;
      /* -- filter search ---*/
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            obj.originalTitle &&
            obj.originalTitle
              .toLowerCase()
              .includes(tmpSearch.replace('\t', ' '))
          ) {
            result = true;
          }
          return result;
        });
      }

      /* -- filter address ---*/
      if (data.address.length > 0) {
        let tmpAddress = data.address.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            obj.originalAddress &&
            obj.originalAddress
              .toLowerCase()
              .includes(tmpAddress.replace('\t', ' '))
          ) {
            result = true;
          }
          return result;
        });
      }
      /* -- filter Type ---*/
      if (data.type.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.type.map(type => {
            if (obj.initialType) {
              let tmpType = [];
              tmpType.push(obj.initialType);
              if (tmpType && tmpType.length > 0) {
                tmpType.map(t => {
                  if (t === type.label) {
                    result = true;
                  }
                });
              }
            }
          });
          return result;
        });
      }

      /* -- filter date ---*/
      if (data.start || data.end) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;

          if (
            data.start &&
            Moment(obj.simplifiedStart)
              .local()
              .isSameOrAfter(Moment(data.start)) &&
            (!data.end ||
              (data.end &&
                Moment(obj.simplifiedEnd)
                  .local()
                  .isSameOrBefore(Moment(data.end))))
          ) {
            result = true;
          }
          return result;
        });
      }

      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      {showModal && (
        <CalendarModalEventWaiters
          showModal={showModal}
          setShowModal={setShowModal}
          setSelectedObject={setSelectedObject}
          selectedObject={selectedObject}
        />
      )}
      {isLoading === true ? (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Card className="mt-4">
          <Card.Body>
            <>
              <Row className="align-items-end">
                <Col xs={3} className="mb-3">
                  <Form.Label>Recherche</Form.Label>
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Titre"
                    value={formDataSearch.search || ''}
                    onChange={event => {
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          search: event.target.value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    onChange={dates => {
                      const [start, end] = dates;
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          start: start,
                          end: end
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                    startDate={formDataSearch.start}
                    endDate={formDataSearch.end}
                    selectsRange
                    showMonthDropdown
                    dateFormat="dd/MM/yy"
                    customInput={<DatePickerInput />}
                    locale="fr"
                  />
                </Col>
                <Col xs={3} className="mb-3">
                  <Form.Label>Type de prestation </Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={types}
                    placeholder="Choisir..."
                    isMulti
                    name="type"
                    classNamePrefix="react-select"
                    value={formDataSearch.type}
                    onChange={value => {
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          type: value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
                <Col xs={3} className="mb-3">
                  <Form.Label>Lieu</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Adresse, code postal..."
                    value={formDataSearch.address || ''}
                    onChange={event => {
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          address: event.target.value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <TableWaiters
                    data={objects}
                    setSelectedObject={setSelectedObject}
                  />
                </Col>
              </Row>
            </>

            <p className="mb-0 mt-3 text-primary">
              Aucune prestation à afficher
              <br />
            </p>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function TableWaiters({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'date',
        Header: 'Date'
      },
      {
        accessor: 'title',
        Header: 'Événement'
      },
      {
        accessor: 'type',
        Header: 'Type'
      },
      {
        accessor: 'address',
        Header: 'Lieu'
      },
      {
        accessor: 'guest_count',
        Header: 'Convives'
      },

      {
        accessor: 'actions',
        Header: 'Actions'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}
TableWaiters.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

ListWaiters.propTypes = {
  prestations: PropTypes.array,
  setSelectedObject: PropTypes.func,
  selectedObject: PropTypes.object
};

export default ListWaiters;
