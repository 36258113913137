import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import FormAlert from '../../../commons/FormAlert';
import { toast } from 'react-toastify';
import FileUploader from '../../../users/fileUpload/FileUploader';
import {
  DeletePrestationSection,
  PostModelSection,
  PostPrestationSection,
  PutPrestationSection
} from '../../../../../services/PrestationSection';
import ManageSectionModel from './manageSectionModel';

const ManageSection = ({ formData, setFormData, section, prestation }) => {
  const [showManage, setShowManage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [sectionFormData, setSectionFormData] = useState({
    name: '',
    description: '',
    image_url: '',
    prestation_id: prestation.id
  });

  useEffect(() => {
    if (section && section.id) {
      setSectionFormData({
        ...sectionFormData,
        name: section.name,
        description: section.description,
        image_url: section.image_url
      });
    }
  }, [section]);

  const handleSubmitModel = async model => {
    const response = await PostModelSection({
      name: model.name,
      description: model.description,
      image_url: model.image_url
    });
    if (response.success === true) {
      toast('Nouveau modèle enregistré');
    }
  };

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    let response = false;
    if (section && section.id) {
      response = await PutPrestationSection(section.id, sectionFormData);
    } else {
      response = await PostPrestationSection(sectionFormData);
    }
    if (response.success === true) {
      setShowManage(false);
      toast('Section enregistrée');
      if (section && section.id) {
        const tmpSections = formData.sections;
        const index = tmpSections.findIndex(
          sectionTmp => sectionTmp.id === section.id
        );
        tmpSections[index] = {
          ...tmpSections[index],
          name: response.data.name,
          description: response.data.description,
          image_url: response.data.image_url,
          prestation_id: response.data.prestation_id
        };
        setFormData({
          ...formData,
          sections: tmpSections
        });
      } else {
        setFormData({
          ...formData,
          sections: [
            ...formData.sections,
            {
              id: response.data.id,
              name: response.data.name,
              description: response.data.description,
              image_url: response.data.image_url,
              prestation_id: response.data.prestation_id
            }
          ]
        });
      }
    } else {
      toast('Une erreur est survenu');
    }
    setIsLoadingSubmit(false);
    setErrorForm(response.validator);
  };

  const deleteSection = async id => {
    const responseDeleteSection = await DeletePrestationSection(id);
    if (responseDeleteSection.success === true) {
      setShowDelete(false);
      const tmpSections = formData.sections;
      const index = tmpSections.findIndex(sectionTmp => sectionTmp.id === id);
      tmpSections.splice(index, 1);
      let tmpProducts = formData.products;
      tmpProducts = tmpProducts.filter(product => product.section_id !== id);
      setFormData({
        ...formData,
        sections: tmpSections,
        products: tmpProducts
      });
      toast('Section supprimée');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {section && section.id ? (
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="14"
            height="14"
            className="cursor-pointer"
            onClick={() => {
              setShowManage(true);
            }}
          >
            <path
              d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33365L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6474L14.435 2.21233C14.8256 1.8218 15.4587 1.8218 15.8492 2.21233L18.6777 5.04075C19.0682 5.43128 19.0682 6.06444 18.6777 6.45497L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
              fill="#2C7BE5"
            ></path>
          </svg>
          <span>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="hi">Modifier</Tooltip>}
            >
              <a
                className="cursor-pointer"
                title="Enregistrer comme modèle"
                onClick={() => {
                  handleSubmitModel(section);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  style={{
                    marginBottom: '1px',
                    marginLeft: '3px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M18 19H19V6.82843L17.1716 5H16V9H7V5H5V19H6V12H18V19ZM4 3H18L20.7071 5.70711C20.8946 5.89464 21 6.149 21 6.41421V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM8 14V19H16V14H8Z"
                    fill="rgba(44,123,229,1)"
                  />
                </svg>
              </a>
            </OverlayTrigger>
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="cursor-pointer ms-2"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              fill="rgba(229,47,18,1)"
            />
          </svg>
        </span>
      ) : (
        <>
          <Button
            size="sm"
            variant="falcon-default"
            onClick={() => {
              setShowManage(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                fill="rgba(94,110,130,1)"
              />
            </svg>{' '}
            Ajouter une section
          </Button>
          <ManageSectionModel
            prestation={prestation}
            formData={formData}
            setFormData={setFormData}
          />
        </>
      )}
      <Offcanvas
        className={'w-50'}
        show={showManage}
        onHide={() => {
          setShowManage(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowManage(false);
          }}
        >
          <Offcanvas.Title>
            <h3 className="text-primary admin-title mb-0">
              {section && section.id
                ? 'Modifier la section'
                : 'Ajouter une section'}
            </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Col xs={12}>
              <Form.Group controlId="content">
                <Form.Label>Titre de la section :</Form.Label>
                <Form.Control
                  rows={3}
                  type="text"
                  name="name"
                  placeholder="Titre de la section"
                  value={sectionFormData.name}
                  onChange={e => {
                    setSectionFormData({
                      ...sectionFormData,
                      name: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Group controlId="content">
                <Form.Label>
                  Description <small>pour la plaquette</small> :
                </Form.Label>
                <Form.Control
                  rows={3}
                  as={'textarea'}
                  type="text"
                  name="description"
                  placeholder="Description de la section"
                  value={sectionFormData.description}
                  onChange={e => {
                    setSectionFormData({
                      ...sectionFormData,
                      description: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Label>
                Photo <small>pour la plaquette</small> :
              </Form.Label>
              <FileUploader
                pathToUpload="booklets/covers"
                formData={sectionFormData}
                setFormData={setSectionFormData}
                format="img"
                databaseField="image_url"
                cropperEnabled={true}
                currentImage={sectionFormData.image_url || null}
              />
            </Col>
            <Col lg={12}>
              <Button
                disabled={isLoadingSubmit}
                className="mt-3"
                onClick={e => {
                  handleSubmit(e);
                }}
                variant="primary"
              >
                Enregistrer
              </Button>
            </Col>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {section && section.id && (
        <Offcanvas
          show={showDelete}
          onHide={() => {
            setShowDelete(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>Supprimer la section ?</p>
            <Button
              size="sm"
              variant="falcon-danger"
              className="me-2"
              onClick={() => {
                deleteSection(section.id);
              }}
            >
              Oui, supprimer la section
            </Button>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowDelete(false);
              }}
            >
              Annuler
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageSection.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  section: PropTypes.object,
  prestation: PropTypes.object
};

export default ManageSection;
