import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import * as Moment from 'moment/moment';
import moment from 'moment';

const TableBackLogs = ({ initialApiLogs, setParentTrigger }) => {
  const [initialObjects, setInitialObjects] = useState([]);
  const [logs, setLogs] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    search: ''
  });
  useEffect(() => {
    transformedDataApiCustomer(initialApiLogs);
  }, [initialApiLogs]);
  const updateSearch = data => {
    if (data.search.length === 0) {
      setLogs(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            obj.initialObject.prestation?.name
              ?.toLowerCase()
              .includes(tmpSearch) ||
            obj.initialObject.email?.toLowerCase().includes(tmpSearch) ||
            obj.initialObject.to?.toLowerCase().includes(tmpSearch)
          ) {
            result = true;
          }
          return result;
        });
      }
      setLogs(tmpFilterObjects);
    }
  };
  const transformedDataApiCustomer = logs => {
    let transformedApi = [];
    logs.map(obj => {
      transformedApi.push({
        initialObject: obj,
        id: <small>{obj.id}</small>,
        prestation: (
          <small>
            <a
              href={obj.prestation ? `/prestation/${obj.prestation.id}` : '#'}
              target="_blank"
              rel="noreferrer"
            >
              {obj.prestation?.name}
            </a>
          </small>
        ),
        email: <small>{obj.email}</small>,
        to: <small>{obj.to}</small>,
        status:
          obj.events && obj.events.length > 0 ? (
            <small>
              {obj.events[0].event_type}{' '}
              <small>
                le {moment(obj.events[0].created_at).format('DD/MM/yyyy HH:mm')}
              </small>
            </small>
          ) : (
            ''
          ),
        created_at: Moment(obj.created_at).local().format('DD/MM/yyyy HH:mm')
      });
    });
    setLogs(transformedApi);
    setInitialObjects(transformedApi);
  };

  return (
    <>
      {initialApiLogs.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Row className="align-items-end">
              <Col xs={6} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Rechercher quelque chose..."
                  value={formData.search || ''}
                  onChange={event => {
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>

              <Fragment>
                <Col xs={12} className="mt-3">
                  <Table data={logs} setTrigger={setParentTrigger} />
                </Col>
              </Fragment>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    // eslint-disable-next-line no-sparse-arrays
    () => [
      {
        accessor: 'id',
        Header: '#'
      },
      {
        accessor: 'prestation',
        Header: 'Prestation'
      },
      {
        accessor: 'email',
        Header: 'Sujet'
      },
      {
        accessor: 'to',
        Header: 'Envoyé à'
      },
      {
        accessor: 'status',
        Header: 'Statut'
      },
      {
        accessor: 'created_at',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 15 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
        {/*<ExportBackUsers users={data} />*/}
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func,
  setParentTrigger: PropTypes.func
};

TableBackLogs.propTypes = {
  initialApiLogs: PropTypes.array,
  setParentTrigger: PropTypes.func
};

export default TableBackLogs;
