import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import {
  IndexProductCategories,
  PostProductCategories,
  PutProductCategories
} from '../../../../services/ProductCategoriesService';

const ManageProductCategory = ({
  showManageCategories,
  setShowManageCategories,
  selectedCategory,
  formParentProduct,
  setFormParentProduct,
  setLabelCategory,
  setTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    name: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedCategory && selectedCategory.id) {
      response = await PutProductCategories(selectedCategory.id, formData);
    } else {
      response = await PostProductCategories(formData);
    }

    if (response.success === true) {
      toast(`Catégorie enregistrée`);
      setIsLoadingSubmit(true);
      const responseCategories = await IndexProductCategories();
      if (responseCategories.success === true) {
        setShowManageCategories(false);
        let categoriesArray = [];
        responseCategories.data.map(cat => {
          categoriesArray.push({
            value: cat.id,
            label: cat.name
          });
        });
        setLabelCategory(categoriesArray);
      }

      if (formParentProduct) {
        setFormParentProduct({
          ...formParentProduct,
          categories: [
            ...formParentProduct.categories,
            { value: response.data.id, label: response.data.name }
          ]
        });
        setShowManageCategories(false);
        return;
      }

      if (!formParentProduct) {
        setTrigger(Date.now());
      }
      setShowManageCategories(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory.id) {
      setFormData({
        ...formData,
        name: selectedCategory.name
      });
    }
  }, []);
  return (
    <>
      <Offcanvas
        show={showManageCategories}
        onHide={() => {
          setShowManageCategories(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowManageCategories(false);
          }}
        >
          <Offcanvas.Title>
            {selectedCategory && selectedCategory.id
              ? 'Modifier la catégorie'
              : `Ajouter une catégorie`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="name">
                  <Form.Label>Nom de la catégorie :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Entrée, Plats, Dessert..."
                    value={formData.name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  {selectedCategory && selectedCategory.id
                    ? 'Enregistrer la modification'
                    : 'Enregistrer la catégorie'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageProductCategory.propTypes = {
  selectedCategory: PropTypes.object,
  setShowManageCategories: PropTypes.func,
  showManageCategories: PropTypes.bool,
  setTrigger: PropTypes.func,
  formParentProduct: PropTypes.object,
  setFormParentProduct: PropTypes.func,
  setLabelCategory: PropTypes.func
};

export default ManageProductCategory;
