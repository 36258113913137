import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button, Offcanvas } from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import SoftBadge from '../../common/SoftBadge';
import CanvaContactCustomer from './ContactCanva';
import CanvaNotes from '../canvas-components/canvaNotes';
import { toast } from 'react-toastify';
import { DeleteCustomer } from '../../../services/CustomerService';
import CanvaCustomer from './CustomerCanva';

const TableBackCustomers = ({
  initialApiCustomers,
  setParentTrigger,
  setShowManageCustomers,
  setSelectedObject
}) => {
  const [showCanvaContactCustomer, setShowCanvaContactCustomer] =
    useState(false);
  const [showCanvasCustomer, setshowCanvasCustomer] = useState(false);
  const [initialObjects, setInitialObjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const [formData, setFormData] = useStateWithCallbackLazy({
    search: ''
  });
  const [selectedCustomer, setSelectedCustomer] = useState({});

  useEffect(() => {
    transformedDataApiCustomer(initialApiCustomers);
  }, [initialApiCustomers]);

  const deleteCustomer = async id => {
    const responsedeleteCustomer = await DeleteCustomer(id);
    if (responsedeleteCustomer.success === true) {
      toast('Client archivé');
      setShowDelete(false);
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const updateSearch = data => {
    if (data.search.length === 0) {
      setCustomers(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.fullname && obj.fullname.toLowerCase().includes(tmpSearch)) ||
            (obj.initialAddress &&
              obj.initialAddress.toLowerCase().includes(tmpSearch)) ||
            (obj.zip && obj.zip.toLowerCase().includes(tmpSearch)) ||
            (obj.sage_id && obj.sage_id.toLowerCase().includes(tmpSearch)) ||
            (obj.city && obj.city.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      setCustomers(tmpFilterObjects);
    }
  };
  const transformedDataApiCustomer = customers => {
    let transformedApi = [];
    customers.map(obj => {
      transformedApi.push({
        fullname: obj.name,
        name: (
          <a
            className="cursor-pointer"
            onClick={() => {
              setSelectedCustomer(obj);
              setshowCanvasCustomer(true);
            }}
          >
            {obj.name}
          </a>
        ),
        initialAddress: obj.address,
        contacts: (
          <>
            {obj.contacts && obj.contacts.length > 0 ? (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setSelectedCustomer(obj);
                  setShowCanvaContactCustomer(true);
                }}
              >
                <SoftBadge bg="info">
                  {obj.contacts.length} contact
                  {obj.contacts.length > 1 ? 's' : ''}
                </SoftBadge>
              </span>
            ) : (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setSelectedCustomer(obj);
                  setShowCanvaContactCustomer(true);
                }}
              >
                <SoftBadge bg="warning">Aucun contact</SoftBadge>
              </span>
            )}
          </>
        ),
        address: (
          <>
            {obj.address} {obj.zip} {obj.city}
          </>
        ),
        sage_id: obj.sage_id,
        pennylane_id: obj.pennylane_id ? 'OK' : '',
        notes: (
          <CanvaNotes
            model={obj}
            noteableType={'customer'}
            setTrigger={setParentTrigger}
            context={'customers'}
            hideSvg={true}
          />
        ),
        actions: (
          <>
            <Button
              size="sm"
              variant="primary"
              className="me-2 admin-primary"
              type="button"
              onClick={() => {
                setShowManageCustomers(true);
                setSelectedObject(obj);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px'
                }}
              >
                <path
                  d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="me-2 admin-primary"
              type="button"
              onClick={() => {
                setShowDelete(true);
                setSelectedCustomer(obj);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px'
                }}
              >
                <path
                  d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
          </>
        )
      });
    });
    setCustomers(transformedApi);
    setInitialObjects(transformedApi);
  };

  return (
    <>
      {initialApiCustomers.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Row className="align-items-end">
              <Col xs={6} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Dénomination, prix, référence ..."
                  value={formData.search || ''}
                  onChange={event => {
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>

              <Fragment>
                <Col xs={12} className="mt-3">
                  <Table data={customers} setTrigger={setParentTrigger} />
                </Col>
              </Fragment>
            </Row>
          </Card.Body>
        </Card>
      )}
      {showCanvaContactCustomer && (
        <CanvaContactCustomer
          showCanvaContactCustomer={showCanvaContactCustomer}
          setShowCanvaContactCustomer={setShowCanvaContactCustomer}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setParentTrigger={setParentTrigger}
        />
      )}
      {showCanvasCustomer && (
        <CanvaCustomer
          showCanvasCustomer={showCanvasCustomer}
          setShowCanvasCustomer={setshowCanvasCustomer}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setParentTrigger={setParentTrigger}
        />
      )}
      {showDelete && (
        <Offcanvas
          show={showDelete}
          onHide={() => {
            setSelectedCustomer(null);
            setShowDelete(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>
              Archiver le client "{selectedCustomer.name}" dans le portail AVS
              (aucune incidence dans Pennylane) ?
            </p>
            <Button
              size="sm"
              variant="falcon-danger"
              className="me-2"
              onClick={() => {
                deleteCustomer(selectedCustomer.id);
              }}
            >
              Oui, archiver le client
            </Button>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowDelete(false);
              }}
            >
              Annuler
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    // eslint-disable-next-line no-sparse-arrays
    () => [
      {
        accessor: 'name',
        Header: 'Entreprise'
      },
      {
        accessor: 'address',
        Header: 'Adresse'
      },
      {
        accessor: 'contacts',
        Header: 'Contacts'
      },
      /*{
        accessor: 'sage_id',
        Header: 'Identifiant Sage'
      },*/
      {
        accessor: 'pennylane_id',
        Header: 'Connexion Pennylane'
      },
      {
        accessor: 'notes',
        Header: 'Notes'
      },
      {
        accessor: 'actions',
        Header: 'Actions'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 15 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
        {/*<ExportBackUsers users={data} />*/}
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func,
  setParentTrigger: PropTypes.func
};

TableBackCustomers.propTypes = {
  initialApiCustomers: PropTypes.array,
  setParentTrigger: PropTypes.func,
  setShowManageCustomers: PropTypes.func,
  setSelectedObject: PropTypes.func
};

export default TableBackCustomers;
