import HttpService from './HttpService';
const http = new HttpService();
const checkAutoLogOutUserIfUnauthenticated = data => {
  if (data.success === false && data.message === 'Unauthenticated') {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    window.location.href = '/login';
  }
};
export default class ModelService {
  IndexModel = async url => {
    return http
      .getData(url)
      .then(data => {
        checkAutoLogOutUserIfUnauthenticated(data);
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  ShowModel = async url => {
    return http
      .getData(url)
      .then(data => {
        checkAutoLogOutUserIfUnauthenticated(data);
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  PostModel = async (url, formData) => {
    return http
      .postData(formData, url)
      .then(data => {
        checkAutoLogOutUserIfUnauthenticated(data);
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  PutModel = async (url, formData) => {
    return http
      .putData(formData, url)
      .then(data => {
        checkAutoLogOutUserIfUnauthenticated(data);
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  DeleteModel = async url => {
    return http
      .deleteData(url)
      .then(data => {
        checkAutoLogOutUserIfUnauthenticated(data);
        return data;
      })
      .catch(error => {
        return error;
      });
  };
}
