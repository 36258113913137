import { Card, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap';

import React, { useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import FalconCardHeader from '../../../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from '../../../common/FalconCloseButton';
import Flex from '../../../common/Flex';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import IconButton from '../../../common/IconButton';

const ManageForwardedEmailsService = ({ prestation, setParentTrigger }) => {
  const [showForwardedEmail, setShowForwardedEmail] = useState(false);
  const [selectedForwardedmail, setSelectedForwardedmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Card
        className="recent-activity-card mt-3"
        style={{
          borderWidth: '1px',
          borderColor: 'rgba(0,0,0,0.125)',
          borderRadius: '4px'
        }}
      >
        <FalconCardHeader
          title={
            <>
              Historique{' '}
              {isLoading === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                  style={{ cursor: 'not-allowed' }}
                >
                  <path
                    d="M12 4C14.7486 4 17.1749 5.38626 18.6156 7.5H16V9.5H22V3.5H20V5.99936C18.1762 3.57166 15.2724 2 12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4ZM20 12C20 16.4183 16.4183 20 12 20C9.25144 20 6.82508 18.6137 5.38443 16.5H8V14.5H2V20.5H4V18.0006C5.82381 20.4283 8.72764 22 12 22C17.5228 22 22 17.5228 22 12H20Z"
                    fill="#9da9bb"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                  className="cursor-pointer"
                  onClick={() => {
                    setParentTrigger(Date.now());
                    setIsLoading(true);
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 2000);
                  }}
                >
                  <path
                    d="M12 4C14.7486 4 17.1749 5.38626 18.6156 7.5H16V9.5H22V3.5H20V5.99936C18.1762 3.57166 15.2724 2 12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4ZM20 12C20 16.4183 16.4183 20 12 20C9.25144 20 6.82508 18.6137 5.38443 16.5H8V14.5H2V20.5H4V18.0006C5.82381 20.4283 8.72764 22 12 22C17.5228 22 22 17.5228 22 12H20Z"
                    fill="#2C7BE5"
                  ></path>
                </svg>
              )}
            </>
          }
        />
        <Card.Body className="pt-0">
          <Tabs
            className="mb-3 mt-0"
            defaultActiveKey="emails"
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="emails" title="Emails" className="pb-3 px-0 pt-2">
              <Row>
                <Col xs={12}>
                  <p className="text-muted small mb-0 ms-3 ps-1">
                    Transférer un mail dans l'historique à l'adresse :<br />
                    <span
                      className="text-800 cursor-pointer d-block mb-3"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `prestation-${prestation.id}@mails.avs.qwenty.fr`
                        );
                        toast('Adresse copiée dans le presse-papier');
                      }}
                    >
                      {`prestation-${prestation.id}@mails.avs.qwenty.fr`}
                    </span>
                  </p>
                </Col>
              </Row>
              <SimpleBarReact style={{ height: '27rem' }}>
                <Card.Body className="ps-2 recent-activity-body-height">
                  {prestation.forwardedmails &&
                    prestation.forwardedmails.length > 0 && (
                      <>
                        {prestation.forwardedmails.map(
                          (forwardedmail, index) => (
                            <Row
                              key={index}
                              className="g-3 recent-activity-timeline recent-activity-timeline-primary recent-activity-timeline-past pb-x1"
                            >
                              <Col xs="auto" className="ps-4 ms-2">
                                <div className="ps-2">
                                  <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                                    <FontAwesomeIcon
                                      icon={'envelope-open'}
                                      className="text-primary"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <Row className="g-3 border-bottom pb-x1">
                                  <Col>
                                    <h6 className="text-800 mb-1">
                                      <span className="text-muted">
                                        {forwardedmail.from}
                                      </span>
                                      <span className="text-muted d-block">
                                        <small>
                                          À : {forwardedmail.recipient}
                                        </small>
                                      </span>
                                      <span className="d-block mb-1"></span>
                                      {forwardedmail.subject}
                                    </h6>
                                    <p className="fs--1 text-600 mb-0">
                                      <a
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setShowForwardedEmail(true);
                                          setSelectedForwardedmail(
                                            forwardedmail
                                          );
                                        }}
                                      >
                                        {forwardedmail.data.length > 50
                                          ? `${forwardedmail.data.substring(
                                              0,
                                              50
                                            )}...`
                                          : forwardedmail.data}
                                      </a>
                                    </p>
                                  </Col>
                                  <Col xs="auto">
                                    <p className="fs--2 text-500 mb-0">
                                      {Moment(forwardedmail.created_at)
                                        .local()
                                        .format('DD/MM/YYYY')}
                                    </p>
                                  </Col>
                                  {forwardedmail.attachments &&
                                    forwardedmail.attachments.length > 0 && (
                                      <Col xs={12} className="mt-1">
                                        {forwardedmail.attachments.map(
                                          (attachment, index) => (
                                            <small key={index}>
                                              <Flex
                                                inline
                                                alignItems="center"
                                                className="border px-3 py-1 me-2 mt-2 inbox-link cursor-pointer"
                                                onClick={() => {
                                                  window.open(
                                                    attachment.file_url,
                                                    '_blank'
                                                  );
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={'file-alt'}
                                                  transform="grow-4"
                                                  className="text-primary"
                                                />
                                                <span
                                                  className="ms-2"
                                                  style={{ fontSize: '13px' }}
                                                >
                                                  {attachment.name}
                                                </span>
                                              </Flex>
                                            </small>
                                          )
                                        )}
                                      </Col>
                                    )}
                                </Row>
                              </Col>
                            </Row>
                          )
                        )}
                      </>
                    )}
                </Card.Body>
              </SimpleBarReact>
            </Tab>
            {/*<Tab
              eventKey="activities"
              title="Activités"
              className="border-bottom"
            >
              <SimpleBarReact style={{ height: '27rem' }}>
                <Card.Body className="ps-2 recent-activity-body-height">
                  {prestation.logs && prestation.logs.length > 0 && (
                    <>
                      {prestation.logs.map((log, index) => (
                        <Row
                          key={index}
                          className="g-3 recent-activity-timeline recent-activity-timeline-primary recent-activity-timeline-past pb-x1"
                        >
                          <Col xs="auto" className="ps-4 ms-2">
                            <div className="ps-2">
                              <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                                <FontAwesomeIcon
                                  icon={'clock'}
                                  className="text-primary"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <Row className="g-3 border-bottom pb-x1">
                              <Col>
                                <h6 className="text-800 mb-1">
                                  <span className="text-muted">
                                    {log.user?.firstname} {log.user?.lastname}
                                  </span>
                                  <span className="d-block mb-1"></span>
                                  {log.action === 'created'
                                    ? 'Création'
                                    : log.action === 'updated'
                                    ? 'Modification'
                                    : 'Suppression'}{' '}
                                  de la prestation
                                </h6>
                              </Col>
                              <Col xs="auto">
                                <p className="fs--2 text-500 mb-0">
                                  {Moment(log.created_at)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Card.Body>
              </SimpleBarReact>
            </Tab>*/}
          </Tabs>
        </Card.Body>
      </Card>
      <Offcanvas
        placement={'end'}
        style={{ width: '50%' }}
        show={showForwardedEmail && selectedForwardedmail}
        onHide={() => {
          setShowForwardedEmail(false);
          setSelectedForwardedmail(false);
        }}
      >
        <Offcanvas.Header className="pb-0">
          <Offcanvas.Title>{selectedForwardedmail.subject}</Offcanvas.Title>
          <FalconCloseButton
            onClick={() => {
              setShowForwardedEmail(false);
              setSelectedForwardedmail(false);
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <h6 className="">
                    De : {selectedForwardedmail.from}
                    {' <'}
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          selectedForwardedmail.sender
                        );
                        toast('Adresse copiée dans le presse-papier');
                      }}
                    >
                      {selectedForwardedmail.sender}
                    </span>
                    {'>'}
                  </h6>
                  <h6 className="">À : {selectedForwardedmail.recipient}</h6>
                </Col>
                <Col xs={6} className="text-end d-none">
                  <IconButton
                    as={Link}
                    variant="falcon-default"
                    size="sm"
                    icon="reply"
                    to={`mailto:${selectedForwardedmail.sender},prestation-${prestation.id}@mails.avs.qwenty.fr?subject=Re%20%3A%20${selectedForwardedmail.subject}`}
                  >
                    Répondre
                  </IconButton>
                </Col>
              </Row>
              <h6 className="mt-3">
                Le{' '}
                {Moment(selectedForwardedmail.created_at)
                  .local()
                  .format('DD/MM/YYYY HH:mm')}{' '}
                :
              </h6>
              {selectedForwardedmail.data_html ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedForwardedmail.data_html
                  }}
                  className="fs--1 text-600 mb-0 mt-3"
                ></p>
              ) : (
                <p className="fs--1 text-600 mb-0">
                  {selectedForwardedmail.data}
                </p>
              )}
            </Col>
            {selectedForwardedmail.attachments &&
              selectedForwardedmail.attachments.length > 0 && (
                <Col xs={12} className="mt-1">
                  {selectedForwardedmail.attachments.map(
                    (attachment, index) => (
                      <small key={index}>
                        <Flex
                          inline
                          alignItems="center"
                          className="border px-3 py-1 me-2 mt-2 inbox-link cursor-pointer"
                          onClick={() => {
                            window.open(attachment.file_url, '_blank');
                          }}
                        >
                          <FontAwesomeIcon
                            icon={'file-alt'}
                            transform="grow-4"
                            className="text-primary"
                          />
                          <span className="ms-2" style={{ fontSize: '13px' }}>
                            {attachment.name}
                          </span>
                        </Flex>
                      </small>
                    )
                  )}
                </Col>
              )}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageForwardedEmailsService.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func
};

export default ManageForwardedEmailsService;
