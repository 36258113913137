import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { IndexLog } from '../../../services/LogService';
import TableBackLogs from './table';

const IndexLogs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialApiLogs, setInitialApiLogs] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  useEffect(() => {
    const fetchLogs = async () => {
      setIsLoading(true);
      if (initialApiLogs.length > 0) {
        setIsLoading(false);
      }
      const responseLogs = await IndexLog();
      if (responseLogs.success === true) {
        setInitialApiLogs(responseLogs.data);
        setIsLoading(false);
      }
    };
    fetchLogs();
  }, [trigger]);
  return (
    <>
      {' '}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Historique des actions
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiLogs.length > 0 && (
        <TableBackLogs
          initialApiLogs={initialApiLogs}
          setParentTrigger={setTrigger}
        />
      )}
    </>
  );
};

export default IndexLogs;
