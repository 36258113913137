import { toast } from 'react-toastify';

export default function RequireAuthAdmin(navigate) {
  if (
    !localStorage.getItem('accessToken_avs') &&
    !localStorage.getItem('user_avs')
  ) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (
    ![1, 3, 4].includes(JSON.parse(localStorage.getItem('user_avs')).role_id)
  ) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/login');
  }
}
