import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'products/seasons';

export async function IndexSeasons() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowSeason(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutSeasons(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostSeasons(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteSeasons(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
