import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import Select from 'react-select';
import { PutPrestation } from '../../../../services/PrestationService';

const ManageButtlersFromService = ({
  showAddButtlers,
  prestation,
  setShowAddButtlers,
  allButtlersLabel,
  formDataParent,
  setFormDataParent,
  setGrandParentTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let tmpFormData = { ...formDataParent };
    tmpFormData = {
      ...tmpFormData,
      context: 'butlers',
      butlers: tmpFormData.butlers.map(butler => {
        return {
          butler_id: butler.value
        };
      })
    };
    let response = false;
    response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      setShowAddButtlers(false);
      toast("Maitre d'hôtel ajouté à la prestation");
      setGrandParentTrigger(Date.now);
    } else {
      toast('Une erreur est survenu');
    }
    setIsLoadingSubmit(false);
    setErrorForm(response.validator);
  };

  return (
    <>
      {showAddButtlers && (
        <Offcanvas
          className={'w-50'}
          show={showAddButtlers}
          onHide={() => {
            setShowAddButtlers(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header
            closeButton={() => {
              setShowAddButtlers(false);
            }}
          >
            <Offcanvas.Title>
              <h3 className="text-primary admin-title mb-0">
                Gestion des ressources humaines
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}

            <Col>
              <Form.Group>
                <Form.Label>Sélectionner les ressources humaines</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  placeholder="Choisir ..."
                  isMulti
                  name="butlers"
                  options={allButtlersLabel}
                  onChange={value => {
                    setFormDataParent({
                      ...formDataParent,
                      butlers: value
                    });
                  }}
                  value={formDataParent.butlers}
                />
              </Form.Group>
            </Col>
            <Form onSubmit={handleSubmit}>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  Enregistrer
                </Button>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageButtlersFromService.propTypes = {
  prestation: PropTypes.object,
  trigger: PropTypes.bool,
  showAddButtlers: PropTypes.bool,
  setShowAddButtlers: PropTypes.func,
  setFormDataParent: PropTypes.func,
  setGrandParentTrigger: PropTypes.func,
  allButtlersLabel: PropTypes.array,
  formDataParent: PropTypes.object
};

export default ManageButtlersFromService;
