import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'folders';

export async function IndexFolder() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowFolder(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostFolder(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutFolder(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteFolder(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
