import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'resources/categories';

export async function IndexResourceCategories() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowResourceCategory(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PutResourceCategories(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostResourceCategories(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteResourceCategories(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
