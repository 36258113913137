import {
  Button,
  Card,
  Col,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SoftBadgeWithStyle from '../../commons/SoftBadgeWithStyle';
import * as Moment from 'moment';
import SoftBadge from '../../../common/SoftBadge';
import CanvaNotes from '../../canvas-components/canvaNotes';
import { toast } from 'react-toastify';
import {
  DeletePrestation,
  DuplicatePrestation
} from '../../../../services/PrestationService';
import { useNavigate } from 'react-router';

const ManageHeaderService = ({ prestation, setTrigger }) => {
  const isColorLight = hex => {
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };

  const [showCanvaDelete, setShowCanvaDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    const response = await DeletePrestation(prestation.id);
    if (response.success === true) {
      toast('Prestation archivée');
      setTimeout(() => {
        window.location.href = '/calendrier';
      }, 1800);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <Card className="mt-3">
        {isLoading === true ? (
          <Row>
            <Col xs={12} className="mt-3 mb-2 ms-3">
              <Spinner animation="border" role="status" size="xs">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
          <Card.Body>
            <Row>
              <Col xs={8}>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className={'mb-2 d-block'}
                  onClick={() => navigate(-1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                      fill="rgba(93,110,130,1)"
                    />
                  </svg>
                  Retour
                </Button>
                <h3 className="mb-2">{prestation && prestation.name}</h3>
                {prestation.type && (
                  <SoftBadgeWithStyle
                    style={{
                      backgroundColor: prestation.type.color,
                      color: isColorLight(prestation.type.color)
                        ? '#000000'
                        : '#FFFFFF'
                    }}
                  >
                    {prestation.type.name}
                  </SoftBadgeWithStyle>
                )}
                {prestation.date && (
                  <SoftBadge bg="primary" className="text-capitalize ms-3">
                    {Moment(prestation.date).local().format('DD/MM/YYYY')}
                  </SoftBadge>
                )}
              </Col>
              <Col className="text-end" xs={4}>
                <CanvaNotes
                  model={prestation}
                  noteableType={'prestation'}
                  setTrigger={setTrigger}
                  context={'prestation'}
                />
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="hi">Dupliquer la prestation</Tooltip>}
                >
                  <Button
                    className="ms-3"
                    variant="falcon-default"
                    size="sm"
                    onClick={async () => {
                      setIsLoading(true);
                      const response = await DuplicatePrestation(prestation.id);
                      if (response.success === true) {
                        window.location.href = `/prestation/${response.data.id}`;
                      } else {
                        setIsLoading(false);
                        toast(
                          'Une erreur est survenue, veuillez réessayer plus tard'
                        );
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z"
                        fill="rgba(93,110,130,1)"
                      />
                    </svg>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="hi">Archiver la prestation</Tooltip>}
                >
                  <Button
                    className="ms-3"
                    variant="falcon-default"
                    size="sm"
                    onClick={() => {
                      setShowCanvaDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M3 10H2V4.00293C2 3.44903 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.43788 22 4.00293V10H21V20.0015C21 20.553 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5525 3 20.0015V10ZM19 10H5V19H19V10ZM4 5V8H20V5H4ZM9 12H15V14H9V12Z"
                        fill="rgba(245,128,62,1)"
                      />
                    </svg>
                  </Button>
                </OverlayTrigger>
                <Offcanvas
                  show={showCanvaDelete}
                  onHide={() => {
                    setShowCanvaDelete(false);
                  }}
                  placement={'end'}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <p>
                      Archiver la prestation :{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        {prestation.name}{' '}
                      </span>{' '}
                      ?
                    </p>
                    <Button
                      size="sm"
                      variant="falcon-warning"
                      className="me-2"
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      Oui, archiver
                    </Button>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      onClick={() => {
                        setShowCanvaDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Offcanvas.Body>
                </Offcanvas>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

ManageHeaderService.propTypes = {
  prestation: PropTypes.object,
  setTrigger: PropTypes.func
};

export default ManageHeaderService;
