import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'booklets';

export async function ShowBooklet(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostBooklet(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutBooklet(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteBooklet(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PostSection(formData) {
  return modelService.PostModel(`${model}/sections`, formData);
}

export async function SendBooklet(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function SendBookletQuote(prestationId, formData) {
  return modelService.PostModel(
    `prestations/send-booklet-quote/${prestationId}`,
    formData
  );
}
