import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import Select from 'react-select';
import { PutPrestation } from '../../../../services/PrestationService';

const ManageVehiclesFromService = ({
  showAddVehicles,
  prestation,
  setShowAddVehicles,
  allVehiclesLabel,
  setGrandParentTrigger,
  formDataParentVehicles,
  setFormDataVehicles,
  vehiclesFromPresta
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let tmpFormData = { ...formDataParentVehicles };
    tmpFormData = {
      ...tmpFormData,
      context: 'vehicles',
      vehicles: tmpFormData.vehicles.map(vehicle => vehicle.value)
    };
    let response = false;
    response = await PutPrestation(prestation.id, tmpFormData);
    if (response.success === true) {
      setShowAddVehicles(false);
      toast('Enregistré avec succès');
      setGrandParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenu');
    }
    setIsLoadingSubmit(false);
    setErrorForm(response.validator);
  };

  useEffect(() => {
    setFormDataVehicles({
      ...formDataParentVehicles,
      vehicles: vehiclesFromPresta
    });
  }, [vehiclesFromPresta]);

  return (
    <>
      {showAddVehicles && (
        <Offcanvas
          className={'w-50'}
          show={showAddVehicles}
          onHide={() => {
            setShowAddVehicles(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header
            closeButton={() => {
              setShowAddVehicles(false);
            }}
          >
            <Offcanvas.Title>
              <h3 className="text-primary admin-title mb-0">
                Gestions des véhicules
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}

            <Col>
              <Form.Group>
                <Form.Label>Assigner des véhicules à la prestation </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  placeholder="Choisir ..."
                  isMulti
                  name="vehicles"
                  options={allVehiclesLabel}
                  onChange={value => {
                    setFormDataVehicles({
                      ...formDataParentVehicles,
                      vehicles: value
                    });
                  }}
                  value={formDataParentVehicles.vehicles}
                />
              </Form.Group>
            </Col>
            <Form onSubmit={handleSubmit}>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  Enregistrer
                </Button>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageVehiclesFromService.propTypes = {
  prestation: PropTypes.object,
  trigger: PropTypes.bool,
  showAddVehicles: PropTypes.bool,
  setShowAddVehicles: PropTypes.func,
  setFormDataParent: PropTypes.func,
  setGrandParentTrigger: PropTypes.func,
  allVehiclesLabel: PropTypes.array,
  vehiclesFromPresta: PropTypes.array,
  formDataParent: PropTypes.object,
  formDataParentVehicles: PropTypes.object,
  setFormDataVehicles: PropTypes.func
};

export default ManageVehiclesFromService;
