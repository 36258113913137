import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexUser } from '../../../services/UserService';
import TableBackUsers from './table';
import ManageUsers from './manage';

const IndexUsers = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiUsersObjects, setInitialApiUsersObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [showManageUsers, setShowManageUsers] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const responseObjects = await IndexUser();
      if (responseObjects.success === true) {
        setInitialApiUsersObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchUsers();
  }, [trigger]);

  return (
    <>
      {showManageUsers && (
        <ManageUsers
          showManageUsers={showManageUsers}
          setShowManageUsers={setShowManageUsers}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          setParentTrigger={setTrigger}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des utilisateurs
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                size="sm"
                onClick={() => {
                  setShowManageUsers(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                Ajouter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiUsersObjects.length > 0 && (
        <TableBackUsers
          context="admins"
          initialApiUsersObjects={initialApiUsersObjects}
          setShowManageUsers={setShowManageUsers}
          showManageUsers={showManageUsers}
          setSelectedObject={setSelectedObject}
        />
      )}
    </>
  );
};

export default IndexUsers;
