import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import esLocale from '@fullcalendar/core/locales/fr';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import * as Moment from 'moment';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { IndexPrestationsStatus } from '../../../../services/PrestationStatusService';
import { IndexPrestationsType } from '../../../../services/PrestationTypeService';

const Calendar = ({ prestations }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [changeViewFilter, setChangeViewFilter] = useState('Changer de vue');
  const [changeColorFilter, setChangeColorFilter] = useState(
    'Choisir les couleurs'
  );
  const [types, setTypes] = useState([]);
  const [status, setStatus] = useState([]);
  const navigate = useNavigate();
  const handleEventClick = object => {
    navigate(`/prestation/${object.event.id}`);
  };
  useEffect(() => {
    const fetchTypes = async () => {
      const responseTypes = await IndexPrestationsType();
      if (responseTypes.success === true) {
        setTypes(responseTypes.data);
      }
    };
    const fetchStatus = async () => {
      const responseStatus = await IndexPrestationsStatus();
      if (responseStatus.success === true) {
        setStatus(responseStatus.data);
      }
    };
    fetchTypes();
    fetchStatus();
  }, []);
  const eventTimeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    second: false,
    meridiem: false
  };

  const viewName = [
    'Affichage par mois',
    'Affichage semaine',
    'Affichage journée'
  ];
  const colorName = ['Couleurs par type', 'Couleurs par statut'];
  const handleFilter = filter => {
    setChangeViewFilter(filter);
    switch (filter) {
      case 'Affichage par mois':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'month');
        break;
      case 'Affichage semaine':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'week');
        break;
      case 'Affichage journée':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'day');
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
        localStorage.setItem('selectedTempViewAVS', 'year');
    }
  };

  const handleColor = color => {
    setChangeColorFilter(color);
    switch (color) {
      case 'Couleurs par type':
        localStorage.setItem('selectedColorViewAVS', 'type');
        break;
      case 'Couleurs par statut':
        localStorage.setItem('selectedColorViewAVS', 'status');
        break;
    }
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  function renderEventContent(eventInfo) {
    let icon = eventInfo.event.extendedProps.icon;
    let title = eventInfo.event.title;
    return (
      <div className="fc-event-title fc-sticky">
        {icon} {title}
      </div>
    );
  }

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
    let api = calendarRef.current.getApi();
    if (localStorage.getItem('selectedTempViewAVS')) {
      switch (localStorage.getItem('selectedTempViewAVS')) {
        case 'month':
          api.changeView('dayGridMonth');
          setTitle(api.getCurrentData().viewTitle);
          break;
        case 'week':
          api.changeView('timeGridWeek');
          setTitle(api.getCurrentData().viewTitle);
          break;
        case 'day':
          api.changeView('timeGridDay');
          setTitle(api.getCurrentData().viewTitle);
          break;
        default:
          api.changeView('listYear');
          setTitle(api.getCurrentData().viewTitle);
      }
    }
  }, []);

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Précédent</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    let selectedDate = '';
                    if (
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridDay' ||
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridWeek'
                    ) {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .local()
                        .format('YYYY-MM-DD');
                    } else {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                    }
                    localStorage.setItem('selectedDateAVS', selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Suivant</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    let selectedDate = '';
                    if (
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridDay' ||
                      calendarApi?.getCurrentData()?.viewApi?.type ===
                        'timeGridWeek'
                    ) {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .local()
                        .format('YYYY-MM-DD');
                    } else {
                      selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                    }
                    localStorage.setItem('selectedDateAVS', selectedDate);
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col className="d-flex justify-content-start order-md-2 ms-3">
              <Button
                className="me-2"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
                onClick={() => {
                  calendarApi.today();
                  const selectedDate = Moment(
                    calendarApi.getCurrentData().currentDate
                  )
                    .endOf('month')
                    .local()
                    .format('YYYY-MM-DD');
                  localStorage.setItem('selectedDateAVS', selectedDate);
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Aujourd'hui
              </Button>
            </Col>

            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={changeViewFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
              <DropdownFilter
                className="me-2"
                filters={colorName}
                currentFilter={changeColorFilter}
                handleFilter={handleColor}
                icon="sort"
                right
              />
            </Col>
          </Row>
          {(!localStorage.getItem('selectedColorViewAVS') ||
            localStorage.getItem('selectedColorViewAVS') === 'type') && (
            <Row>
              {types.length > 0 && (
                <Col xs={12} className="mt-3 mb-2">
                  {types.map((item, index) => (
                    <span
                      className="me-2"
                      style={{ fontSize: '10px' }}
                      key={index}
                    >
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: item.color,
                          height: '10px',
                          width: '10px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      {item.name}
                    </span>
                  ))}
                </Col>
              )}
            </Row>
          )}
          {localStorage.getItem('selectedColorViewAVS') &&
            localStorage.getItem('selectedColorViewAVS') === 'status' && (
              <Row>
                {status.length > 0 && (
                  <Col xs={12} className="mt-3 mb-2">
                    {status.map((item, index) => (
                      <span
                        className="me-2"
                        style={{ fontSize: '10px' }}
                        key={index}
                      >
                        <span
                          className="rounded-circle d-inline-block me-1"
                          style={{
                            backgroundColor: item.color,
                            height: '10px',
                            width: '10px',
                            position: 'relative',
                            top: '1px'
                          }}
                        ></span>
                        {item.name}
                      </span>
                    ))}
                  </Col>
                )}
              </Row>
            )}
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            eventOrder={['start', 'time']}
            eventContent={renderEventContent}
            ref={calendarRef}
            locale={esLocale}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={10}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={1000}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={prestations}
            initialDate={
              localStorage.getItem('selectedDateAVS')
                ? new Date(localStorage.getItem('selectedDateAVS'))
                : new Date()
            } // récupération du start depuis localstorage
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Calendar;

Calendar.propTypes = {
  prestations: PropTypes.array
};
