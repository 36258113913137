import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'resources';

export async function IndexResources() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowResources(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutResources(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostResources(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteResources(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
