import { Route, Routes } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router';
import is from 'is_js';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import AppContext from 'context/Context';

import AuthSimpleLayoutAvs from '../components/avs/layout/AuthSimpleLayout';
import LoginAvs from '../components/avs/pages/authentication/Login';
import ForgetPasswordAvs from '../components/avs/pages/authentication/ForgetPassword';
import PasswordResetAvs from '../components/avs/pages/authentication/PasswordReset';
import AdminLayout from '../components/avs/layout/AdminLayout';
import IndexService from '../components/avs/service';
import IndexUsers from '../components/avs/users';
import ManageUsers from '../components/avs/users/manage';
import IndexMediaLibrary from '../components/avs/media-library';
import CreateService from '../components/avs/service/CreateService';
import ManageService from '../components/avs/service/Manage';
import IndexWaiters from '../components/avs/waiters';
import IndexZoneKitchenProductionSheet from '../components/avs/service/components/IndexZoneKitchenProductionSheet';
import IndexProduct from '../components/avs/products';
import ListServices from '../components/avs/service/calendar/ListServices';
import WaiterLayout from '../components/avs/layout/WaiterLayout';
import ManageProduct from '../components/avs/products/Manage';
import LogisticSheet from '../components/avs/benefits/LogisticSheet';
import IndexResources from '../components/avs/resources';
import IndexVehicle from '../components/avs/vehicles';
import IndexCustomers from '../components/avs/customers';
import IndexIngredients from '../components/avs/ingredients';
import IndexProductsBatches from '../components/avs/products-lots';
import ManageInfoPrestaButler from '../components/avs/waiters/calendar/Show';
import IndexLogs from '../components/avs/logs';
import IndexEmailLogs from '../components/avs/emaillogs';
import Comptabilite from '../components/avs/analytics/comptabilite';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        {/* ROUTES AVS */}
        <Route element={<AuthSimpleLayoutAvs />}>
          <Route path="/" element={<LoginAvs />} />
          <Route path="/login" element={<LoginAvs />} />
          <Route path="forgot-password" element={<ForgetPasswordAvs />} />
          <Route path="reset-password" element={<PasswordResetAvs />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/calendrier" element={<IndexService />} />
          <Route
            path="/calendrier/archives"
            element={<IndexService isArchive={true} />}
          />
          <Route path="/utilisateurs" element={<IndexUsers />} />
          <Route path="/utilisateur/ajouter" element={<ManageUsers />} />
          <Route path="/mediatheque" element={<IndexMediaLibrary />} />
          <Route path="prestation/ajouter" element={<CreateService />} />
          <Route path="prestation" element={<ManageService />} />
          <Route path="prestation/:id" element={<ManageService />} />
          <Route path="lots/produits" element={<IndexProductsBatches />} />
          <Route path="produits" element={<IndexProduct />} />
          <Route path="produits/:id/modifier" element={<ManageProduct />} />

          <Route path="/prestations" element={<ListServices />} />
          <Route
            path="/productions/:id"
            element={<IndexZoneKitchenProductionSheet />}
          />
          <Route path="/ressources" element={<IndexResources />} />
          <Route path="/vehicules" element={<IndexVehicle />} />
          <Route path="/clients" element={<IndexCustomers />} />
          <Route path="/ingredients" element={<IndexIngredients />} />
          <Route path="/analytics/comptabilite" element={<Comptabilite />} />
          <Route path="/historique-actions" element={<IndexLogs />} />
          <Route path="/historique-emails" element={<IndexEmailLogs />} />
        </Route>

        <Route element={<WaiterLayout />}>
          <Route path="/maitre-d-hotel" element={<IndexWaiters />} />
          <Route path="/logistic-sheet" element={<LogisticSheet />} />
          <Route
            path="/maitre-d-hotel/prestation/:id"
            element={<ManageInfoPrestaButler />}
          />
        </Route>

        {/* FIN ROUTES AVS */}
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
