import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexVehicles } from '../../../services/VehiclesService';
import TableBackVehicles from './table';
import ManageCanvaVehicles from './ManageCanvaVehicles';
import DeleteCanvaVehicles from './DeleteCanvaVehicles';

const IndexVehicle = () => {
  const [showDelete, setShowDelete] = useState(false); // ouvre et ferme le canva pour supprimer un véhicule
  const [showVehicle, setShowVehicle] = useState(false); // ouvre et ferme le canva pour modifier ou créer un véhicule
  const [selectedObject, setSelectedObject] = useState({}); // récupère les véhicule séléctionné dans le tableau
  const [initialApiVehicles, setInitialApiVehicles] = useState([]); // tous les véhiule de la DB
  const [trigger, setTrigger] = useState(Date.now());

  useEffect(() => {
    const fetchVehicles = async () => {
      const responseVehicles = await IndexVehicles();
      if (responseVehicles.success === true) {
        setInitialApiVehicles(responseVehicles.data);
      } else {
        toast('Une erreur est survenue');
      }
    };
    fetchVehicles();
  }, [trigger]);

  return (
    <div>
      {/* canva pour supprimer*/}
      {showDelete && (
        <DeleteCanvaVehicles
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          selectedObject={selectedObject}
          setShowVehicle={setShowVehicle}
          setParentTrigger={setTrigger}
        />
      )}
      {/* canva pour créer et modifier un véhicule*/}
      {showVehicle && (
        <ManageCanvaVehicles
          showVehicle={showVehicle}
          setShowVehicle={setShowVehicle}
          selectedObject={selectedObject}
          setParentTrigger={setTrigger}
          setShowDelete={setShowDelete}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                Gestion des véhicules
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setSelectedObject({});
                  setShowVehicle(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un véhicule
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialApiVehicles && initialApiVehicles.length > 0 && (
        <TableBackVehicles
          initialApiVehicles={initialApiVehicles}
          setSelectedObject={setSelectedObject}
          showVehicle={showVehicle}
          setShowVehicle={setShowVehicle}
        />
      )}
    </div>
  );
};

export default IndexVehicle;
