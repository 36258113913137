import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'prestations/types';

export async function IndexPrestationsType() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowPrestationType(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutPrestationType(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostPrestationType(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeletePrestationType(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
