import React, { useEffect, useState } from 'react';
import CalendarWaiters from './calendar/CalendarWaiters';
import {
  Card,
  Col,
  Row,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IndexPrestation } from '../../../services/PrestationService';
import { toast } from 'react-toastify';
import ListWaiters from './calendar/ListWaiters';

const IndexWaiters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [eventsDisplayMode, setEventsDisplayMode] = useState('calendar');
  const [prestations, setPrestations] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});

  const isColorLight = hex => {
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const fetchPrestation = async () => {
      setIsLoading(true);
      const responsePrestation = await IndexPrestation();
      if (responsePrestation.success === true) {
        setIsLoading(false);
        let prestationsArray = [];

        responsePrestation.data?.map(presta => {
          prestationsArray.push({
            id: presta.id,
            title: presta.name?.replace('Prestation de', ' '),
            address: presta.address,
            start: `${presta.date}`,
            end: `${presta.date}`,
            time: presta.time,
            typeName: presta.type?.name,
            originalType: presta.type,
            guest_count: presta.guest_count,
            location_lat: presta.latitude,
            location_lng: presta.longitude,
            type: presta.type?.name ? presta.type?.name : '',
            backgroundColor: presta.type ? presta.type.color : '#ccf2e1',
            textColor: presta.type
              ? isColorLight(presta.type.color)
                ? '#000000'
                : '#FFFFFF'
              : '#FFFFFF'
          });
          setPrestations(prestationsArray);
        });
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };
    if (localStorage.getItem('avsEventsDisplayMode')) {
      setEventsDisplayMode(localStorage.getItem('avsEventsDisplayMode'));
    } else {
      localStorage.setItem('calendar', 'eventsDisplayMode');
    }
    fetchPrestation();
  }, [trigger]);
  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-sm-between align-items-center">
            <Col lg={3}>
              <h3 className="text-primary admin-title mb-0">
                {eventsDisplayMode === 'list'
                  ? 'Liste des prestations'
                  : 'Calendrier'}
              </h3>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2 mt-2"
                onClick={() => {
                  setTrigger(Date.now());
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M12 4C9.25144 4 6.82508 5.38626 5.38443 7.5H8V9.5H2V3.5H4V5.99936C5.82381 3.57166 8.72764 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4ZM4 12C4 16.4183 7.58172 20 12 20C14.7486 20 17.1749 18.6137 18.6156 16.5H16V14.5H22V20.5H20V18.0006C18.1762 20.4283 15.2724 22 12 22C6.47715 22 2 17.5228 2 12H4Z"
                    fill="#4d5969"
                  />
                </svg>
                Rafraîchir
              </Button>
            </Col>

            <Col lg={9} className="d-flex justify-content-end">
              <div
                className="px-0 theme-control-toggle float-end pe-2 ps-2"
                onClick={() => {
                  setEventsDisplayMode(
                    eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                  );
                  localStorage.setItem(
                    'avsEventsDisplayMode',
                    eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                  );
                }}
              >
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="hi">
                      Affichage en mode{' '}
                      {eventsDisplayMode === 'list' ? 'calendrier' : 'liste'}
                    </Tooltip>
                  }
                >
                  <div className="theme-control-toggle-label">
                    <FontAwesomeIcon
                      icon={
                        eventsDisplayMode === 'list' ? 'calendar-alt' : 'list'
                      }
                      transform="shrink-7"
                      className="fs-4"
                      style={{ padding: '3px' }}
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/*  --------   Calendrier des maitres d'hotels ------------    */}
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {!isLoading && eventsDisplayMode === 'calendar' && (
        <CalendarWaiters
          prestation={prestations}
          setSelectedObject={setSelectedObject}
          selectedObject={selectedObject}
        />
      )}

      {!isLoading && eventsDisplayMode === 'list' && (
        <>
          {prestations.length > 0 && (
            <ListWaiters
              prestations={prestations}
              setSelectedObject={setSelectedObject}
              selectedObject={selectedObject}
            />
          )}
        </>
      )}
    </>
  );
};

export default IndexWaiters;
