import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import * as Moment from 'moment/moment';
import {
  ExportProductionSheet,
  ProductionSheet
} from '../../../../services/PrestationService';
import { toast } from 'react-toastify';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import IconAlert from '../../../common/IconAlert';
import { useParams } from 'react-router';
import {
  IndexProductionZone,
  ShowProductionZone
} from '../../../../services/ProductionZoneService';

const IndexZoneKitchenProductionSheet = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  //const [days, setDays] = useState([]);
  const [zone, setZone] = useState({});
  const [zones, setZones] = useState([]);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(false);
  const [dates, setdates] = useState({
    start: Moment().toDate(),
    end: Moment().toDate()
  });

  useEffect(() => {
    const fetchProductionZone = async () => {
      const responseProductionZone = await ShowProductionZone(id);
      if (responseProductionZone.success === true) {
        setZone(responseProductionZone.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchProdSheet = async () => {
      let start = Moment().format('YYYY-MM-DD');
      let end = Moment().format('YYYY-MM-DD');
      if (dates.start) {
        start = Moment(dates.start).format('YYYY-MM-DD');
        if (!dates.end) {
          end = Moment(dates.start).format('YYYY-MM-DD');
        }
      }
      if (dates.end) {
        end = Moment(dates.end).format('YYYY-MM-DD');
      }
      const responseProdSheet = await ProductionSheet(start, end, id);
      if (responseProdSheet.success === true) {
        responseProdSheet.data.map(day => {
          day.data = JSON.parse(day.data);
          if (day.data.products !== undefined && day.data.products !== null) {
            day.data.products = Object.values(day.data.products);
            day.data.products.map(product => {
              product.prestations = Object.values(product.prestations);
              product.products = Object.values(product.products);
              if (product.products.length > 0) {
                product.products.map(productBatch => {
                  if (
                    productBatch.ingredients !== undefined &&
                    productBatch.ingredients !== null
                  ) {
                    productBatch.ingredients = Object.values(
                      productBatch.ingredients
                    );
                  }
                });
              }
            });
          }
        });
        //setDays(responseProdSheet.data);
      } else {
        toast('Une erreur est survennu, veuillez réessayer plus tard');
      }
    };

    const fetchAllProductionZones = async () => {
      const responseProductionZones = await IndexProductionZone();
      if (responseProductionZones.success === true) {
        setZones(responseProductionZones.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchAllProductionZones();
    fetchProductionZone();
    fetchProdSheet();
  }, [dates, id]);

  const exportSheets = async () => {
    setExportIsLoading(true);
    let start = Moment().format('YYYY-MM-DD');
    let end = Moment().format('YYYY-MM-DD');
    if (dates.start) {
      start = Moment(dates.start).format('YYYY-MM-DD');
      if (!dates.end) {
        end = Moment(dates.start).format('YYYY-MM-DD');
      }
    }
    if (dates.end) {
      end = Moment(dates.end).format('YYYY-MM-DD');
    }
    const responseExport = await ExportProductionSheet(start, end, id);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-0">
      <Form.Control
        color="text-primary"
        placeholder="Choisir une date"
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {dates.start && dates.end && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setdates({
              ...dates,
              start: '',
              end: ''
            });
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row className="">
            <Col lg={6}>
              <h3 className="mb-0">
                Fiche de production
                <span className={'fw-bold'}> {zone.name}</span>
              </h3>
            </Col>

            <Col lg={6} className={'d-flex justify-content-end'}>
              <DropdownButton
                as={ButtonGroup}
                variant="falcon-default"
                size="sm"
                style={{ position: 'static', zIndex: '1' }}
                title={
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path
                        d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                        fill="rgba(75,74,77,1)"
                      />
                    </svg>
                    Changer de zone
                  </>
                }
                className="me-2"
              >
                {zones.map((zone, index) => (
                  <Dropdown.Item
                    eventKey="1"
                    key={index}
                    onClick={() => {
                      navigate(`/productions/${zone.id}`);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{
                        marginBottom: '2px',
                        marginRight: '2px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                    </svg>
                    {zone.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <Button
                variant="light"
                className={'me-3'}
                size="sm"
                onClick={() => navigate('/calendrier')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                    fill="rgba(93,110,130,1)"
                  />
                </svg>
                Retour
              </Button>
              {/*<PrintComponentProduct dates={dates} />*/}
            </Col>
          </Row>
          {exportIsLoading === true ? (
            <Row>
              <Col xs={12} className="mt-3">
                <Spinner animation="border" role="status" size="xs">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          ) : (
            <Row>
              {exportUrl && (
                <Col xs={12}>
                  <IconAlert
                    variant="success"
                    dismissible
                    onClose={() => setExportUrl('')}
                    className="mt-3"
                  >
                    <p className="mb-0">
                      Export PDF disponible :{' '}
                      <a
                        onClick={() => {
                          setExportUrl('');
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={exportUrl}
                      >
                        cliquez ici pour le télécharger
                      </a>
                    </p>
                  </IconAlert>
                </Col>
              )}
              <Form>
                <Col xs={12} className="mt-3">
                  <Form.Label className="mt-3">
                    Sélectionner les dates concernées
                  </Form.Label>
                  <Row className="align-items-center">
                    <Col xs={9}>
                      <DatePicker
                        className="form-control"
                        onChange={dates => {
                          const [start, end] = dates;
                          setdates({
                            ...dates,
                            start: start,
                            end: end
                          });
                        }}
                        startDate={dates.start}
                        endDate={dates.end}
                        minDate={Moment().toDate()}
                        selectsRange
                        dateFormat="dd/MM/yy"
                        customInput={<DatePickerInput />}
                        locale="fr"
                      />
                    </Col>
                    <Col xs={3} className="ps-0">
                      <Button
                        className="me-2"
                        variant="primary"
                        size="md"
                        icon={faFileExcel}
                        transform="shrink-3"
                        onClick={() => {
                          exportSheets();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            position: 'relative',
                            top: '-1px',
                            marginRight: '4px'
                          }}
                        >
                          <path
                            d="M17 2C17.5523 2 18 2.44772 18 3V7H21C21.5523 7 22 7.44772 22 8V18C22 18.5523 21.5523 19 21 19H18V21C18 21.5523 17.5523 22 17 22H7C6.44772 22 6 21.5523 6 21V19H3C2.44772 19 2 18.5523 2 18V8C2 7.44772 2.44772 7 3 7H6V3C6 2.44772 6.44772 2 7 2H17ZM16 17H8V20H16V17ZM20 9H4V17H6V16C6 15.4477 6.44772 15 7 15H17C17.5523 15 18 15.4477 18 16V17H20V9ZM8 10V12H5V10H8ZM16 4H8V7H16V4Z"
                            fill="#FFFFFF"
                          ></path>
                        </svg>
                        Imprimer
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Row>
          )}
        </Card.Header>
      </Card>

      {/*{days && dates && (
        <>
          {days &&
            days.map((day, index) => (
              <div key={index}>
                <ArraySheetProduct
                  day={day}
                  setDays={setDays}
                  dates={dates}
                  zone={zone}
                />
              </div>
            ))}
        </>
      )}*/}
    </>
  );
};

export default IndexZoneKitchenProductionSheet;

IndexZoneKitchenProductionSheet.propTypes = {
  context: PropTypes.string
};
