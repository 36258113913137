import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'customers';

export async function IndexCustomer(light = false) {
  return modelService.IndexModel(`${model}?light=${light}`);
}

export async function ShowCustomer(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function DeleteCustomer(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function SyncCustomer() {
  return modelService.PutModel(`${model}/customer-sync`);
}

export async function PutCustomer(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostCustomer(formData) {
  return modelService.PostModel(model, formData);
}
