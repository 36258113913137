import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'quotes';

export async function PostQuote(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutQuote(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteQuote(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function SendQuote(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function SignQuote(id, formData) {
  return modelService.PostModel(
    `${model}/${id}/create-sign-procedure`,
    formData
  );
}

export async function CancelSign(id) {
  return modelService.PostModel(`${model}/${id}/cancel-sign-procedure`);
}
