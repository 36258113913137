import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import {
  PostProductionZone,
  PutProductionZone
} from '../../../../services/ProductionZoneService';

const ManageProductZone = ({
  showManagezones,
  setShowManagezones,
  selectedZone,
  setTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    name: ''
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedZone && selectedZone.id) {
      response = await PutProductionZone(selectedZone.id, formData);
    } else {
      response = await PostProductionZone(formData);
    }

    if (response.success === true) {
      toast(`Zone enregistrée`);
      setIsLoadingSubmit(true);
      setShowManagezones(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setTrigger(Date.now());
    setErrorForm(response.validator);
  };

  useEffect(() => {
    if (selectedZone && selectedZone.id) {
      setFormData({
        ...formData,
        name: selectedZone.name
      });
    }
  }, []);
  return (
    <>
      <Offcanvas
        show={showManagezones}
        onHide={() => {
          setShowManagezones(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowManagezones(false);
          }}
        >
          <Offcanvas.Title>
            {selectedZone && selectedZone.id
              ? 'Modifier la zone'
              : `Ajouter une zone`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="name">
                  <Form.Label>Nom de la zone :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Froid, chaud, boulangerie..."
                    value={formData.name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Button
                  disabled={isLoadingSubmit}
                  className="mt-3"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  variant="primary"
                >
                  {selectedZone && selectedZone.id
                    ? 'Enregistrer la modification'
                    : 'Enregistrer la zone'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageProductZone.propTypes = {
  selectedZone: PropTypes.object,
  setShowManagezones: PropTypes.func,
  showManagezones: PropTypes.bool,
  setTrigger: PropTypes.func
};

export default ManageProductZone;
