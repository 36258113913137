import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'prestations/sections';

export async function PostPrestationSection(formData) {
  return modelService.PostModel(model, formData);
}

export async function PostModelSection(formData) {
  return modelService.PostModel('prestations/section-models', formData);
}

export async function IndexModelSection() {
  return modelService.IndexModel('prestations/section-models');
}

export async function DeleteModelSection(id) {
  return modelService.DeleteModel(`prestations/section-models/${id}`);
}

export async function PutPrestationSection(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeletePrestationSection(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
