import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { SendButlerNotification } from '../../../../services/PrestationService';
import FormAlert from '../../commons/FormAlert';
import FileUploader from '../../users/fileUpload/FileUploader';
import Select from 'react-select';

const ButlersNotification = ({
  prestation,
  setParentTrigger,
  allButtlersLabel
}) => {
  const [showCanva, setShowCanva] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [errorForm, setErrorForm] = useState(false);
  const [notificationForm, setNotificationForm] = useState({
    note: '',
    attachments: [],
    butlers: [],
    emails: ''
  });
  useEffect(() => {
    setNotificationForm({
      ...notificationForm,
      butlers: prestation.butlers.map(b => {
        return {
          label: b.firstname + ' ' + b.lastname,
          value: b.id
        };
      })
    });
  }, [showCanva]);

  useEffect(() => {
    //
  }, [trigger]);
  const sendConfirmation = async () => {
    setIsLoading(true);
    setErrorForm(false);
    const response = await SendButlerNotification(prestation.id, {
      ...notificationForm,
      butlers: notificationForm.butlers.map(b => {
        return b.value;
      })
    });
    setErrorForm(response.validator);
    if (response.success === true) {
      setTrigger(Date.now());
      setIsLoading(false);
      toast("La notification a bien été envoyée aux maitres d'hôtel");
      setShowCanva(false);
      setNotificationForm({
        note: '',
        attachments: [],
        butlers: prestation.butlers.map(b => {
          return {
            label: b.firstname + ' ' + b.lastname,
            value: b.id
          };
        }),
        emails: ''
      });
      setParentTrigger(Date.now());
    } else {
      setIsLoading(false);
      setTrigger(Date.now());
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="me-0"
        onClick={() => {
          setShowCanva(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"
            fill="rgba(75,74,77,1)"
          />
        </svg>
        Envoyer une notification
      </Button>

      <Offcanvas
        show={showCanva}
        onHide={() => {
          setShowCanva(false);
        }}
        placement={'end'}
        style={{ width: '920px' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Envoyer une notification </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Col xs={12} className="mt-0">
            <span className="d-block mb-3">
              <b>
                Les destinataires vont recevoir un email contenant la ou les
                pièces jointes et la note ci-dessous :
              </b>
            </span>
            <Form.Group>
              <Form.Label>
                Sélectionner des maitres d'hôtels destinataires
              </Form.Label>
              <Select
                closeMenuOnSelect={true}
                classNamePrefix="react-select"
                placeholder="Choisir ..."
                isMulti
                name="butlers"
                options={allButtlersLabel.filter(
                  b => prestation.butlers.includes(b.value) !== false
                )}
                onChange={value => {
                  setNotificationForm({
                    ...notificationForm,
                    butlers: value
                  });
                }}
                value={notificationForm.butlers}
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>
                Attacher des fichiers <small>facultatif</small>
              </Form.Label>
              {notificationForm.attachments && (
                <>
                  {notificationForm.attachments.map((file, index) => (
                    <Row key={index} className="align-items-center">
                      <Col xs={8} className="pt-2">
                        <FileUploader
                          pathToUpload="surveys/notifications"
                          formData={notificationForm}
                          setFormData={setNotificationForm}
                          format="any"
                          databaseField="file_url"
                          databaseFieldArray="attachments"
                          databaseFieldArrayIndex={index}
                          cropperEnabled={false}
                          progressBar={true}
                        />
                      </Col>
                      <Col xs={4} className="pt-2 px-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          className="cursor-pointer"
                          onClick={() => {
                            const newAttachments =
                              notificationForm.attachments.filter(
                                (attachment, i) => i !== index
                              );
                            setNotificationForm({
                              ...notificationForm,
                              attachments: newAttachments
                            });
                          }}
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                            fill="#e52f12"
                          />
                        </svg>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
              <Row>
                <Col xs={12} className="pt-2">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="mt-2 mb-3"
                    onClick={() => {
                      let tmpForm = notificationForm;
                      if (!tmpForm.attachments) {
                        tmpForm.attachments = [];
                      }
                      tmpForm.attachments.push({
                        file_url: ''
                      });
                      setNotificationForm(tmpForm);
                      setTrigger(Date.now());
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{
                        marginBottom: '2px',
                        marginRight: '2px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        fill="rgba(77,89,105,1)"
                      />
                    </svg>{' '}
                    Ajouter une pièce jointe
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-3">
            <Form.Group controlId="served_countries">
              <Form.Label>Ajouter une note</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                name="note"
                value={notificationForm.note || ''}
                onChange={e => {
                  setNotificationForm({
                    ...notificationForm,
                    note: e.target.value
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3 mt-3">
            <Form.Group controlId="name">
              <Form.Label>
                Destinataires en copie{' '}
                <small>
                  séparer les adresses par des <b>virgules</b>
                </small>{' '}
                :
              </Form.Label>
              <Form.Control
                type="text"
                name="emails"
                placeholder="email1@email.com,email2@email.com..."
                value={notificationForm.emails || ''}
                onChange={e => {
                  setNotificationForm({
                    ...notificationForm,
                    emails: e.target.value
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="12">
            <Button
              size="sm"
              variant="primary"
              className="me-0 admin-primary"
              disabled={isLoading}
              onClick={() => {
                sendConfirmation();
              }}
            >
              Envoyer
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="admin-primary ms-2"
              onClick={() => {
                setShowCanva(false);
              }}
            >
              Annuler
            </Button>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

// proptypes :
ButlersNotification.propTypes = {
  prestation: PropTypes.object.isRequired,
  setParentTrigger: PropTypes.func.isRequired,
  allButtlersLabel: PropTypes.array.isRequired
};
export default ButlersNotification;
