import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import {
  PostProduct,
  ShowProduct,
  DeleteProduct,
  PutProduct
} from '../../../services/ProductService';
import { toast } from 'react-toastify';
import { IndexSeasons } from '../../../services/ProductSeasonsService';
import { IndexProductCategories } from '../../../services/ProductCategoriesService';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';
import ManageProductCategory from './categories/ManageProductCategory';
import ManageProductSeasons from './seasons/ManageProductSeasons';
import ManageResource from '../resources/ManageResource';
import { IndexResources } from '../../../services/RessourceService';
import ManageIngredient from '../ingredients/Manage';
import { IndexIngredient } from '../../../services/IngredientsService';
import { IndexProductionZone } from '../../../services/ProductionZoneService';

const ManageProduct = ({
  showCanvaProduct,
  setShowCanvaProduct,
  selectedObject,
  setParentTrigger,
  setFormParentProductBatches,
  formParentProductBatches
}) => {
  const [labelSeasonsProduct, setLabelSeasonsProduct] = useState([]);
  const [labelCategory, setLabelCategory] = useState([]);
  const [labelIngredients, setLabelIngredients] = useState([]);
  const [labelZones, setLabelZones] = useState([]);
  const [labelResources, setLabelResources] = useState([]); // stock tout les label et value pour le Select Ressources
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  /* const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);*/
  const [showManageCategories, setShowManageCategories] = useState(false); // ouverture et fermuture du Manage des catégories
  const [showManageSeasons, setShowManageSeasons] = useState(false); // ouverture et fermuture du Manage de seasons
  const [showResource, setShowResource] = useState(false); // ouverture et fermuture du Manage des ressources
  const [showCanvaIngredient, setShowCanvaIngredient] = useState(false); //ouverture et fermeture du manage des ingrédients
  const [trigger, setTrigger] = useState(Date.now());
  const [formData, setFormData] = useState({
    booklet_name: '', // name plaquette
    booklet_description: '', // description plaquette
    invoice_name: '', // name devis
    production_sheet_name: '', // fiche de production cuisine
    default_price: '', // prix
    default_unit: '', // unité de mesure (kg, part... )
    default_vat: 20, // tranche TVA
    default_zone_id: '', // zone par default froide ou chaude ( cold hot )
    reference: '', // la référence du produit
    categories: [], // categorie du produit entrée plat dessert
    ingredients: [], // tableau d'objet d'ingredients
    seasons: [], // la saison du poduit
    resources: []
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    let tmpFormData = { ...formData };
    if (tmpFormData.default_unit === 'pièce') {
      tmpFormData.default_unit = 'piece';
    }
    tmpFormData = {
      ...tmpFormData,
      seasons: tmpFormData.seasons.map(season => season.value),
      categories: tmpFormData.categories.map(category => category.value),
      ingredients: tmpFormData.ingredients.map(ing => {
        return {
          ingredient_id: ing.value,
          quantity: ing.quantity || 1
        };
      }),
      resources: tmpFormData.resources.map(res => res.value)
    };
    if (selectedObject && selectedObject.id) {
      response = await PutProduct(selectedObject.id, tmpFormData);
    } else {
      response = await PostProduct(tmpFormData);
    }

    if (response.success === true) {
      if (formParentProductBatches) {
        setFormParentProductBatches({
          ...formParentProductBatches,
          products: [
            ...formParentProductBatches.products,
            {
              value: response.data.id,
              label: response.data.invoice_name
            }
          ]
        });
        setShowCanvaProduct(false);
        return;
      }

      toast('Produit enregistré');
      setTrigger(Date.now());
      setParentTrigger(Date.now());
      setShowCanvaProduct(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  const deleteProduct = async () => {
    const responseDeleteProduct = await DeleteProduct(selectedObject.id);
    if (responseDeleteProduct.success === true) {
      toast('Produit supprimé');
      setParentTrigger(Date.now());
      setShowCanvaProduct(false);
      setShowDelete(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  useEffect(() => {
    const fetchOneProduct = async () => {
      const responseOneProduct = await ShowProduct(selectedObject.id);
      if (responseOneProduct.success === true) {
        let dataProduct = responseOneProduct.data;
        /*--------  Ingrédients ----- */
        let ingredientsArray = [];
        responseOneProduct.data.ingredients.map(ingredient => {
          ingredientsArray.push({
            value: ingredient.id,
            label: ingredient.name,
            quantity: ingredient.pivot?.quantity
          });
        });
        /*--------  Categories ----- */
        let categoriesArray = [];
        responseOneProduct.data.categories.map(category => {
          categoriesArray.push({
            value: category.id,
            label: category.name
          });
        });
        /*--------  Seasons ----- */
        let seasonsArray = [];
        responseOneProduct.data.seasons.map(season => {
          seasonsArray.push({
            value: season.id,
            label: season.name
          });
        });
        /*--------  Ressources ----- */
        let resourceArray = [];
        responseOneProduct.data.resources.map(resource => {
          resourceArray.push({
            value: resource.id,
            label: resource.invoice_name
          });
        });
        setFormData({
          booklet_name: dataProduct.booklet_name,
          booklet_description: dataProduct.booklet_description,
          invoice_name: dataProduct.invoice_name,
          production_sheet_name: dataProduct.production_sheet_name,
          categories: categoriesArray,
          default_price: dataProduct.default_price,
          default_unit: dataProduct.default_unit,
          default_vat: dataProduct.default_vat,
          default_zone_id: dataProduct.default_zone_id,
          ingredients: ingredientsArray,
          reference: dataProduct.reference,
          seasons: seasonsArray,
          resources: resourceArray
        });
      }
    };

    const fetchAllProductSeasons = async () => {
      const responseProductSeasons = await IndexSeasons();
      if (responseProductSeasons.success === true) {
        let tmpSeasonsArray = [];
        responseProductSeasons.data.map(season => {
          tmpSeasonsArray.push({
            value: season.id,
            label: season.name
          });
        });

        setLabelSeasonsProduct(tmpSeasonsArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    const fetchAllIngredients = async () => {
      const responseIngredients = await IndexIngredient();
      if (responseIngredients.success === true) {
        let tmpIngredientsArray = [];
        responseIngredients.data.map(ing => {
          tmpIngredientsArray.push({
            value: ing.id,
            label: ing.name
          });
        });
        setLabelIngredients(tmpIngredientsArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    const fetchAllProductCategories = async () => {
      const responseProductCategories = await IndexProductCategories();
      if (responseProductCategories.success === true) {
        let tmpCategoriesArray = [];
        responseProductCategories.data.map(category => {
          tmpCategoriesArray.push({
            value: category.id,
            label: category.name
          });
        });
        setLabelCategory(tmpCategoriesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    const fetchAllRessources = async () => {
      const responseResource = await IndexResources();
      if (responseResource.success === true) {
        let tmpResourcesArray = [];
        responseResource.data.map(resource => {
          tmpResourcesArray.push({
            value: resource.id,
            label: resource.invoice_name
          });
        });
        setLabelResources(tmpResourcesArray);
      }
    };

    const fetchAllProductionZones = async () => {
      const responseProductionZones = await IndexProductionZone();
      if (responseProductionZones.success === true) {
        let tmpZonesArray = [];
        responseProductionZones.data.map(zone => {
          tmpZonesArray.push({
            value: zone.id,
            label: zone.name
          });
        });
        setLabelZones(tmpZonesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchAllIngredients();
    fetchAllRessources();
    fetchAllProductCategories();
    fetchAllProductSeasons();
    fetchAllProductionZones();

    if (selectedObject && selectedObject.id) {
      fetchOneProduct();
    }
  }, [selectedObject, trigger]);

  return (
    <>
      {/* ---- Manage de gestion des diffférentes catégories -----*/}
      {showManageCategories && (
        <ManageProductCategory
          showManageCategories={showManageCategories}
          setShowManageCategories={setShowManageCategories}
          setTrigger={setTrigger}
          parentFormData={formData}
          setParentFormData={setFormData}
          formParentProduct={formData}
          setFormParentProduct={setFormData}
          setLabelCategory={setLabelCategory}
          trigger={trigger}
        />
      )}
      {/* ---- Manage de gestion des diffférents ingrédients -----*/}
      {showCanvaIngredient && (
        <ManageIngredient
          showCanvaIngredient={showCanvaIngredient}
          setShowCanvaIngredient={setShowCanvaIngredient}
          setParentTrigger={setTrigger}
          formParentProduct={formData}
          setFormParentProduct={setFormData}
          setLabelIngredients={setLabelIngredients}
          selectedObject={{}}
        />
      )}
      {/* ---- Manage de gestion des diffférentes saisons -----*/}
      {showManageSeasons && (
        <ManageProductSeasons
          showManageSeasons={showManageSeasons}
          setShowManageSeasons={setShowManageSeasons}
          formParentProduct={formData}
          setFormParentProduct={setFormData}
          setLabelSeasonsProduct={setLabelSeasonsProduct}
        />
      )}

      {/* ---- Manage de gestions des ressources ---*/}
      {showResource && (
        <ManageResource
          showResource={showResource}
          setShowResource={setShowResource}
          setGrandParentTrigger={setParentTrigger}
          setParentTrigger={setTrigger}
          selectedObject={{}}
          formParentProduct={formData}
          setFormParentProduct={setFormData}
          setLabelResources={setLabelResources}
        />
      )}

      {selectedObject && (
        <>
          {/* -------  OffCanva pour delete un product --------*/}
          {selectedObject && selectedObject.id && (
            <Offcanvas
              show={showDelete}
              onHide={() => {
                setShowDelete(false);
              }}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <p>
                  Supprimer le produit :{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    {selectedObject.booklet_name}{' '}
                  </span>{' '}
                  ?
                </p>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    deleteProduct();
                  }}
                >
                  Oui, supprimer le produit
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </>
      )}

      {showCanvaProduct && (
        <Offcanvas
          className="w-50"
          show={showCanvaProduct}
          onHide={() => {
            setShowCanvaProduct(false);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <h3 className="text-primary admin-title mb-0">
              {selectedObject && selectedObject.id
                ? 'Modifier le produit'
                : `Ajouter un produit`}
            </h3>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <Row>
                {/* ---- référence ----*/}
                <Col>
                  <Form.Group controlId="reference">
                    <Form.Label>Référence :</Form.Label>
                    <Form.Control
                      type="text"
                      name="reference"
                      placeholder="Choisir la référence"
                      value={formData.reference || ''}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {' '}
                  <Form.Group controlId="default_price">
                    <Form.Label>Prix :</Form.Label>
                    <Form.Control
                      type="number"
                      name="default_price"
                      placeholder="Choisir son prix"
                      value={formData.default_price || ''}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                {/* ---- tranche TVA ----*/}
                <Col>
                  {' '}
                  <Form.Group controlId="default_vat">
                    <Form.Label>Tranche TVA :</Form.Label>
                    <Form.Select
                      name="default_vat"
                      placeholder="Tranche TVA"
                      value={formData.default_vat || ''}
                      onChange={handleFieldChange}
                    >
                      {formData.default_vat === '5.50' ? (
                        <>
                          <option value="5.50">5,50%</option>
                          <option value="10">10%</option>
                          <option value="20">20%</option>
                        </>
                      ) : formData.default_vat === '10.00' ? (
                        <>
                          <option value="10">10%</option>
                          <option value="5.50">5.50%</option>
                          <option value="20">20%</option>
                        </>
                      ) : (
                        <>
                          <option value="20">20%</option>
                          <option value="5.50">5.50%</option>
                          <option value="10">10%</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {/* ---- zone par default ----*/}
              <Row className="mt-2">
                <Col lg="4">
                  <Form.Group controlId="default_zone_id">
                    <Form.Label>Zone par défaut :</Form.Label>
                    <Form.Select
                      value={formData.default_zone_id}
                      onChange={handleFieldChange}
                      aria-label="default_zone_id"
                      name="default_zone_id"
                    >
                      <option
                        disabled={formData.default_zone_id ? true : false}
                      >
                        Choisir ...
                      </option>
                      {labelZones.length > 0 && (
                        <Fragment>
                          {labelZones.map(zone => (
                            <option key={zone.value} value={zone.value}>
                              {zone.label}
                            </option>
                          ))}
                        </Fragment>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  {' '}
                  {/* ---- initié par default  ----- */}
                  <Form.Group controlId="default_unit">
                    <Form.Label>Unité :</Form.Label>
                    <Form.Select
                      value={formData.default_unit}
                      onChange={handleFieldChange}
                      className="form-select"
                      aria-label="default_unit"
                      name="default_unit"
                    >
                      <option disabled={formData.default_unit ? true : false}>
                        Choisir ...
                      </option>
                      <option value="piece">Pièce</option>
                      <option value="part">Part</option>
                      <option value="kilo">Kilo</option>
                      <option value="liter">Litre</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/* ---- saisons ----*/}
                <Col lg="4">
                  {' '}
                  <Form.Group controlId="seasons">
                    <Form.Label>
                      Saisons :
                      <a
                        onClick={() => {
                          setShowManageSeasons(true);
                        }}
                        className="cursor-pointer ms-2"
                      >
                        <small>Créer une saison</small>
                      </a>{' '}
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      isMulti
                      options={labelSeasonsProduct}
                      placeholder="Saison(s) du produit..."
                      name="seasons"
                      classNamePrefix="react-select"
                      onChange={value => {
                        setFormData({
                          ...formData,
                          seasons: value
                        });
                      }}
                      value={formData.seasons}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                {/* ----- categories ------*/}
                <Col lg="4">
                  {' '}
                  <Form.Group controlId="categories">
                    <Form.Label>
                      Catégories :
                      <a
                        onClick={() => setShowManageCategories(true)}
                        className="cursor-pointer ms-2"
                      >
                        <small>Créer une catégorie</small>
                      </a>
                    </Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={true}
                      options={labelCategory}
                      placeholder="Entrée, plat, dessert..."
                      name="categories"
                      classNamePrefix="react-select"
                      onChange={value => {
                        setFormData({
                          ...formData,
                          categories: value
                        });
                      }}
                      value={formData.categories}
                    ></Select>
                  </Form.Group>
                </Col>
                {/* ------ Ingredients -----*/}
                <Col lg="4">
                  {' '}
                  <Form.Group controlId="ingredients">
                    <Form.Label>
                      Ingredients :
                      <a
                        className="cursor-pointer ms-2"
                        onClick={() => {
                          setShowCanvaIngredient(true);
                        }}
                      >
                        <small>Créer un ingrédient</small>
                      </a>
                    </Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={true}
                      options={labelIngredients}
                      placeholder="Ingrédients.."
                      name="ingredients"
                      classNamePrefix="react-select"
                      value={formData.ingredients}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          ingredients: value
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                {/* ------ Ressources associé au produit -----*/}
                <Col lg="4">
                  {' '}
                  <Form.Group controlId="invoice_name">
                    <Form.Label>
                      Ressource
                      <a
                        className="cursor-pointer ms-2"
                        onClick={() => {
                          setShowResource(true);
                        }}
                      >
                        <small>Créer une ressource</small>
                      </a>
                    </Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={true}
                      options={labelResources}
                      placeholder="Pince..."
                      name="resources"
                      classNamePrefix="react-select"
                      value={formData.resources}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          resources: value
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>

              {formData.ingredients && formData.ingredients.length > 0 && (
                <Row className="mt-3">
                  <Col xs={12}>
                    <Table bordered className="admin-table mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Ingrédient</th>
                          <th scope="col">
                            Quantité <small>pour une unité - facultatif</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.ingredients.map((ingredient, index) => (
                          <tr key={index}>
                            <td>{ingredient.label}</td>
                            <td>
                              <Form.Control
                                type="number"
                                name="quantity"
                                placeholder="0,25"
                                value={ingredient.quantity}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    ingredients: formData.ingredients.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            quantity: e.target.value
                                          };
                                        }
                                        return item;
                                      }
                                    )
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}

              <Col
                style={{ borderTop: 'dashed 1px #d8e2ef' }}
                className="my-4"
              ></Col>
              <Col
                style={{ borderTop: 'dashed 1px #d8e2ef' }}
                className="my-4"
              ></Col>
              {/* ----- Démination plaquette ----- */}
              <Col>
                <Form.Group className="mt-2" controlId="booklet_name">
                  <Form.Label>Dénomination de la plaquette :</Form.Label>
                  <Form.Control
                    type="text"
                    name="booklet_name"
                    placeholder="Nom sur la plaquette"
                    value={formData.booklet_name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              {/* ----- Description plaquette ----- */}
              <Col>
                <Form.Group className="mt-2" controlId="booklet_description">
                  <Form.Label>Description de la plaquette :</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="booklet_description"
                    placeholder="Description sur la plaquette"
                    value={formData.booklet_description || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              {/* ----- Démination devis ----- */}
              <Col className="mt-3">
                <Form.Group className="mt-2" controlId="invoice_name">
                  <Form.Label>Dénomination du devis :</Form.Label>
                  <Form.Control
                    type="text"
                    name="invoice_name"
                    placeholder="Nom sur le devis"
                    value={formData.invoice_name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              {/* ----- Démination fiche cuisine ----- */}
              <Col className="mt-3">
                <Form.Group className="mt-2" controlId="production_sheet_name">
                  <Form.Label>
                    Dénomination de la fiche de production cuisine :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="production_sheet_name"
                    placeholder="Nom sur la fiche cuisine"
                    value={formData.production_sheet_name || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-3">
                <Button
                  /*  disabled={isLoadingSubmit}*/
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  size="sm"
                >
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  {selectedObject && selectedObject.id
                    ? ' Enregistrer les modifications'
                    : ' Enregistrer le nouveau produit'}
                </Button>
                {selectedObject && selectedObject.id && (
                  <Button
                    size="sm"
                    variant="danger"
                    className="me-0 float-end"
                    type="button"
                    onClick={() => {
                      setShowDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="14"
                      height="14"
                      style={{
                        marginBottom: '2px',
                        marginRight: '3px'
                      }}
                    >
                      <path
                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Supprimer
                  </Button>
                )}
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

ManageProduct.propTypes = {
  showCanvaProduct: PropTypes.bool,
  setShowCanvaProduct: PropTypes.func,
  selectedObject: PropTypes.object,
  initialApiProducts: PropTypes.array,
  setParentTrigger: PropTypes.func,
  setFormParentProductBatches: PropTypes.func,
  formParentProductBatches: PropTypes.object
};

export default ManageProduct;
