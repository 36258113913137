import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'billing-sheets';

export async function GenerateBillingSheetFile(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function SendBillingSheet(id, formData) {
  return modelService.PostModel(`${model}/${id}/send`, formData);
}

export async function DeleteBillingSheet(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
