const convertUnit = unit => {
  switch (unit) {
    case 'piece':
      return 'pièce';
    case 'part':
      return 'part';
    case 'kilo':
      return 'kilo';
    case 'liter':
      return 'litre';
  }
};

export default convertUnit;
