import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, Button } from 'react-bootstrap';
import CanvaNotes from '../../canvas-components/canvaNotes';
import ManageVehiclesFromService from './ManageVehiclesFromService';
import TableVehicles from './TableVehicles';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';

import { IndexVehicles } from '../../../../services/VehiclesService';

const ManageVehiclesService = ({
  prestation,
  setParentTrigger,
  vehiclesFromPresta
}) => {
  const [showAddVehicles, setShowAddVehicles] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [allVehiclesLabel, setAllVehiclesLabel] = useState([]); //récupère les véhicules
  const [formVehicles, setFormVehicles] = useState([]); //récupère la date et l'horaire d'intervention de chaque maitre d'hotel
  const [formDataVehicles, setFormDataVehicles] = useState({
    vehicles: [],
    context: 'vehicles'
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      // récupère tout les véhicules avec le label pour le select
      const responseVehicles = await IndexVehicles();
      if (responseVehicles.success === true) {
        let tmpVehicles = [];
        responseVehicles.data.map(vehicle => {
          tmpVehicles.push({
            value: vehicle.id,
            label: vehicle.name
          });
        });
        setAllVehiclesLabel(tmpVehicles);
      } else {
        toast('Une erreur est survenue');
      }
      setErrorForm(responseVehicles.validator);
    };

    fetchVehicles();
  }, []);

  return (
    <>
      {showAddVehicles && (
        <ManageVehiclesFromService
          showAddVehicles={showAddVehicles}
          setShowAddVehicles={setShowAddVehicles}
          prestation={prestation}
          allVehiclesLabel={allVehiclesLabel}
          formDataParentVehicles={formDataVehicles}
          setFormDataVehicles={setFormDataVehicles}
          setGrandParentTrigger={setParentTrigger}
          vehiclesFromPresta={vehiclesFromPresta}
        />
      )}
      <Card className="mt-3">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Col className="d-flex ">
                <h5 className="pt-1">Transport</h5>
              </Col>
            </Accordion.Header>
            <Accordion.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              <Row>
                <Col>
                  <p style={{ fontWeight: 500 }}>
                    Associer des véhicules à la prestation
                    <Button
                      size="sm"
                      variant="primary"
                      className="mt-0 mb-0 ms-3"
                      onClick={() => {
                        setShowAddVehicles(true);
                      }}
                    >
                      Gérer
                    </Button>
                  </p>
                </Col>
                <Col className="text-end">
                  <CanvaNotes
                    model={prestation}
                    noteableType={'prestation'}
                    setTrigger={setParentTrigger}
                    context={'vehicles'}
                  />
                </Col>
              </Row>
              <Row>
                {vehiclesFromPresta &&
                  vehiclesFromPresta.length > 0 &&
                  prestation && (
                    <Col>
                      <TableVehicles
                        vehiclesFromPresta={vehiclesFromPresta}
                        formVehicles={formVehicles}
                        setFormVehicles={setFormVehicles}
                      />
                    </Col>
                  )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </>
  );
};
ManageVehiclesService.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func,
  vehiclesFromPresta: PropTypes.array
};

export default ManageVehiclesService;
