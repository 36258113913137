import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'production-zones';

export async function IndexProductionZone() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowProductionZone(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutProductionZone(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostProductionZone(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeleteProductionZone(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
