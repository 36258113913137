import React, { useEffect, useState } from 'react';
import { Offcanvas, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ManageProductSeasons from './ManageProductSeasons';
import OffCanvaProductSeasonDelete from './OffCanvaProductSeasonsDelete';
import { IndexSeasons } from '../../../../services/ProductSeasonsService';
import * as Moment from 'moment';

const IndexProductSeasonsCanvas = ({
  showProductsSeasons,
  setShowProductsSeasons,
  setParentTrigger
}) => {
  const [seasons, setSeasons] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [selectedSeason, setSelectedSeason] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showManageSeasons, setShowManageSeasons] = useState(false);
  useEffect(() => {
    const fetchProductSeasons = async () => {
      const responseSeasons = await IndexSeasons();
      if (responseSeasons.success === true) {
        setSeasons(responseSeasons.data);
      }
    };
    fetchProductSeasons();
  }, [trigger]);

  return (
    <>
      {showDelete && (
        <OffCanvaProductSeasonDelete
          selectedSeason={selectedSeason}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          setTrigger={setTrigger}
          setGrandParentTrigger={setParentTrigger}
        />
      )}

      {showManageSeasons && (
        <ManageProductSeasons
          showManageSeasons={showManageSeasons}
          setShowManageSeasons={setShowManageSeasons}
          setTrigger={setTrigger}
          selectedSeason={selectedSeason}
        />
      )}

      <Offcanvas
        placement={'end'}
        show={showProductsSeasons}
        onHide={() => {
          setShowProductsSeasons(false);
        }}
        className={'w-50'}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Gestion des saisons
            <a
              onClick={() => {
                setSelectedSeason({});
                setShowManageSeasons(true);
              }}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>{' '}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {seasons && seasons.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <tbody>
                {seasons.map((season, index) => (
                  <tr key={index}>
                    <td>{season.name}</td>
                    <td className="text-center">
                      Du {Moment(season.start).local().format('DD/MM/YYYY')} au{' '}
                      {Moment(season.end).local().format('DD/MM/YYYY')}{' '}
                    </td>
                    <td className="text-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="hi">Modifier</Tooltip>}
                      >
                        <a
                          onClick={() => {
                            setSelectedSeason(season);
                            setShowManageSeasons(true);
                          }}
                          className="cursor-pointer me-3"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            style={{
                              marginBottom: '1px',
                              marginLeft: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                              fill="rgba(44,123,229,1)"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>

                      <span>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="hi">Supprimer</Tooltip>}
                        >
                          <a
                            href="#"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedSeason(season);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

IndexProductSeasonsCanvas.propTypes = {
  showProductsSeasons: PropTypes.bool,
  setShowProductsSeasons: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default IndexProductSeasonsCanvas;
