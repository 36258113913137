import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'prestations';

export async function IndexPrestation() {
  return modelService.IndexModel(`${model}`);
}

export async function IndexPrestationArchive() {
  return modelService.IndexModel(`${model}?withTrashed=true`);
}

export async function ShowPrestation(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PutPrestation(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DuplicatePrestation(id) {
  return modelService.PostModel(`${model}/${id}/duplicate`, {});
}

export async function UnarchivePrestation(id) {
  return modelService.PutModel(`${model}/unarchive/${id}`);
}

export async function PostPrestation(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeletePrestation(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function ExportPrestations() {
  return modelService.IndexModel(`${model}/export`);
}

export async function Analytics(start, end) {
  return modelService.PostModel(`${model}/analytics`, {
    start,
    end
  });
}

export async function ProductionSheet(date_debut, date_fin, zone) {
  return modelService.IndexModel(
    `production-sheets/${date_debut}/${date_fin}/${zone}`
  );
}

export async function ExportProductionSheet(date_debut, date_fin, zone) {
  return modelService.PostModel(
    `production-sheets/${date_debut}/${date_fin}/${zone}/save-file`,
    {}
  );
}

export async function SendButlerNotification(id, formData) {
  return modelService.PutModel(`${model}/${id}`, {
    ...formData,
    context: 'butlers-notification'
  });
}
