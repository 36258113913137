import React, { useState } from 'react';
import {
  Offcanvas,
  Row,
  Col,
  Alert,
  Button,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import { DeleteNotes } from '../../../services/NoteService';
import { toast } from 'react-toastify';
import NotesModal from '../modals-components/NotesModal';
import SoftBadge from '../../common/SoftBadge';

const CanvaNotes = ({ model, noteableType, context, setTrigger, hideSvg }) => {
  const [showCanvaNotes, setShowCanvaNotes] = useState(false);
  const [showCanvaNotesDelete, setShowCanvaNotesDelete] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const deleteNote = async id => {
    const responseDeleteNote = await DeleteNotes(id);
    setIsLoadingSubmit(true);
    if (responseDeleteNote.success === true) {
      setTrigger(Date.now());
      setShowCanvaNotesDelete(false);
      toast('Note supprimée');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoadingSubmit(false);
  };

  return (
    <>
      {showCanvaNotesDelete && (
        <>
          <Offcanvas
            placement="end"
            show={showCanvaNotesDelete}
            onHide={() => {
              setShowCanvaNotesDelete(false);
            }}
          >
            <Offcanvas.Header
              closeButton={() => {
                setShowCanvaNotesDelete(false);
              }}
            >
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Col> Supprimmer la note ?</Col>
              <Col className="mt-3">
                <Button
                  disabled={isLoadingSubmit}
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    deleteNote(selectedNote.id);
                  }}
                >
                  Oui, supprimer la note
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowCanvaNotesDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Col>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}

      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="hi">Gérer les notes</Tooltip>}
      >
        <Button
          style={{ paddingLeft: '5px', paddingRight: '5px' }}
          size="sm"
          variant="falcon-default"
          className="ms-3"
          onClick={() => {
            setShowCanvaNotes(true);
          }}
        >
          <SoftBadge
            pill
            bg="warning"
            className={!hideSvg || hideSvg !== true ? 'me-1' : ''}
          >
            {model.notes.filter(note => note.context === context).length > 0
              ? model.notes.filter(note => note.context === context).length
              : 0}
          </SoftBadge>{' '}
          {(!hideSvg || hideSvg !== true) && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M13.828 1.686l8.486 8.486-1.415 1.414-.707-.707-4.242 4.242-.707 3.536-1.415 1.414-4.242-4.243-4.95 4.95-1.414-1.414 4.95-4.95-4.243-4.242 1.414-1.415L8.88 8.05l4.242-4.242-.707-.707 1.414-1.415zm.708 3.536l-4.671 4.67-2.822.565 6.5 6.5.564-2.822 4.671-4.67-4.242-4.243z"
                fill="rgba(93,110,130,1)"
              />
            </svg>
          )}
        </Button>
      </OverlayTrigger>

      <Offcanvas
        placement={'end'}
        show={showCanvaNotes}
        onHide={() => {
          setShowCanvaNotes(false);
        }}
        className={'w-30'}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Notes</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {model && model.id && selectedNote && (
            <NotesModal
              model={model}
              setTrigger={setTrigger}
              context={context}
              noteableType={noteableType}
              modalShow={modalShow}
              setModalShow={setModalShow}
              selectedNote={selectedNote}
            />
          )}
          {model.notes && model.notes.length > 0 && (
            <Row>
              {model.notes
                .filter(note => note.context === context)
                .map((note, index) => (
                  <div key={index}>
                    <Col xs={12}>
                      <Alert variant={'primary'}>
                        <Row>
                          <Col xs={12} className="text-align-start">
                            {note.content}
                          </Col>
                          <Col xs={12} className="text-align-end mt-3">
                            <small>
                              {Moment(note.created_at)
                                .local()
                                .format('DD/MM/yyyy')}
                              {note.show_on_sheet &&
                                note.show_on_sheet === 1 && (
                                  <span>
                                    <small>
                                      <i>
                                        {' '}
                                        affichée sur{' '}
                                        {note.context === 'products'
                                          ? 'la fiche de production'
                                          : note.context === 'resources'
                                          ? 'la fiche logistique'
                                          : 'la fiche'}
                                      </i>
                                    </small>
                                  </span>
                                )}
                              <Col className="text-end">
                                {/*-- Bouton modifier --*/}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="hi">Modifier</Tooltip>}
                                >
                                  <a
                                    onClick={() => {
                                      setModalShow(true);
                                      setSelectedNote(note);
                                    }}
                                    className="cursor-pointer me-2"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="16"
                                      height="16"
                                      style={{
                                        marginBottom: '1px',
                                        marginLeft: '3px'
                                      }}
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path
                                        d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                        fill="rgba(44,123,229,1)"
                                      />
                                    </svg>
                                  </a>
                                </OverlayTrigger>
                                {/*-- bouton supprimer --*/}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="hi">Supprimer</Tooltip>}
                                >
                                  <a className="cursor-pointer">
                                    <svg
                                      onClick={() => {
                                        setShowCanvaNotesDelete(true);
                                        setSelectedNote(note);
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="16"
                                      height="16"
                                      className="float-end"
                                      style={{
                                        marginBottom: '1px',
                                        marginLeft: '3px'
                                      }}
                                    >
                                      <path
                                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                        fill="rgba(255,0,0)"
                                      />
                                    </svg>
                                  </a>
                                </OverlayTrigger>
                              </Col>
                            </small>
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </div>
                ))}
            </Row>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CanvaNotes;

CanvaNotes.propTypes = {
  model: PropTypes.object,
  noteableType: PropTypes.string,
  setTrigger: PropTypes.func,
  context: PropTypes.string,
  hideSvg: PropTypes.bool
};
