import React, { Fragment, useEffect } from 'react';
import { Col, Table } from 'react-bootstrap';

import PropTypes from 'prop-types';

const TableVehicles = ({
  vehiclesFromPresta,
  setFormVehicles,
  formVehicles
}) => {
  useEffect(() => {
    setFormVehicles(vehiclesFromPresta);
  }, [vehiclesFromPresta]);

  return (
    <>
      {' '}
      {vehiclesFromPresta && vehiclesFromPresta.length > 0 && (
        <Fragment>
          <Col xs={12} className="mt-3">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                </tr>
              </thead>
              <tbody>
                {formVehicles.map((vehicle, index) => (
                  <tr key={index}>
                    <td>{vehicle.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Fragment>
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func
};
TableVehicles.propTypes = {
  vehiclesFromPresta: PropTypes.array,
  trigger: PropTypes.func,
  setFormVehicles: PropTypes.func,
  formVehicles: PropTypes.array
};

export default TableVehicles;
