import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  PostResources,
  ShowResources,
  DeleteResources,
  PutResources,
  IndexResources
} from '../../../services/RessourceService';
import { toast } from 'react-toastify';
import { IndexResourceCategories } from '../../../services/ResourceCategoriesService';
import PropTypes from 'prop-types';
import FormAlert from '../commons/FormAlert';
import ManageResourceCategory from './categories/ManageResourceCategory';

const ManageResource = ({
  showResource,
  setShowResource,
  setParentTrigger,
  selectedObject,
  formParentProduct,
  setFormParentProduct,
  setLabelResources
}) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [
    checkRessourceIsAddByDefaultPrestation,
    setCheckRessourceIsAddByDefaultPrestation
  ] = useState(false); // vérifie si le checkBox passe à true ou false
  const [showManageCategories, setShowManageCategories] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [showDelete, setShowDelete] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingSubmitDelete, setIsLoadingSubmitDelete] = useState(false);
  const [labelCategoriesResource, setLabelCategoriesResource] = useState([]); // Affiche toutes les catégorie
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    category_id: null,
    invoice_name: '',
    service_sheet_name: '',
    default_price: '', // prix
    default_vat: 20, // tranche TVA
    reference: '',
    added_to_prestation_by_default: '',
    category: {},
    default_unit: 'hour'
  });

  const handleGetCategory = (value, label) => {
    setFormData({ ...formData, category: { value, label } });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckBoxChange = event => {
    setCheckRessourceIsAddByDefaultPrestation(event.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    let response = false;
    let tmpFormData = { ...formData };

    if (!tmpFormData.default_vat) {
      tmpFormData.default_vat = 20;
    }
    if (checkRessourceIsAddByDefaultPrestation === true) {
      tmpFormData.added_to_prestation_by_default = 1;
    } else {
      tmpFormData.added_to_prestation_by_default = 0;
    }

    if (tmpFormData.category && tmpFormData.category.value) {
      tmpFormData.category_id = tmpFormData.category.value;
    }

    if (!tmpFormData.category_id) {
      tmpFormData.category_id = selectedObject.category_id;
    }

    if (selectedObject && selectedObject.id) {
      response = await PutResources(
        selectedObject && selectedObject.id,
        tmpFormData
      );
    } else {
      response = await PostResources(tmpFormData);
    }

    if (response.success === true) {
      toast('Ressource enregistrée');

      setShowResource(false);
      setParentTrigger(Date.now());
      const responseRessource = await IndexResources();
      if (responseRessource.success === true) {
        let tmpResources = [];
        responseRessource.data.map(resource => {
          tmpResources.push({
            value: resource.id,
            label: resource.invoice_name
          });
        });
        setLabelResources(tmpResources);
      }

      if (formParentProduct) {
        setFormParentProduct({
          ...formParentProduct,
          resources: [
            ...formParentProduct.resources,
            { value: response.data.id, label: response.data.invoice_name }
          ]
        });
        setShowResource(false);
        return;
      }
      setShowResource(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
    setIsLoadingSubmit(false);
  };

  const deleteResource = async () => {
    const responseDeleteResource = await DeleteResources(
      selectedObject && selectedObject.id
    );
    setIsLoadingSubmitDelete(true);
    if (responseDeleteResource.success === true) {
      toast('Ressource supprimée');
      setParentTrigger(Date.now());
      setShowDelete(false);
      setShowResource(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(responseDeleteResource.validator);
  };

  useEffect(() => {
    const fetchOneResource = async () => {
      const responseOneResource = await ShowResources(
        selectedObject && selectedObject.id
      );
      if (responseOneResource.success === true) {
        let categoryArray = [];
        let tmpCategory = [];
        categoryArray.push(responseOneResource.data.category);
        categoryArray.map(category => {
          tmpCategory.push({
            value: category.id,
            label: category.name
          });
        });

        setFormData({
          category_id: responseOneResource.data.category.value,
          invoice_name: responseOneResource.data.invoice_name,
          service_sheet_name: responseOneResource.data.service_sheet_name,
          category: tmpCategory,
          default_price: responseOneResource.data.default_price,
          default_vat: responseOneResource.data.default_vat,
          default_unit: responseOneResource.data.default_unit,
          reference: responseOneResource.data.reference,
          added_to_prestation_by_default:
            responseOneResource.data.added_to_prestation_by_default
        });
      }
      setIsLoadingSubmitDelete(false);
    };

    const fetchAllResourceCategories = async () => {
      const responseResourceCategories = await IndexResourceCategories();
      const dataCategories = responseResourceCategories.data;
      if (responseResourceCategories.success === true) {
        setFormData({ ...formData, category_id: dataCategories.id });
        let tmpCategoriesArray = [];
        responseResourceCategories.data.map(category => {
          tmpCategoriesArray.push({
            value: category.id,
            label: category.name
          });
        });
        setLabelCategoriesResource(tmpCategoriesArray);
      }
    };

    fetchAllResourceCategories();
    if (selectedObject.id) {
      fetchOneResource();
    }
  }, [trigger, selectedObject]);

  return (
    <>
      {showManageCategories && (
        <ManageResourceCategory
          selectedCategory={selectedCategory}
          showManageCategories={showManageCategories}
          setShowManageCategories={setShowManageCategories}
          setLabelCategoriesResource={setLabelCategoriesResource}
          setTrigger={setTrigger}
          handleGetCategory={handleGetCategory}
        />
      )}
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            Supprimer :{' '}
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              {selectedObject.invoice_name}{' '}
            </span>{' '}
            ?
          </p>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Button
            disabled={isLoadingSubmitDelete}
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteResource();
            }}
          >
            Oui, supprimer la ressource{' '}
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showResource}
        onHide={() => {
          setShowResource(false);
        }}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header
          closeButton={() => {
            setShowResource(false);
          }}
        >
          <h3 className="text-primary admin-title mb-0">
            {selectedObject && selectedObject.id
              ? 'Modifier la ressource'
              : `Ajouter une ressource`}
          </h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="reference">
                  <Form.Label>Référence :</Form.Label>
                  <Form.Control
                    type="text"
                    name="reference"
                    placeholder="Choisir la référence"
                    value={formData.reference || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="default_price">
                  <Form.Label>Prix :</Form.Label>
                  <Form.Control
                    type="number"
                    name="default_price"
                    placeholder="Choisir son prix"
                    value={formData.default_price || ''}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="default_vat">
                  <Form.Label>Tranche TVA :</Form.Label>
                  <Form.Select
                    name="default_vat"
                    placeholder="Tranche TVA"
                    value={formData.default_vat || ''}
                    onChange={handleFieldChange}
                  >
                    {formData.default_vat === '5.50' ? (
                      <>
                        <option value="5.50">5,50%</option>
                        <option value="10">10%</option>
                        <option value="20">20%</option>
                      </>
                    ) : formData.default_vat === '10.00' ? (
                      <>
                        <option value="10">10%</option>
                        <option value="5.50">5.50%</option>
                        <option value="20">20%</option>
                      </>
                    ) : (
                      <>
                        <option value="20">20%</option>
                        <option value="5.50">5.50%</option>
                        <option value="10">10%</option>
                      </>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg="4">
                <Form.Group controlId="category">
                  <Form.Label>
                    Catégorie :
                    <a
                      onClick={() => {
                        /*setSelectedCategory({});*/
                        setShowManageCategories(true);
                      }}
                      className="cursor-pointer ms-2"
                    >
                      <small>Créer une catégorie</small>
                    </a>
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={labelCategoriesResource}
                    placeholder="Boisson, Vaisselle ..."
                    name="category"
                    classNamePrefix="react-select"
                    onChange={value => {
                      setFormData({
                        ...formData,
                        category: value
                      });
                      setSelectedCategory(value);
                    }}
                    value={formData.category}
                  ></Select>
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group controlId="default_unit">
                  <Form.Label>Unité par défaut :</Form.Label>
                  <Form.Select
                    name="default_unit"
                    placeholder="Unité par défaut"
                    value={formData.default_unit || ''}
                    onChange={handleFieldChange}
                  >
                    <option value="hour">Heure</option>
                    <option value="piece">Pièce</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Col
              style={{ borderTop: 'dashed 1px #d8e2ef' }}
              className="my-4"
            ></Col>

            <Col className="mt-3">
              <Form.Group className="mt-2" controlId="invoice_name">
                <Form.Label style={{ fontWeight: '800 !important' }}>
                  Dénomination du devis :
                </Form.Label>
                <Form.Control
                  type="text"
                  name="invoice_name"
                  placeholder="Ecrire une dénomination"
                  value={formData.invoice_name || ''}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Col>

            <Col className="mt-3">
              <Form.Group className="mt-2" controlId="service_sheet_name">
                <Form.Label style={{ fontWeight: '800 !important' }}>
                  Dénomination de la fiche logistique :
                </Form.Label>
                <Form.Control
                  type="text"
                  name="service_sheet_name"
                  placeholder="Ecrire une dénomination"
                  value={formData.service_sheet_name || ''}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Col>
            <Col className="mt-3">
              <Form.Check
                onChange={handleCheckBoxChange}
                type="checkbox"
                id="defaultCheckbox"
                defaultChecked={
                  selectedObject.added_to_prestation_by_default === 1
                }
                label={
                  <p>
                    <small>
                      Ajouter automatiquement cette ressource aux nouvelles
                      prestations ?
                    </small>
                  </p>
                }
              />
            </Col>

            <Col className="mt-2">
              <Button
                disabled={isLoadingSubmit}
                onClick={e => {
                  handleSubmit(e);
                }}
                size="sm"
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {selectedObject && selectedObject.id
                  ? ' Enregistrer les modifications'
                  : ' Enregistrer la nouvelle ressource'}
              </Button>
              {selectedObject.id && (
                <Button
                  size="sm"
                  variant="danger"
                  className="me-0 float-end"
                  type="button"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '2px',
                      marginRight: '3px'
                    }}
                  >
                    <path
                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Supprimer
                </Button>
              )}
            </Col>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageResource.propTypes = {
  selectedObject: PropTypes.object,
  setShowResource: PropTypes.func,
  showResource: PropTypes.bool,
  setParentTrigger: PropTypes.func,
  formParentProduct: PropTypes.object,
  setFormParentProduct: PropTypes.func,
  setLabelResources: PropTypes.func
};

export default ManageResource;
