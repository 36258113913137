import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Moment from 'moment';
import ManageInfoPrestation from './ManageInfoPrestation';
import FalconLightBox from '../../../common/FalconLightBox';
import Avatar from '../../../common/Avatar';
import { IndexPrestationsStatus } from '../../../../services/PrestationStatusService';
import Select from 'react-select';
import { PutPrestation } from '../../../../services/PrestationService';
import { toast } from 'react-toastify';
import FalconCardHeader from '../../../common/FalconCardHeader';

const ManageInfoService = ({
  prestation,
  setParentTrigger,
  statusFromPresta
}) => {
  const [showManagePrestation, setShowManagePrestation] = useState(false);
  const [labelStatus, setLabelStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const getFilename = fullPath => {
    return fullPath.replace(/^.*[\\/]/, '');
  };

  useEffect(() => {
    if (prestation.status) {
      setSelectedStatus({
        value: prestation.status.id,
        label: (
          <>
            <span
              style={{
                backgroundColor: prestation.status.color,
                height: '18px',
                width: '18px',
                position: 'relative',
                top: '3px'
              }}
              className="rounded-circle d-inline-block me-2"
            ></span>
            {prestation.status.name}
          </>
        )
      });
    }
    const fetchStatus = async () => {
      const responseStatus = await IndexPrestationsStatus();
      if (responseStatus.success === true) {
        let tmpStatus = [];
        responseStatus.data.map(status => {
          tmpStatus.push({
            value: status.id,
            label: (
              <>
                <span
                  style={{
                    backgroundColor: status.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                  className="rounded-circle d-inline-block me-2"
                ></span>
                {status.name}
              </>
            )
          });
        });
        setLabelStatus(tmpStatus);
      }
    };
    fetchStatus();
  }, []);

  return (
    <>
      {showManagePrestation && (
        <ManageInfoPrestation
          setShowManagePrestation={setShowManagePrestation}
          showManagePrestation={showManagePrestation}
          prestation={prestation}
          setParentTrigger={setParentTrigger}
        />
      )}
      <Card
        className="mt-3 pb-3"
        style={{
          borderWidth: '1px',
          borderColor: 'rgba(0,0,0,0.125)',
          borderRadius: '4px'
        }}
      >
        <FalconCardHeader title="Informations générales" />
        <Card.Body className="pt-0">
          <Row>
            {selectedStatus.value && (
              <Col md={12} className="mb-3">
                <Form.Group controlId="type">
                  <Form.Label>Statut de la prestation</Form.Label>
                  <Select
                    name="type"
                    closeMenuOnSelect={true}
                    options={labelStatus}
                    placeholder="Choisir un statut..."
                    classNamePrefix="react-select"
                    value={statusFromPresta || selectedStatus}
                    onChange={async value => {
                      setSelectedStatus(value);
                      const response = await PutPrestation(prestation.id, {
                        context: 'status',
                        status_id: value.value
                      });
                      if (response.success === true) {
                        toast('Statut mis à jour');
                        setParentTrigger(Date.now());
                      } else {
                        toast(
                          'Une erreur est survenue, veuillez réessayer plus tard'
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            <Col lg={12}>
              {prestation.customer && (
                <>
                  <span className="text-600 fw-medium fs--1 d-block mb-1">
                    Client :{' '}
                  </span>
                  <h5 className="text-800 mb-0">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="hi">
                          {prestation.customer.name} -{' '}
                          {prestation.customer.address} -{' '}
                          {prestation.customer.city}
                        </Tooltip>
                      }
                    >
                      <span style={{ cursor: 'default' }}>
                        {prestation.customer.name}
                      </span>
                    </OverlayTrigger>
                    {(prestation.contact_name || prestation.contact_phone) && (
                      <small>
                        <small>
                          <br />
                          {prestation.contact_name}
                          {prestation.contact_phone
                            ? ` - ${prestation.contact_phone}`
                            : ''}
                        </small>
                      </small>
                    )}
                  </h5>
                </>
              )}
              {prestation.address && (
                <>
                  <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                    Lieu :{' '}
                  </span>
                  <h6 className="text-800 mb-0">
                    <a
                      href={`https://www.google.com/maps/place/${prestation.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {prestation.address}
                    </a>{' '}
                    <small>{prestation.address_details}</small>
                  </h6>
                </>
              )}

              <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                Date / heure :{' '}
              </span>
              <h5 className="text-800 mb-0">
                {Moment(prestation.date).local().format('DD/MM/YYYY')} -{' '}
                {moment(prestation.time, 'HH:mm:ss').format('HH')}
                {':'}
                {moment(prestation.time, 'HH:mm:ss').format('mm')}{' '}
                <small>
                  <small>
                    Heure arrivée AVS :{' '}
                    {moment(prestation.time_team, 'HH:mm:ss').format('HH')}
                    {':'}
                    {moment(prestation.time_team, 'HH:mm:ss').format('mm')}
                  </small>
                  <br />
                  <small>
                    Heure départ AVS :{' '}
                    {moment(prestation.time_team_end, 'HH:mm:ss').format('HH')}
                    {':'}
                    {moment(prestation.time_team_end, 'HH:mm:ss').format('mm')}
                  </small>
                </small>
              </h5>

              <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                Nombre de convives :{' '}
              </span>
              <h5 className="text-800 mb-0">{prestation.guest_count}</h5>

              {prestation.quotes && prestation.quotes.length > 0 && (
                <>
                  <span className="text-600 fw-medium fs--1 mt-3 mb-1 d-block">
                    Référence du devis :{' '}
                  </span>
                  <h5 className="text-800 mb-0">
                    {prestation.quotes[0].reference}
                  </h5>
                </>
              )}

              <span className="text-600 fw-medium fs--1 mt-3 pt-1 mb-1 d-block">
                Prestation suivie par :{' '}
              </span>
              <div className="d-flex align-items-center mt-2">
                <span className="d-block pt-1">
                  {prestation.user?.profile_picture_url &&
                  getFilename(prestation.user.profile_picture_url) !==
                    'default_port.png' ? (
                    <FalconLightBox image={prestation.user.profile_picture_url}>
                      <Avatar src={prestation.user.profile_picture_url} />
                    </FalconLightBox>
                  ) : (
                    <Avatar
                      name={`${prestation.user?.firstname} ${prestation.user?.lastname}`}
                    />
                  )}
                </span>
                <h5 className="text-800 mb-0 align-items-center ps-2 pt-0 mt-0">
                  {prestation.user?.firstname}
                </h5>
              </div>
            </Col>

            <Col lg={12} className="d-flex justify-content-start mb-2 mt-4">
              <Button
                variant="light"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setShowManagePrestation(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    marginBottom: '2px',
                    marginRight: '2px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                    fill="rgba(93,110,130,1)"
                  />
                </svg>
                Modifier les informations
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

ManageInfoService.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func,
  statusFromPresta: PropTypes.array
};

export default ManageInfoService;
