import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import HttpService from '../../../../services/HttpService';
import Flex from '../../../common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import getCroppedImg from '../../commons/cropImage';

const FileUploader = ({
  pathToUpload,
  formData,
  setFormData,
  format,
  databaseField,
  databaseFieldArray,
  databaseFieldArrayIndex,
  postRoute,
  cropperEnabled,
  currentImage,
  hideReplace,
  progressBar
}) => {
  const [progress, setProgress] = useState(0);
  const [listFile, setListFile] = useState(currentImage);
  const [isLoading, setIsLoading] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  //const [croppedArea, setCroppedArea] = useState(null);
  const handleFile = e => {
    setIsLoading(true);
    const formDataFile = new FormData();
    formDataFile.append('path', pathToUpload);
    formDataFile.append('file', e.target.files[0]);
    uploadFile(formDataFile);
    setShowCropModal(true);
  };
  const uploadFile = async formDataFile => {
    const http = new HttpService();
    const token = await localStorage.getItem('accessToken_avs');

    const req = new XMLHttpRequest();
    req.open('POST', `${http.getApiUrl()}/upload-files?format=${format}`);
    req.setRequestHeader('Authorization', 'Bearer ' + token);
    req.upload.addEventListener('progress', function (e) {
      setIsLoading(true);
      const percentComplete = Math.round((e.loaded / e.total) * 100);
      setProgress(percentComplete);
    });

    req.addEventListener('load', async () => {
      let responseUpload = JSON.parse(req.response);
      if (
        responseUpload.message === 'Object created' &&
        responseUpload.data !== undefined &&
        responseUpload.data !== null
      ) {
        setListFile(responseUpload.data);
        if (postRoute) {
          const formDataPostRoute = new FormData();
          formDataPostRoute.append('file_url', responseUpload.data);
          await fetch(`${http.getApiUrl()}/${postRoute}`, {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + token
            },
            body: formDataPostRoute
          }).then(response => response.json());
        } else {
          if (databaseField !== null && databaseField !== undefined) {
            if (
              databaseFieldArray !== null &&
              databaseFieldArray !== undefined &&
              databaseFieldArrayIndex !== null &&
              databaseFieldArrayIndex !== undefined
            ) {
              let tmpFormData = formData;
              if (tmpFormData[databaseFieldArray] === undefined) {
                tmpFormData[databaseFieldArray] = [];
              }
              let tmpIndex =
                tmpFormData[databaseFieldArray][databaseFieldArrayIndex];
              if (tmpIndex === undefined) {
                tmpIndex = {};
              }
              tmpIndex[databaseField] = responseUpload.data;
              tmpFormData[databaseFieldArray][databaseFieldArrayIndex] =
                tmpIndex;
              setFormData(tmpFormData);
            } else {
              setFormData({
                ...formData,
                [databaseField]: responseUpload.data
              });
            }
          } else {
            setFormData({
              ...formData,
              file_url: responseUpload.data
            });
          }
        }
      } else if (responseUpload.message === 'erreur format') {
        setIsLoading(false);
        toast(responseUpload.data);
      } else {
        setIsLoading(false);
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    });
    req.send(formDataFile);
    setIsLoading(false);
  };
  const { getRootProps, getInputProps } = useDropzone({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, cap) => {
    setCroppedAreaPixels(cap);
  }, []);
  const showCroppedImage = async (file, cap) => {
    setShowCropModal(false);
    const croppedImage = await getCroppedImg(file, cap, rotation);
    setIsLoading(true);
    const formDataFile = new FormData();
    formDataFile.append('path', pathToUpload);
    formDataFile.append('fileData', croppedImage);
    uploadFile(formDataFile);
  };
  return (
    <>
      {listFile !== undefined && listFile !== null ? (
        <Row>
          <Col xs={12}>
            {['jpg', 'jpeg', 'png', 'gif'].includes(
              listFile.split('.').pop().toLowerCase()
            ) ? (
              <>
                {cropperEnabled === true && (
                  <>
                    <Modal
                      show={showCropModal}
                      onHide={() => {
                        setShowCropModal(false);
                      }}
                      backdrop="static"
                      keyboard={false}
                      className="crop-modal"
                    >
                      <Modal.Body>
                        <div
                          className="crop-container"
                          style={{ height: '300px' }}
                        >
                          <Cropper
                            image={listFile}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            rotation={rotation}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          className="admin-primary"
                          size="sm"
                          onClick={() => {
                            showCroppedImage(listFile, croppedAreaPixels);
                          }}
                        >
                          Enregistrer
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
                {hideReplace !== true && (
                  <span
                    className="deleteFile d-block mb-1 cursor-pointer"
                    onClick={() => {
                      setListFile(null);
                      setIsLoading(false);
                      if (
                        databaseField !== null &&
                        databaseField !== undefined
                      ) {
                        setFormData({
                          ...formData,
                          [databaseField]: ''
                        });
                      } else {
                        setFormData({
                          ...formData,
                          file_url: ''
                        });
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="10"
                      height="10"
                      style={{ marginBottom: '2px' }}
                    >
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                    </svg>{' '}
                    Supprimer
                  </span>
                )}
                <img
                  style={{
                    width: '100%',
                    maxWidth: '250px'
                  }}
                  src={listFile}
                  className="shadow"
                />
              </>
            ) : ['pdf'].includes(listFile.split('.').pop()) ? (
              <iframe src={listFile} style={{ width: '100%' }}></iframe>
            ) : (
              <a href={listFile} target="_blank" rel="noreferrer">
                {listFile.substring(listFile.lastIndexOf('/') + 1)}
              </a>
            )}
          </Col>
        </Row>
      ) : (
        <>
          {isLoading ? (
            <Row className="align-items-center">
              {progressBar === true ? (
                <Col xs={12}>
                  {progress >= 95 ? (
                    <small className="d-block mb-2">
                      Scan de sécurité et transfert du fichier vers Google
                      Cloud, veuillez patienter quelques instants... (entre 15
                      secondes et 3 minutes)
                    </small>
                  ) : (
                    <small className="d-block mb-2">
                      Chargement en cours...
                    </small>
                  )}
                  <ProgressBar
                    now={progress >= 95 ? 95 : progress}
                    label={`${Math.round(progress >= 95 ? 95 : progress)}%`}
                  />
                </Col>
              ) : (
                <Col xs={12}>
                  <Spinner animation="border" size="sm" variant="primary" />
                  <small
                    style={{ position: 'relative', bottom: '4px', left: '6px' }}
                  >
                    Chargement du fichier en cours, veuillez patienter...
                  </small>
                </Col>
              )}
            </Row>
          ) : (
            <Row className="align-items-center">
              <Col xs={12}>
                <div {...getRootProps({ className: 'dropzone-area py-4' })}>
                  <input
                    {...getInputProps({ multiple: false })}
                    onChange={e => {
                      handleFile(e);
                    }}
                  />
                  <Flex justifyContent="center">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                    <p className="fs-0 mb-0 text-700">
                      Choisissez votre fichier ici
                    </p>
                  </Flex>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

FileUploader.propTypes = {
  pathToUpload: PropTypes.string,
  file: PropTypes.any,
  setFile: PropTypes.func,
  setFormData: PropTypes.func,
  postRoute: PropTypes.any,
  formData: PropTypes.object,
  format: PropTypes.any,
  databaseField: PropTypes.any,
  databaseFieldArray: PropTypes.any,
  databaseFieldArrayIndex: PropTypes.any,
  cropperEnabled: PropTypes.any,
  currentImage: PropTypes.any,
  hideReplace: PropTypes.any,
  progressBar: PropTypes.any
};

export default FileUploader;
