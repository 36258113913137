import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PostQuote } from '../../../../services/QuoteService';

const ManageEstimate = ({ setParentTrigger, prestation }) => {
  const [showCanvaEstimate, setShowCanvaEstimate] = useState(false);

  const handleSubmit = async () => {
    setShowCanvaEstimate(true);
    const responsePostQuote = await PostQuote({
      prestation_id: prestation.id
    });
    if (responsePostQuote.success === true) {
      toast('Le devis a bien été généré');
      setParentTrigger(Date.now());
      setShowCanvaEstimate(false);
    }
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        onClick={() => {
          handleSubmit();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="15"
          height="15"
          style={{ position: 'relative', bottom: '1px' }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 4C9.25144 4 6.82508 5.38626 5.38443 7.5H8V9.5H2V3.5H4V5.99936C5.82381 3.57166 8.72764 2 12 2C17.5228 2 22 6.47715 22 12H20C20 7.58172 16.4183 4 12 4ZM4 12C4 16.4183 7.58172 20 12 20C14.7486 20 17.1749 18.6137 18.6156 16.5H16V14.5H22V20.5H20V18.0006C18.1762 20.4283 15.2724 22 12 22C6.47715 22 2 17.5228 2 12H4Z"
            fill="rgba(94,110,130,1)"
          />
        </svg>{' '}
        Générer un nouveau devis
      </Button>

      <Modal
        backdrop="static"
        keyboard={false}
        size="lg"
        show={showCanvaEstimate}
        onHide={() => setShowCanvaEstimate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Col xs={11}>
            <b>Enregistrement du devis, veuillez ne pas quitter la page...</b>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="d-block mt-3">
                <Spinner animation="border" role="status" size="xxs">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageEstimate;

ManageEstimate.propTypes = {
  setParentTrigger: PropTypes.func,
  prestation: PropTypes.object
};
