import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DisplayFolder = ({
  index,
  setCurrentFolder,
  folder,
  setBreadcrumbHistory,
  breadcrumbHistory,
  setSelectedObject
}) => {
  const handleDoubleClick = () => {
    setCurrentFolder(folder);
    setBreadcrumbHistory([...breadcrumbHistory, folder]);
    let selected = document.getElementsByClassName('bg-soft-info');
    Array.from(selected).forEach(item => {
      item.classList.remove('bg-soft-info');
    });
    setSelectedObject({});
  };

  return (
    <>
      <Col xs={2} key={index} className={index > 5 ? 'p-3' : 'p-3'}>
        <Card
          id={'idFolder' + index}
          className={'cursor-pointer'}
          onDoubleClick={handleDoubleClick}
          onClick={() => {
            let selected = document.getElementsByClassName('bg-soft-info');
            Array.from(selected).forEach(item => {
              item.classList.remove('bg-soft-info');
            });

            document
              .getElementById('idFolder' + index)
              .classList.add('bg-soft-info');
            setSelectedObject(folder);
          }}
        >
          <Card.Body>
            <Row>
              <Col lg={12}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className={'me-2'}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4 5v14h16V7h-8.414l-2-2H4zm8.414 0H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2z"
                    fill="rgba(97,110,128,1)"
                  />
                </svg>
                <span className={'prevent-select me-2 displayColorTextSelect'}>
                  {folder.name}
                </span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

DisplayFolder.propTypes = {
  index: PropTypes.number,
  setCurrentFolder: PropTypes.func,
  folder: PropTypes.object,
  setBreadcrumbHistory: PropTypes.func,
  breadcrumbHistory: PropTypes.array,
  setSelectedObject: PropTypes.func
};

export default DisplayFolder;
