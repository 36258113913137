import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, Button } from 'react-bootstrap';
import CanvaNotes from '../../canvas-components/canvaNotes';
import ManageButtlersFromService from './ManageButtlersFromService';
import TableButtlers from './TableButtlers';
import { IndexUser } from '../../../../services/UserService';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { PutPrestation } from '../../../../services/PrestationService';
import FormAlert from '../../commons/FormAlert';
import ButlersNotification from './ButlersNotification';

const ManageButtlersService = ({
  prestation,
  setParentTrigger,
  butlersFromPresta
}) => {
  const [showAddButtlers, setShowAddButtlers] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [trigger] = useState(Date.now());
  const [allButtlersLabel, setAllButtlersLabel] = useState([]); // récupère tout les maitres d'hotels
  const [formButlers, setFormButlers] = useState([]); //récupère la date et l'horaire d'intervention de chaque maitre d'hotel
  const [formData, setFormData] = useState({
    butlers: [],
    context: 'butlers'
  });
  const handleSubmit = async e => {
    e.preventDefault();
    const response = await PutPrestation(prestation.id, {
      context: 'butlers',
      butlers: formButlers.map(butler => {
        return {
          butler_id: butler.value,
          start_at: Moment(butler.start_at)
            .local()
            .format('YYYY-MM-DD HH:mm:ss'),
          worked_hours: butler.worked_hours,
          mission: butler.mission
        };
      })
    });
    if (response.success === true) {
      toast('Mise à jour réussie');
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue');
    }
    setErrorForm(response.validator);
  };

  useEffect(() => {
    const fetchButllers = async () => {
      const responseUser = await IndexUser();
      if (responseUser.success === true) {
        let tmpButllers = [];
        //filtre tout les utilisteurs ayant le role de maitre d'hotel
        responseUser.data
          .filter(role => role.role_id === 2)
          .map(butler => {
            tmpButllers.push({
              value: butler.id,
              label: `${butler.firstname} ${butler.lastname}`,
              email: butler.email,
              firstname: butler.firstname,
              lastname: butler.lastname,
              butler_id: butler.id
            });
          });
        //stock tout les maitres d'hotel dans un state avec leur label pour le select dans allButlersLabel
        setAllButtlersLabel(tmpButllers);
        setFormData({
          butlers: butlersFromPresta
        });
      } else {
        toast('Une erreur est survennu, veuillez réessayer plus tard');
      }
    };
    fetchButllers();
  }, [trigger]);

  return (
    <>
      {showAddButtlers && (
        <ManageButtlersFromService
          showAddButtlers={showAddButtlers}
          setShowAddButtlers={setShowAddButtlers}
          prestation={prestation}
          allButtlersLabel={allButtlersLabel}
          formDataParent={formData}
          setFormDataParent={setFormData}
          setGrandParentTrigger={setParentTrigger}
        />
      )}
      <Card className="mt-3">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Col className="d-flex ">
                <h5 className="pt-1">Associer des Maîtres d'Hôtel</h5>
              </Col>
            </Accordion.Header>
            <Accordion.Body>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              <Row>
                <Col>
                  <p style={{ fontWeight: 500 }}>
                    Liste des personnes autorisées à se connecter depuis
                    l'espace Maître d'Hôtel :{' '}
                    <Button
                      size="sm"
                      variant="primary"
                      className="mt-0 mb-0 ms-3"
                      onClick={() => {
                        setShowAddButtlers(true);
                      }}
                    >
                      Gérer
                    </Button>
                  </p>
                </Col>
                <Col className="text-end">
                  {prestation.butlers?.length > 0 && (
                    <ButlersNotification
                      prestation={prestation}
                      setParentTrigger={setParentTrigger}
                      allButtlersLabel={allButtlersLabel}
                    />
                  )}
                  <CanvaNotes
                    model={prestation}
                    noteableType={'prestation'}
                    setTrigger={setParentTrigger}
                    context={'butlers'}
                  />
                </Col>
              </Row>
              <Row>
                {butlersFromPresta &&
                  butlersFromPresta.length > 0 &&
                  prestation && (
                    <Col>
                      <TableButtlers
                        butlersFromPresta={butlersFromPresta}
                        formButlers={formButlers}
                        setFormButlers={setFormButlers}
                      />
                    </Col>
                  )}
              </Row>
              <Col>
                <Button
                  variant="primary"
                  className="admin-primary mt-3"
                  size="sm"
                  onClick={handleSubmit}
                  /* disabled={isLoadingSubmit}*/
                >
                  Enregistrer
                </Button>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </>
  );
};
ManageButtlersService.propTypes = {
  prestation: PropTypes.object,
  setParentTrigger: PropTypes.func,
  butlersFromPresta: PropTypes.array
};

export default ManageButtlersService;
