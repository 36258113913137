import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DeleteVehicles } from '../../../services/VehiclesService';
import { toast } from 'react-toastify';
import FormAlert from '../commons/FormAlert';

const DeleteCanvaVehicles = ({
  showDelete,
  setShowDelete,
  selectedObject,
  setShowVehicle,
  setParentTrigger
}) => {
  const [errorForm, setErrorForm] = useState(false);

  const deleteVehicle = async () => {
    const response = await DeleteVehicles(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      setShowVehicle(false);
      /*   setIsLoadingSubmit(true);*/

      toast('Véhicule supprimé');
      /*  setTrigger(Date.now());*/
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setErrorForm(response.validator);
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Supprimer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <p>
            Supprimer le véhicule :
            <span style={{ fontWeight: 'bold' }}> {selectedObject.name}</span>?
          </p>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteVehicle();
            }}
          >
            Oui, supprimer le véhicule
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

DeleteCanvaVehicles.propTypes = {
  showDelete: PropTypes.bool,
  setShowDelete: PropTypes.func,
  setShowVehicle: PropTypes.func,
  setParentTrigger: PropTypes.func,
  selectedObject: PropTypes.object
};
export default DeleteCanvaVehicles;
