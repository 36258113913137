export const adminRoutes = {
  label: 'avs',
  children: [
    {
      name: 'Calendrier',
      icon: 'calendar-alt',
      to: '/calendrier',
      active: true
    },
    {
      name: 'Médiathèque',
      icon: 'image',
      to: '/mediatheque',
      active: true
    }
  ]
};

export const clientRoute = {
  label: 'Clients',
  children: [
    {
      name: 'Gestion des clients',
      icon: 'user',
      to: '/clients',
      active: true
    }
  ]
};
export const userRoute = {
  label: 'Utilisateurs',
  children: [
    {
      name: 'Gestion des utilisateurs',
      icon: 'user',
      to: '/utilisateurs',
      active: true
    }
  ]
};
export const productRoute = {
  label: 'Produits',
  children: [
    {
      name: 'Gestion des produits',
      icon: 'shopping-cart',
      to: '/produits',
      active: true
    },
    {
      name: 'Gestion des lots',
      icon: 'shopping-cart',
      to: '/lots/produits',
      active: true
    }
  ]
};

export const resourceRoute = {
  label: 'Resources',
  children: [
    {
      name: 'Gestion des ressources',
      icon: 'shopping-cart',
      to: '/ressources',
      active: true
    }
  ]
};
export const vehicleRoute = {
  label: 'Véhicules',
  children: [
    {
      name: 'Gestion des véhicules',
      icon: 'shopping-cart',
      to: '/vehicules',
      active: true
    }
  ]
};

export const analyticsRoute = {
  label: 'Analytics',
  children: [
    {
      name: 'Comptabilité',
      icon: 'clock',
      to: '/analytics/comptabilite',
      active: true
    }
  ]
};

export const logRoute = {
  label: 'Historique',
  children: [
    {
      name: 'Historique des actions',
      icon: 'clock',
      to: '/historique-actions',
      active: true
    },
    {
      name: 'Historique des emails',
      icon: 'clock',
      to: '/historique-emails',
      active: true
    }
  ]
};

export default [
  adminRoutes,
  clientRoute,
  userRoute,
  productRoute,
  resourceRoute,
  vehicleRoute,
  analyticsRoute,
  logRoute
];
